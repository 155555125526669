import {
    // allCollectionData,
    brandDetailData,
    PaginatedResponse,
    // BrandListData,
    collectionDetailData,
    CollectionListData
} from "./../../types/model";
import {
    PayloadAction,
    BrandListStart,
    BrandListEnd,
    UpdateBrand,
    BrandCollectionStart,
    BrandCollection,
    BrandEnableDisable,
    MultipleBrandDelete,
    BrandDetail,
    BrandDetailStart,
    CollectionListStart,
    CollectionListEnd,
    CollectionDetailStart,
    CollectionDetail,
    UpdateCollection
} from "./types";

interface InitialValues {
    brandList: PaginatedResponse<brandDetailData> | null;
    // collectionList: allCollectionData | null;
    // brandList: BrandListData | null;
    collectionList: CollectionListData | null;
    brandDetail: brandDetailData | null;
    collectionDataList: any | null;
    collectionDetailStart: CollectionListData | null;
    collectionDetail: collectionDetailData | null;
}

const initialValues: InitialValues = {
    brandList: null,
    collectionList: null,
    brandDetail: null,
    collectionDataList: null,
    collectionDetailStart: null,
    collectionDetail: null
};

const DashboardReducer = (state = initialValues, action: PayloadAction): InitialValues => {
    switch (action.type) {
        case BrandListStart:
            return {
                ...state,
                brandList: null
            };
        case BrandListEnd:
            return {
                ...state,
                brandList: action?.payload?.data
            };
        case UpdateBrand:
            return {
                ...state
            };

        case BrandCollectionStart:
            return {
                ...state,
                collectionList: null
            };

        case BrandCollection:
            return {
                ...state,
                collectionList: action?.payload?.data
            };

        case BrandEnableDisable:
            if (state.brandList) {
                const temp = [...state.brandList.result.rows];
                const tempIndex = temp.findIndex((item): any => item?.id === action?.payload?.id);

                if (tempIndex !== -1) {
                    temp[tempIndex] = { ...temp[tempIndex], isEnable: !temp?.[tempIndex]?.isEnable };
                }
                return {
                    ...state,
                    brandList: {
                        ...state.brandList,
                        result: {
                            ...state.brandList.result,
                            rows: [...temp]
                        }
                    }
                };
            } else {
                return state;
            }
        case MultipleBrandDelete:
            return {
                ...state
            };
        case BrandDetailStart:
            return {
                ...state,
                brandDetail: null
            };
        case BrandDetail:
            return {
                ...state,
                brandDetail: action.payload.data
            };

        case UpdateCollection:
            return {
                ...state
            };
        case CollectionDetailStart:
            return {
                ...state,
                collectionDetail: null
            };
        case CollectionDetail:
            return {
                ...state,
                collectionDetail: action.payload.data
            };
        case CollectionListStart:
            return {
                ...state,
                collectionDataList: null
            };
        case CollectionListEnd:
            return {
                ...state,
                collectionDataList: action?.payload?.data
            };
        default:
            return state;
    }
};

export default DashboardReducer;
