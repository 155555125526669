import { ReactElement, useState } from "react";

import { Formik } from "formik";
import { Button, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import Header from "../../components/common/Header";
import Images from "../../config/images";
import { AppDispatch } from "../../types/model";
import string from "../../utils/string";
import Spinner from "../../components/common/Spinner";
import { createCategory } from "../../store/actions/categories";
import { DetailPage, DetailHeader, ProfileCircle, Title, DetailInfo, ProfileCont, ProfileAdd, ImgCont } from "./styled";

const AddCategory = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const [imageData, setImageData] = useState<any>(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required(string.nameErrorMessage),
        logo: Yup.string().required(string.logoErrorMessage)
    });

    const initialValues = {
        name: "",
        logo: ""
    };

    const handleLogoChange = async (e: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        const [file] = e.target.files;
        setImageData(URL.createObjectURL(file));
        setFieldValue("logo", e?.target?.files?.[0]);
    };

    return (
        <>
            <Header />
            <DetailPage>
                <Container>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            const formData: any = new FormData();
                            formData.append("name", values.name);
                            formData.append("logo", values.logo);

                            dispatch(
                                createCategory(formData, () => {
                                    navigate("/categories");
                                })
                            );
                        }}
                    >
                        {({ values, touched, handleSubmit, errors, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <DetailHeader>
                                        <DetailInfo>
                                            <ProfileCircle>
                                                <Link to={"/categories"}>
                                                    <ArrowLeft />
                                                </Link>
                                            </ProfileCircle>
                                            <Title>Add Category</Title>
                                        </DetailInfo>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? <Spinner /> : "Submit"}
                                        </Button>
                                    </DetailHeader>
                                    <ProfileCont>
                                        <ProfileAdd>
                                            <ImgCont htmlFor="logo">
                                                <input
                                                    id="logo"
                                                    type="file"
                                                    accept="image/jpg,image/png,image/jpeg"
                                                    capture={true}
                                                    onChange={(e) => handleLogoChange(e, setFieldValue)}
                                                />
                                                {imageData ? (
                                                    <img src={imageData} alt="" className="logo image_object_cover" />
                                                ) : (
                                                    <p>+ Add logo</p>
                                                )}
                                            </ImgCont>
                                        </ProfileAdd>
                                        {errors.logo && touched.logo && (
                                            <div id="InputFeedback" className="errorMessage">
                                                <img src={Images.errorIcon} /> {errors.logo}
                                            </div>
                                        )}
                                    </ProfileCont>
                                    <Form.Group>
                                        <Form.Label>
                                            Name <span className="astrik">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            autoComplete={"off"}
                                            placeholder=""
                                            value={values.name}
                                        />
                                        {errors.name && touched.name && (
                                            <div id="InputFeedback" className="errorMessage">
                                                <img src={Images.errorIcon} /> {errors.name}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Container>
            </DetailPage>
        </>
    );
};

export default AddCategory;
