import { ReactElement, useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { FaDiscord, FaEdit, FaExternalLinkAlt, FaLink, FaMediumM, FaTwitter } from "react-icons/fa";
import { Button, Tooltip } from "@mui/material";

import { AppDispatch } from "../../types/model";
// import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import Images from "../../config/images";
import { ReactComponent as OpenSea } from "../../assets/images/opensea.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as UpArrow } from "../../assets/images/upArrow.svg";
import {
    collectionApproveDeclined,
    collectionAutopilotStatus,
    collectionEnableDisable,
    deleteBrand,
    exportCSV,
    getCollectionList,
    multipleBrandDelete,
    updateAccountStatusApiAction,
    uploadCSV
} from "../../store/actions/dashboard";
import { IRootState } from "../../store";

import {
    CollectionPage,
    CollectionTitle,
    CollectionSection,
    CollectionStatus,
    TableHeaderTr,
    TableBodyTr,
    APCollectionStatus,
    CollectionFilterTab,
    FilterTab
} from "../indexStyle";

import AlertModal from "../../components/alertModal";
import { Topbar } from "../../components/common/Header";
import UploadModel from "../../components/uploadModel";

import { AccountStatus, SocialLinks } from "./types";
import { handleSnackToast } from "../../components/common/Snackbar";
import { Severities } from "../../store/reducers/toast";
import Table from "../../components/table";
import useTable from "../../hooks/useTable";
import moment from "moment";
import { collectionAutopilotStatusPayload } from "../../api/dashboardApi";
import { FaFacebookSquare, FaTwitter } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";
import Spinner from "../../components/common/Spinner";

const Collections = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const location: any = useLocation();
    const navigate = useNavigate();
    const [selectedCollection, setSelectedCollection] = useState<number[]>([]);
    const [enableMultipleCollectionShow, setEnableMultipleCollectionShow] = useState(false);
    const { collectionDataList } = useSelector((state: IRootState) => state?.dashboard);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [show, setShow] = useState(false);
    const [collectionId] = useState(0);
    const [collectionName] = useState("");
    // const [order] = useState("ASC");
    // const [field] = useState("");
    const [csv, setCsv] = useState(null);
    const [csvFile, setCsvFile] = useState("");
    const [enableModalShow, setEnableModalShow] = useState(false);
    const [search, setSearch] = useState("");
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [declineCollection, setDeclineCollection] = useState();
    const [declineCollectionName, setDeclineCollectionName] = useState("");
    // const colDataList = collectionDataList?.result?.rows;

    const collectionFilterTabs = [
        { key: 1, tabName: "Listed" },
        { key: 2, tabName: "Needs Approval" }
    ];
    interface CollectionFilterTabs {
        key: number;
        tabName: string;
    }
    const [activeFilterTab, setActiveFilterTab] = useState("Listed");
    const activeTabName = activeFilterTab.replace(" ", "").toLowerCase();

    const { pageSize, setPageSize, offset, setOffset, order, setOrder, field, setField, setSelectedItems, isLoading, setIsLoading } =
        useTable();

    useEffect(() => {
        setIsLoading(true);
        dispatch(
            getCollectionList(offset, pageSize, false, order, collectionId, field, activeTabName, search, () => {
                setIsLoading(false);
            })
        );
    }, [pageSize, offset, collectionId, order, field, csvFile, activeTabName, search]);

    useEffect(() => {
        if (location?.state?.data) {
            setOffset(location?.state?.data?.offset || offset);
            setPageSize(location?.state?.data?.pageSize || pageSize);
            navigate("/collections");
        }
    }, [location?.state?.data]);
    // const allSelected = useMemo(() => selectedCollection.length === colDataList?.length,
    // [selectedCollection, collectionDataList?.result]);
    // const colListLength = colDataList?.length;
    const handleYesFunction = () => {
        const payload: any = {
            modelName: "collection",
            id: collectionId
        };
        dispatch(
            deleteBrand(payload, collectionId, () => {
                dispatch(getCollectionList(offset, pageSize, false, "ASC"));
            })
        );
        setShow(false);
    };

    // const handleSwitch = (collection: any) => {
    //     setCollectionId(collection.id);
    //     setCollectionName(collection.name);
    //     if (collection.isEnable === true) {
    //         setEnableModalShow(true);
    //     } else {
    //         dispatch(collectionEnableDisable(collection.id));
    //     }
    // };

    const handleEnableCollection = () => {
        dispatch(
            collectionEnableDisable(collectionId, () => {
                setEnableModalShow(false);
            })
        );
    };

    const handleDeleteMultipleCollection = () => {
        setEnableMultipleCollectionShow(true);
    };
    const handleRemoveMultipleBrands = () => {
        const payload: any = {
            modelName: "collection",
            ids: selectedCollection
        };
        dispatch(
            multipleBrandDelete(payload, () => {
                setSelectedCollection([]);
                dispatch(getCollectionList(offset, pageSize, false, order, collectionId, field));
            })
        );
        setEnableMultipleCollectionShow(false);
    };
    const handleExportCSV = () => {
        const payload: any = {
            type: "collection",
            id: selectedCollection
        };
        dispatch(
            exportCSV(payload, (res: any) => {
                window.open(res?.data, "_blank");
            })
        );
    };

    const uploadCsv = () => {
        const formData: any = new FormData();
        formData.append("logo", csvFile);
        dispatch(
            uploadCSV(formData, () => {
                setShowUploadModal(false);
                dispatch(getCollectionList(offset, pageSize, false, order, collectionId, field));
            })
        );
    };

    const handleUploadCsv = (e: any) => {
        setCsv(e.name);
        setCsvFile(e);
    };

    const handleStatus = (collection: any, status: string) => {
        if (collection?.status === status) return;

        const payload: any = {
            id: collection.id,
            modelName: "collection",
            value: {
                status
            }
        };
        dispatch(
            updateAccountStatusApiAction(payload, () => {
                dispatch(getCollectionList(offset, pageSize, false, order, collectionId, field));
            })
        );
    };

    const handleStatusApprove = (collection: any, status: string) => {
        if (collection?.status === status) return;
        const payload: any = {
            collectionId: collection.id,
            status,
            type: activeTabName
        };
        dispatch(
            collectionApproveDeclined(payload, () => {
                dispatch(getCollectionList(offset, pageSize, false, order, collectionId, field, activeTabName));
            })
        );
    };

    const copyHandler = () => {
        handleSnackToast("Contract Address has been copied", Severities.SUCCESS);
    };

    const afterChangeAutopilotStatus = (status: boolean) => {
        if (status) {
            dispatch(getCollectionList(offset, pageSize, false, order, collectionId, field));
        }
    };

    const handleChangeAutopilotStatus = (values: collectionAutopilotStatusPayload, isDisable: boolean) => {
        if (isDisable === Boolean(values?.isAutopilot)) return;
        dispatch(collectionAutopilotStatus(values, afterChangeAutopilotStatus));
    };

    const renderHeader = () => {
        return (
            <TableHeaderTr>
                {/* <th>
                    <label className="custom_checkbox">
                        <input
                            type="checkbox"
                            checked={selectedCollection.length === colListLength}
                            onChange={() => {
                                if (allSelected) {
                                    setSelectedCollection([]);
                                } else {
                                    setSelectedCollection(colDataList?.map((collection: any) => collection?.id));
                                }
                            }}
                        />
                        <span
                            className={`checkmark ${colDataList?.length && selectedCollection.length === colDataList.length ? "" : ""}
                            ${selectedCollection.length > 0 && selectedCollection.length !== colDataList?.length ? "dash" : ""}`}
                        ></span>
                    </label>
                </th> */}
                <th>#</th>
                {activeTabName === "listed" && <th className="text-center">ACCOUNT STATUS</th>}
                <th className="external-link">
                    NAME
                    {/* <UpArrow
                        onClick={() => {
                            setOrder(order === "ASC" ? "DESC" : "ASC");
                            setField("name");
                        }}
                        className={order === "ASC" ? "DESC" : "ASC"}
                    /> */}
                </th>
                {activeTabName === "needsapproval" && (
                    <th className="sort-column">
                        submission date{" "}
                        <UpArrow
                            onClick={() => {
                                setField("createdAt");
                                setOffset(0);
                                setOrder(order === "ASC" ? "DESC" : "ASC");
                            }}
                            className={order === "ASC" && field === "createdAt" ? "ASCDate" : "DESCDate"}
                        />
                    </th>
                )}
                {activeTabName === "listed" && (
                    <th className="sort-column">
                        LISTING DATE
                        <UpArrow
                            onClick={() => {
                                setField("createdAt");
                                setOffset(0);
                                setOrder(order === "ASC" ? "DESC" : "ASC");
                            }}
                            className={order === "ASC" && field === "createdAt" ? "ASCDate" : "DESCDate"}
                        />
                    </th>
                )}
                {activeTabName === "listed" && <th className="text-center">AUTOPILOT</th>}
                <th>WALLET ADDRESS</th>
                {activeTabName === "needsapproval" && <th>links</th>}
                {activeTabName === "needsapproval" && <th>action</th>}
                <th className="text-center">DETAILS</th>
            </TableHeaderTr>
        );
    };

    const handleSearchCollection = debounce((e: any) => {
        setOffset(0);
        setSearch(e.target.value);
    }, 500);

    const renderTBody = (collection: any, index: number) => {
        return (
            <>
                <TableBodyTr key={collection.id}>
                    {/* <td>
                    <label className="custom_checkbox">
                        <input
                            checked={selectedCollection.includes(collection.id)}
                            type="checkbox"
                            onChange={() => {
                                if (selectedCollection.includes(collection.id)) {
                                    setSelectedCollection(selectedCollection.filter((item) => item !== collection.id));
                                } else {
                                    setSelectedCollection([...selectedCollection, collection.id]);
                                }
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </td> */}
                    <td className="indexnumber">#{index + 1}</td>
                    {/* <td>
                    <div>
                        <img src={collection?.logo_image_url} alt="" className="logo" width={30} height={30} />
                    </div>
                </td> */}
                    {activeTabName === "listed" && (
                        <td>
                            <CollectionStatus className="collectionStatus m-0 p-0">
                                <li>
                                    <span
                                        className={`cursor_pointer approved ${
                                            collection.status === AccountStatus.APPROVED ? "active disabled" : ""
                                        }`}
                                        onClick={() => handleStatusApprove(collection, AccountStatus.APPROVED)}
                                    >
                                        <Tooltip title={AccountStatus.APPROVED} placement="top">
                                            <Button>
                                                <Images.checkAutopilotIcon />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className={`cursor_pointer pending ${
                                            collection.status === AccountStatus.SUSPENDED ? "active disabled" : ""
                                        }`}
                                        onClick={() => {
                                            handleStatus(collection, AccountStatus.SUSPENDED);
                                        }}
                                    >
                                        <Tooltip title={"PAUSED"} placement="top">
                                            <Button>
                                                <Images.suspendAutopilotIcon />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className={`cursor_pointer declined ${
                                            collection.status === AccountStatus.DECLINED ? "active disabled" : ""
                                        }`}
                                        onClick={() => handleStatusApprove(collection, AccountStatus.DECLINED)}
                                    >
                                        <Tooltip title={AccountStatus.DECLINED} placement="top">
                                            <Button>
                                                <Images.rejectAutopilotIcon />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>
                                {/* <li>
                            <span
                                className={`cursor_pointer subscribed ${
                                    collection.status === AccountStatus.SUBSCRIBED ? "active disabled" : ""
                                }`}
                                onClick={() => {
                                    handleStatus(collection, AccountStatus.SUBSCRIBED);
                                }}
                            >
                                <Tooltip title={AccountStatus.SUBSCRIBED} placement="top">
                                    <Button>
                                        <MdOutlineSubscriptions />
                                    </Button>
                                </Tooltip>
                            </span>
                        </li>
                        <li>
                            <span
                                className={`cursor_pointer suspended ${
                                    collection.status === AccountStatus.SUSPENDED ? "active disabled" : ""
                                }`}
                                onClick={() => {
                                    handleStatus(collection, AccountStatus.SUSPENDED);
                                }}
                            >
                                <Tooltip title={AccountStatus.SUSPENDED} placement="top">
                                    <Button>
                                        <Images.suspendAutopilotIcon />
                                    </Button>
                                </Tooltip>
                            </span>
                        </li> */}
                            </CollectionStatus>
                        </td>
                    )}
                    <td className="noWrapText">{collection.name}</td>
                    {activeTabName === "listed" && (
                        <td className="noWrapText">
                            <img src={Images.calendar} alt="ActiveAutopilot" className="mx-2" />
                            {moment(collection?.createdAt).format("DD/MM/YY")}
                        </td>
                    )}
                    {activeTabName === "listed" && (
                        <td className="noWrapText">
                            <APCollectionStatus className="collectionStatus m-0 p-0">
                                <li>
                                    <span className={`cursor_pointer suspended ${collection?.isAutopilot ? "active" : ""} `}>
                                        <Tooltip title="Active" placement="top">
                                            <Button
                                                onClick={() =>
                                                    handleChangeAutopilotStatus(
                                                        { isAutopilot: 1, id: collection?.id },
                                                        collection?.isAutopilot
                                                    )
                                                }
                                            >
                                                <Images.activeAutopilot className="cursor_pointer" />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>
                                <li>
                                    <span className={`cursor_pointer ${!collection?.isAutopilot ? "subscribed active" : "suspended"} `}>
                                        <Tooltip title="Disable" placement="top">
                                            <Button
                                                onClick={() =>
                                                    handleChangeAutopilotStatus(
                                                        { isAutopilot: 0, id: collection?.id },
                                                        collection?.isAutopilot
                                                    )
                                                }
                                            >
                                                <Images.disableAutopilot className="cursor_pointer" />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>
                            </APCollectionStatus>
                        </td>
                    )}
                    {activeTabName === "needsapproval" && (
                        <td className="noWrapText">
                            <img src={Images.calendar} alt="ActiveAutopilot" className="mx-2" />
                            {moment(collection?.createdAt).format("DD/MM/YY")}
                        </td>
                    )}
                    <td>
                        <span className="contractAddrTab">
                            {collection.contractAddr.substring(0, 5) +
                                "..." +
                                collection.contractAddr.substring(collection.contractAddr.length - 5)}
                            <CopyToClipboard text={collection.contractAddr}>
                                <img src={Images.copyIcon} alt="Copy" className="cursor_pointer" onClick={copyHandler} />
                            </CopyToClipboard>
                        </span>
                    </td>
                    {activeTabName === "needsapproval" && (
                        <td>
                            <div className="d-flex align-items-center justify-content-start noWrap socialLinks">
                                {collection.facebook && (
                                    <a href={collection.facebook} className="mx-2" target="_blank" rel="noreferrer">
                                        <Tooltip title={SocialLinks.FACEBOOK.toUpperCase()} placement="top">
                                            <Button className="btn-unset" style={{ minWidth: "1px" }}>
                                                <FaFacebookSquare />
                                            </Button>
                                        </Tooltip>
                                    </a>
                                )}
                                {collection.opensea && (
                                    <a href={collection.opensea} className="mx-2" target="_blank" rel="noreferrer">
                                        <Tooltip title={SocialLinks.OPENSEA.toUpperCase()} placement="top">
                                            <Button className="btn-unset" style={{ minWidth: "1px" }}>
                                                <OpenSea />
                                            </Button>
                                        </Tooltip>
                                    </a>
                                )}
                                {collection.twitter && (
                                    <a href={collection.twitter} className="mx-2" target="_blank" rel="noreferrer">
                                        <Tooltip title={SocialLinks.TWITTER.toUpperCase()} placement="top">
                                            <Button className="btn-unset" style={{ minWidth: "1px" }}>
                                                <FaTwitter />
                                            </Button>
                                        </Tooltip>
                                    </a>
                                )}
                            </div>
                        </td>
                    )}
                    {activeTabName === "needsapproval" && (
                        <td>
                            <CollectionStatus className="collectionStatus m-0 p-0">
                                <li>
                                    <span
                                        className={`cursor_pointer approved selected ${
                                            collection.status === AccountStatus.APPROVED ? "disabled" : ""
                                        }`}
                                        onClick={() => {
                                            handleStatusApprove(collection, AccountStatus.APPROVED);
                                        }}
                                    >
                                        <Tooltip title={AccountStatus.APPROVED} placement="top">
                                            <Button>
                                                <Images.checkAutopilotIcon />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                </li>{" "}
                                <li>
                                    <span
                                        className={`cursor_pointer declined selected ${
                                            collection.status === AccountStatus.DECLINED ? "disabled" : ""
                                        }`}
                                        onClick={() => {
                                            setDeclineCollectionName(collection.name);
                                            setDeclineCollection(collection);
                                            setShowDeclineModal(true);
                                        }}
                                    >
                                        <Tooltip title={AccountStatus.DECLINED} placement="top">
                                            <Button>
                                                <Images.rejectAutopilotIcon />
                                            </Button>
                                        </Tooltip>{" "}
                                        {/* 12345678 */}
                                    </span>
                                </li>
                            </CollectionStatus>
                        </td>
                    )}
                    <td>
                        <Link
                            to={`/collections/edit-collection/${collection?.id}`}
                            state={{ data: { offset, pageSize } }}
                            className="mx-2 color-primary text-center eye-action-icon"
                        >
                            <Tooltip title="View" placement="top">
                                <img src={Images.eyeIcon} alt="ActiveAutopilot" className="cursor_pointer" />
                            </Tooltip>
                        </Link>
                    </td>
                    {/* <td className="text-center">{collection.blockchain}</td> */}
                    {/* <td className="text-center">{collection.tokenStandard}</td> */}
                    {/* <td>{collection.description.length > 25 ? collection.description.substring(0, 25) + "..." : null}</td> */}
                    {/* <td className="action-icon d-flex justify-content-center">
                    <div className="d-flex noWrap align-items-center">
                        <Link to={`/collections/edit-collection/${collection?.id}`} className="mx-2 color-primary">
                            <FaEdit />
                        </Link>
                        <DeleteIcon
                            onClick={() => {
                                setShow(true);
                                setCollectionId(collection?.id);
                                setCollectionName(collection?.name);
                            }}
                            className="mx-2 cursor_pointer"
                        />
                    </div>
                </td> */}
                    {/* <td className="Switchicon text-center">
                    <label className="switch">
                        <input type="checkbox" checked={collection?.isEnable} onChange={() => handleSwitch(collection)} />
                        <span className="slider round"></span>
                    </label>
                </td> */}
                </TableBodyTr>
            </>
        );
    };
    return (
        <>
            <Topbar
                selectedBrand={selectedCollection.length}
                classname={selectedCollection?.length ? "" : "d-none"}
                handleDeleteMultipleBrand={handleDeleteMultipleCollection}
                setSelectedBrand={setSelectedCollection}
                handleExportCSV={handleExportCSV}
                exportCSV={true}
                bulkDelete={true}
            />
            <CollectionPage className="d-flex flex-column">
                <CollectionSection>
                    <CollectionTitle className="d-flex justify-content-between w-100 align-items-center">
                        <h2>
                            Collections{" "}
                            {collectionDataList?.total && (
                                <>
                                    <sub className="ms-2">
                                        <span>
                                            (<p> {collectionDataList?.result?.count}</p>)
                                        </span>
                                    </sub>
                                </>
                            )}
                        </h2>
                        <Form>
                            <Form.Group className="searchbox">
                                <Form.Control type="text" className="mb-0" placeholder="Search by name" onChange={handleSearchCollection} />
                                <span>
                                    <Search />
                                </span>
                            </Form.Group>
                        </Form>
                    </CollectionTitle>
                </CollectionSection>
                <CollectionFilterTab>
                    {collectionFilterTabs?.map((tab: CollectionFilterTabs) => {
                        const isNotifyDot = tab?.tabName === "Needs Approval" && collectionDataList?.needsApprovalCollectionCount > 0;
                        return (
                            <FilterTab
                                key={tab?.key}
                                onClick={(e) => {
                                    setActiveFilterTab((e.target as HTMLElement)?.innerText);
                                }}
                                className={`cursor_pointer ${tab?.tabName === activeFilterTab ? "active" : ""} ${
                                    isNotifyDot ? "notifyDot" : ""
                                }`}
                            >
                                {tab?.tabName}
                            </FilterTab>
                        );
                    })}
                </CollectionFilterTab>
                <Table
                    renderHeader={renderHeader}
                    renderData={renderTBody}
                    data={collectionDataList}
                    isLoading={isLoading}
                    offset={offset}
                    pageSize={pageSize}
                    setOffset={setOffset}
                    setPageSize={setPageSize}
                    setSelected={setSelectedItems}
                />
                <AlertModal
                    open={show}
                    string={"Remove collection?"}
                    setOpen={setShow}
                    handleRemove={handleYesFunction}
                    description={""}
                    image={Images.removeIcon}
                    closeButton={"Cancel"}
                    yesButton={"Remove"}
                />
                <AlertModal
                    open={enableModalShow}
                    string={"Disable collection"}
                    setOpen={setEnableModalShow}
                    handleRemove={handleEnableCollection}
                    description={`${collectionName}`}
                    closeButton={"No"}
                    yesButton={"Yes"}
                />
                <AlertModal
                    open={enableMultipleCollectionShow}
                    string={`Remove ${selectedCollection?.length} collection${selectedCollection?.length > 1 ? "s" : ""}?`}
                    setOpen={setEnableMultipleCollectionShow}
                    handleRemove={handleRemoveMultipleBrands}
                    image={Images.removeIcon1}
                    closeButton={"Cancel"}
                    yesButton={"Remove"}
                />
                <UploadModel
                    show={showUploadModal}
                    setShow={setShowUploadModal}
                    handleUploadCsv={handleUploadCsv}
                    csv={csv}
                    setCsv={setCsv}
                    uploadCsv={uploadCsv}
                    setCsvFile={setCsvFile}
                />
                <AlertModal
                    open={showDeclineModal}
                    setOpen={() => setShowDeclineModal(false)}
                    string={"Decline?"}
                    description={`Listing request for ${declineCollectionName} will be declined`}
                    handleRemove={() => {
                        handleStatusApprove(declineCollection, AccountStatus.DECLINED);
                        setShowDeclineModal(false);
                    }}
                    image={Images.decline}
                    closeButton="Cancel"
                    yesButton={isLoading ? <Spinner /> : "Decline"}
                />
            </CollectionPage>
        </>
    );
};

export default Collections;
