import { Button, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";

import { ReactComponent as Hodlperks } from "../../assets/images/hodlperks.svg";
import { login } from "../../store/actions/auth/Auth";
import { AppDispatch } from "../../types/model";
import Images from "../../config/images";

import "react-toastify/dist/ReactToastify.css";
import { useState, ReactElement } from "react";
import FONTS from "../../config/fonts";
import Spinner from "../../components/common/Spinner";

const Loginpage = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991.98px) {
        margin: 30px 0;
    }

    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
`;
const Logincontent = styled.div`
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991.98px) {
        display: inherit;
    }

    form {
        width: 406px;

        @media (max-width: 991.98px) {
            margin: 0 auto;
        }

        @media (max-width: 575.98px) {
            width: initial;
        }
    }
`;
const Logo = styled.div`
    text-align: center;
    margin-bottom: 48px;

    svg {
        width: 406px;
        height: auto;
    }
`;
const Admintype = styled.p`
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    text-align: left;
    margin-top: 25px;
    font-family: ${FONTS.interFont};
    margin-bottom: 0;

    @media (max-width: 991.98px) {
        text-align: center;
    }
`;

const Passwordeye = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 85px;
    height: 32px;
    margin: auto;
    text-align: center;
`;

const InputIconField = styled.div`
    position: relative;

    .form-control {
        padding-right: 65px;
    }
`;

const Login = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const loginValidationSchema = Yup.object().shape({
        email: Yup.string().email("Please enter valid email").required("Please enter email"),
        password: Yup.string().min(8, "Password should be minimum 8 characters long.").required("Please enter password")
    });

    const [passwordShown, setPasswordShown] = useState(false);

    const handlePasswordShow = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <Loginpage>
            <Container>
                <Logincontent>
                    <Logo>
                        <Hodlperks />
                        <Admintype>super admin</Admintype>
                    </Logo>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={loginValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            dispatch(login(values));
                            setSubmitting(false);
                        }}
                    >
                        {({ values, touched, handleSubmit, isSubmitting, errors, handleChange, handleBlur }) => (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            autoComplete={"off"}
                                            placeholder=""
                                            value={values.email}
                                        />
                                        {errors.email && touched.email && (
                                            <div id="InputFeedback" className="errorMessage">
                                                <img src={Images.errorIcon} /> {errors.email}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Password</Form.Label>
                                        <InputIconField>
                                            <Form.Control
                                                type={passwordShown ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                            />
                                            <Passwordeye onClick={handlePasswordShow} className={values.password === "" ? "d-none" : ""}>
                                                <img src={passwordShown ? Images.passwordHide : Images.passwordShow} />
                                            </Passwordeye>
                                        </InputIconField>
                                        {errors.password && touched.password && (
                                            <div id="InputFeedback" className="errorMessage">
                                                <img src={Images.errorIcon} /> {errors.password}
                                            </div>
                                        )}
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            isSubmitting ||
                                            !!(errors.email && touched.email) ||
                                            !!(errors.password && touched.password) ||
                                            (values.email === "" && values.password === "")
                                        }
                                    >
                                        {isSubmitting ? <Spinner /> : <>Log in</>}
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Logincontent>
            </Container>
        </Loginpage>
    );
};

export default Login;
