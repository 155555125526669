import { ReactElement, useEffect, useState } from "react";
import { AppDispatch } from "../../types/model";
import { useDispatch } from "react-redux";
import { multipleBrandDelete as multiplePerkDelete, exportCSV, listPerk, readAPI, uploadPerkCSV } from "../../store/actions/dashboard";
import { Button, Col, Row } from "react-bootstrap";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/eyeIcon.svg";
import { ReactComponent as UpArrow } from "../../assets/images/upArrow.svg";
import AlertModal from "../../components/alertModal";
import Images from "../../config/images";
import moment from "moment";
import Table from "../../components/table";
import { Topbar } from "./../../components/common/Header";
import UploadModel from "../../components/uploadModel";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/common/Spinner";
import useTable from "../../hooks/useTable";
import { AutopilotPage, AutopilotTab, AutopilotTabSection, ButtonSection, DropdownMainSection, DropdownSection, Title } from "./styled";
import { AUTOPILOT_TYPE, PerkListParams, PerkListPayload } from "./types";
import { Tooltip } from "@mui/material";
import CategorySelector from "../../components/categorySelector";
import { SelectorDropDown } from "../indexStyle";
import SelectSearch from "react-select-search";

const Autopilot = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const [data, setData] = useState<any>(null);
    const [collections, setCollections] = useState<any[]>([]);
    const [type, setType] = useState<AUTOPILOT_TYPE>(location?.state?.data?.type || AUTOPILOT_TYPE.ACTIVE);
    const [collectionId, setCollectionId] = useState<any>(location?.state?.data?.collectionId || "");
    const [categoryId, setCategoryId] = useState<number | string>(location?.state?.data?.categoryId || "");

    const [showDeletePerkModal, setShowDeletePerkModal] = useState<{ ids: number[]; title: string; show: boolean }>({
        ids: [],
        title: "",
        show: false
    });
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [csv, setCsv] = useState("");
    const [csvFile, setCsvFile] = useState("");
    const [isSubmitting, setIsSubmittig] = useState(false);

    useEffect(() => {
        const tabSessionStatus = sessionStorage.getItem("tabStatus");
        const tabStatus: any = tabSessionStatus === null || tabSessionStatus === undefined ? type : tabSessionStatus;
        setType(tabStatus);
        setTimeout(() => {
            sessionStorage.removeItem("tabStatus");
        }, 500);
    }, []);

    const {
        pageSize,
        setPageSize,
        offset,
        setOffset,
        order,
        setOrder,
        field,
        setField,
        selectedItems,
        setSelectedItems,
        isLoading,
        setIsLoading
    } = useTable();

    const handlePostAction = () => {
        setCategoryId("");
        setCollectionId("");
        setOffset(0);
        setField("");
        setOrder("ASC");
        setShowUploadModal(false);
        setCsv("");
        setCsvFile("");
        setSelectedItems([]);
        setIsSubmittig(false);
        setShowDeletePerkModal({ title: "", ids: [], show: false });
        getPerkList();
    };

    const handleRemovePerks = () => {
        setIsSubmittig(true);
        const payload: any = {
            modelName: "perk",
            ids: showDeletePerkModal?.ids
        };

        dispatch(multiplePerkDelete(payload, handlePostAction));
    };

    const handleExportCSV = () => {
        const payload: any = {
            type: "perk",
            id: selectedItems
        };
        dispatch(
            exportCSV(payload, (res: any) => {
                setSelectedItems([]);
                window.open(res?.data, "_blank");
            })
        );
    };

    const uploadCsv = () => {
        setIsLoading(true);
        const formData: any = new FormData();
        formData.append("logo", csvFile);
        dispatch(uploadPerkCSV(formData, handlePostAction));
    };

    const handleUploadCsv = (e: any) => {
        setCsv(e.name);
        setCsvFile(e);
    };

    const handleChangeType = (type: AUTOPILOT_TYPE) => {
        setType(type);
        setOffset(0);
        setOrder("ASC");
        setField("");
        setSelectedItems([]);
    };

    const getPerkList = () => {
        setIsLoading(true);
        const payload: PerkListPayload = {
            type,
            isAutoPilot: 1,
            collectionId,
            categoryId
        };
        const params: PerkListParams = {
            offset,
            limit: pageSize,
            field,
            order
        };
        dispatch(
            listPerk(payload, params, (res) => {
                setData(res);
                setIsLoading(false);
                handleScrollPosition();
            })
        );
    };

    useEffect(() => {
        getPerkList();
    }, [type, collectionId, offset, pageSize, field, order, categoryId]);

    useEffect(() => {
        dispatch(
            readAPI({ modelName: "collection" }, (response: any) => {
                const options = response?.result?.map((item: any) => ({ value: item?.id, name: item?.name })) || [];
                setCollections(options);
            })
        );

        if (location?.state?.data) {
            setPageSize(location?.state?.data?.pageSize || pageSize);
            setOffset(location?.state?.data?.offset || offset);
            navigate("/autopilot");
        }
    }, []);

    const [indexPoint, setIndexPoint] = useState<number>();

    const handleChange = (e: any, id: number) => {
        const sIndex = data?.result?.rows?.findIndex((item: any) => item.id === id);
        setIndexPoint(sIndex);

        const eIndex = data?.result?.rows?.findIndex((item: any) => item.id === id);
        const sliceStartIndex = indexPoint && indexPoint > eIndex ? eIndex : indexPoint;
        const sliceEndIndex = indexPoint && indexPoint > eIndex ? indexPoint : eIndex;

        const slicedArr = data?.result?.rows?.slice(sliceStartIndex, sliceEndIndex + 1)?.map((item: any) => item.id);

        if (selectedItems.includes(id)) {
            setSelectedItems((oItems) => oItems.filter((itemId) => itemId !== id));

            if (e.nativeEvent.shiftKey) {
                if (indexPoint !== undefined) {
                    setSelectedItems((oItems) => oItems.filter((id) => !slicedArr.some((i: any) => i === id)));
                }
            }
        } else {
            setSelectedItems([...new Set([...selectedItems, id])]);
            if (e.nativeEvent.shiftKey) {
                if (indexPoint !== undefined) {
                    setSelectedItems([...new Set([...selectedItems, ...slicedArr])]);
                }
            }
        }
    };

    const renderAutopilotHeader = () => {
        return (
            <tr>
                <th>
                    <label className="custom_checkbox">
                        <input
                            type="checkbox"
                            checked={selectedItems.length !== 0 && selectedItems.length === data?.result.rows.length}
                            onChange={() => {
                                if (selectedItems.length === data?.result.rows.length) {
                                    setSelectedItems([]);
                                } else {
                                    setSelectedItems(data?.result?.rows?.map((perk: any) => perk.id) ?? []);
                                }
                            }}
                        />
                        <span
                            className={`checkmark ${
                                data?.result?.rows?.length && selectedItems.length === data?.result.rows.length ? "" : ""
                            } ${selectedItems.length > 0 && selectedItems.length !== data?.result.rows.length ? "dash" : ""}`}
                        ></span>
                    </label>
                </th>
                <th>#</th>
                <th>Category</th>
                <th>perk name</th>
                <th className="date-createdAt">
                    End date
                    <UpArrow
                        onClick={() => {
                            setSelectedItems([]);
                            setOffset(0);
                            setOrder(order === "ASC" ? "DESC" : "ASC");
                            setField("expiryDate");
                        }}
                        className={order === "ASC" && field === "expiryDate" ? "ASCDate" : "DESCDate"}
                    />
                </th>
                <th className="date-createdAt total-amount">
                    total qty
                    <UpArrow
                        onClick={() => {
                            setOffset(0);
                            setSelectedItems([]);
                            setOrder(order === "ASC" ? "DESC" : "ASC");
                            setField("total");
                        }}
                        className={order === "ASC" && field === "total" ? "ASCDate" : "DESCDate"}
                    />
                </th>
                <th className="text-center">Collections</th>
                <th className="text-center">Action</th>
            </tr>
        );
    };

    const handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            setTimeout(() => {
                sessionStorage.removeItem("scrollPosition");
            }, 1);
        }
    };
    const handlePageYOffset = () => {
        sessionStorage.setItem("scrollPosition", String(window.pageYOffset));
    };

    const renderAutopilotData = (perk: any, index: number) => {
        return (
            <tr key={perk.id}>
                <td>
                    <div className={`custom_checkbox ${selectedItems.includes(perk?.id) && "shiftCheckmark"}`}>
                        <input type="checkbox" checked={selectedItems.includes(perk?.id)} onChange={(e) => handleChange(e, perk?.id)} />
                    </div>
                </td>
                <td className="indexnumber">#{offset + index + 1}</td>
                <td>{perk?.category?.name}</td>
                <td>{perk?.title}</td>
                <td className="noWrapText">
                    {perk?.expiryDate ? (
                        <>
                            <img src={Images.calendar} style={{ marginRight: "11px", verticalAlign: "sub" }} />
                            {moment(perk?.expiryDate).format("DD/MM/YY")}
                        </>
                    ) : (
                        "N/A"
                    )}
                </td>
                <td align="right">{perk?.total || 0}</td>
                <td align="center">{perk?.collectionsCount || 0}</td>
                <td className="action-icon d-flex justify-content-center">
                    <Tooltip title="View" placement="top">
                        <EyeIcon
                            className="eye-icon"
                            onClick={() => {
                                handlePageYOffset();
                                navigate(`/edit-perk/${perk.id}`, {
                                    state: { data: { type, offset, pageSize, collectionId, categoryId }, from: "/autopilot" }
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                        <DeleteIcon onClick={() => setShowDeletePerkModal({ show: true, title: perk?.title, ids: [perk?.id] })} />
                    </Tooltip>
                </td>
            </tr>
        );
    };

    if (typeof data === "undefined") return <></>;

    return (
        <>
            <Topbar
                selectedBrand={selectedItems.length}
                classname={selectedItems?.length ? "" : "d-none"}
                handleDeleteMultipleBrand={() =>
                    setShowDeletePerkModal({ show: true, title: selectedItems?.length?.toString(), ids: selectedItems })
                }
                setSelectedBrand={setSelectedItems}
                handleExportCSV={handleExportCSV}
                exportCSV={true}
                bulkDelete={true}
            />

            <AutopilotPage className="d-flex flex-column">
                <AutopilotTabSection>
                    <Row className="align-items-end">
                        <Col md={6}>
                            <Title>Autopilot</Title>
                            <AutopilotTab>
                                <h4
                                    className={type === AUTOPILOT_TYPE.ACTIVE ? "active" : ""}
                                    onClick={() => handleChangeType(AUTOPILOT_TYPE.ACTIVE)}
                                >
                                    Active{" "}
                                    <span>
                                        (<p>{data?.activeCount || 0}</p>)
                                    </span>
                                </h4>
                                <h4
                                    className={type === AUTOPILOT_TYPE.SCHEDULED ? "active" : ""}
                                    onClick={() => handleChangeType(AUTOPILOT_TYPE.SCHEDULED)}
                                >
                                    Scheduled{" "}
                                    <span>
                                        (<p>{data?.scheduledCount || 0}</p>)
                                    </span>
                                </h4>
                                <h4
                                    className={type === AUTOPILOT_TYPE.COMPLETED ? "active" : ""}
                                    onClick={() => handleChangeType(AUTOPILOT_TYPE.COMPLETED)}
                                >
                                    Completed{" "}
                                    <span>
                                        (<p>{data?.completedCount || 0}</p>)
                                    </span>
                                </h4>
                                <h4
                                    className={type === AUTOPILOT_TYPE.DRAFT ? "active" : ""}
                                    onClick={() => handleChangeType(AUTOPILOT_TYPE.DRAFT)}
                                >
                                    Drafts{" "}
                                    <span>
                                        (<p>{data?.draftCount || 0}</p>)
                                    </span>
                                </h4>
                            </AutopilotTab>
                        </Col>
                        <Col md={6}>
                            <ButtonSection>
                                <div className="image-upload" onClick={() => setShowUploadModal(true)}>
                                    <label>
                                        <img src={Images.upload} />
                                        Upload CSV
                                    </label>
                                </div>
                                <span className="loader"></span>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={() =>
                                        navigate("/create-perk", {
                                            state: { data: { type, offset, pageSize, collectionId, categoryId }, from: "/autopilot" }
                                        })
                                    }
                                >
                                    <Plus />
                                    Add perk
                                </Button>
                            </ButtonSection>
                        </Col>
                    </Row>
                </AutopilotTabSection>
                <DropdownMainSection>
                    <DropdownSection>
                        <SelectorDropDown>
                            <SelectSearch
                                value={collectionId}
                                className="select-search custom-select-search"
                                options={collections}
                                search={true}
                                placeholder="Select Collection"
                                onChange={(id: any) => {
                                    setOffset(0);
                                    setCollectionId(id);
                                    setSelectedItems([]);
                                }}
                                emptyMessage="No category found"
                            />
                        </SelectorDropDown>
                    </DropdownSection>
                    <DropdownSection>
                        <SelectorDropDown>
                            <CategorySelector categoryId={categoryId} setFieldValue={setCategoryId}></CategorySelector>
                        </SelectorDropDown>
                    </DropdownSection>
                </DropdownMainSection>
                <Table
                    renderHeader={renderAutopilotHeader}
                    renderData={renderAutopilotData}
                    data={data}
                    isLoading={isLoading}
                    pageSize={pageSize}
                    offset={offset}
                    setPageSize={setPageSize}
                    setOffset={setOffset}
                    setSelected={setSelectedItems}
                />
            </AutopilotPage>
            <AlertModal
                open={showDeletePerkModal?.show}
                string={`Remove ${selectedItems?.length || showDeletePerkModal?.title} perk${
                    showDeletePerkModal?.ids?.length > 1 ? "'s" : ""
                }?`}
                setOpen={() => setShowDeletePerkModal({ title: "", ids: [], show: false })}
                handleRemove={handleRemovePerks}
                image={Images.removeIcon1}
                closeButton={"Cancel"}
                yesButton={isSubmitting ? <Spinner /> : "Remove"}
            />
            <UploadModel
                show={showUploadModal}
                setShow={setShowUploadModal}
                handleUploadCsv={handleUploadCsv}
                csv={csv}
                setCsv={setCsv}
                uploadCsv={uploadCsv}
                setCsvFile={setCsvFile}
            />
        </>
    );
};

export default Autopilot;
