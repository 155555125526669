/* eslint-disable max-len */
import styled from "styled-components";
import FONTS from "./../config/fonts";
import Chevrondown from "../assets/images/chevrondown.svg";
import Images from "../config/images";

export const BrandPage = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    input {
                        cursor: pointer;
                        background: red;
                    }
                    &:first-child {
                        border-radius: 16px 0 0 16px;
                        vertical-align: middle;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }
        tr {
            .logo {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASC {
                    transform: rotate(180deg);
                }
            }
        }
        .date-createdAt {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASCDate {
                    transform: rotate(180deg);
                }
            }
        }

        .action-icon {
            display: flex;

            svg {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }
        }

        .Switchicon {
            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }
        }
    }
`;

export const BrandTitle = styled.div`
    h2 {
        display: flex;
        align-items: center;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;
    }

    span {
        display: flex;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    p {
        color: #d52b2b;
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }
`;

export const ButtonSection = styled.div`
    display: flex;

    .image-upload {
        width: 235px;
        height: 64px;
        background-color: #ffffff;
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;
        cursor: pointer;

        img {
            margin-right: 15px;
        }
    }

    button {
        width: 210px;
        height: 64px;
        margin-left: 31px;

        svg {
            margin-right: 15px;
        }
    }
`;

export const PageNumber = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 64px;

    h6 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 0;
    }
`;

export const PageSelect = styled.div`
    width: 125px;
    padding-right: 16px;

    select {
        width: 100%;
        height: 48px;
        background: #2f2f2f;
        border: 1px solid rgba(95, 90, 90, 0.4);
        border-radius: 8px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        padding: 12px 16px 14px 24px;
        appearance: none;
        background-image: url("${Chevrondown}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;

        &:focus-visible {
            outline: none;
        }
    }
`;

export const PaginationPage = styled.div`
    margin-bottom: 49px;

    ul {
        justify-content: center;
    }

    .page-link {
        background: #373636;
        border: 1px solid #4f4f4f;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        width: 65.6px;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page-item.disabled .page-link {
        background: #221f1f;
        border: 1px solid #4f4f4f;
    }

    .page-item.active .page-link {
        background: #d52b2b;
        border: transparent;
    }

    .page-link:focus {
        box-shadow: none;
    }
`;

export const DropdownSection = styled.div`
    width: 268px;
    padding-bottom: 25px;

    select {
        width: 100% !important;
        height: 48px;
        background: #2f2f2f;
        border: 1px solid rgba(95, 90, 90, 0.4);
        border-radius: 8px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        padding: 12px 16px 14px 24px;
        appearance: none;
        background-image: url("${Chevrondown}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;

        &:focus-visible {
            outline: none;
        }
    }
`;

export const BrandSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
`;

export const CollectionPage = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    input {
                        cursor: pointer;
                        background: red;
                    }
                    &:first-child {
                        border-radius: 16px 0 0 16px;
                        vertical-align: middle;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }
        tr {
            .logo {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASC {
                    transform: rotate(180deg);
                }
            }
        }
        .date-createdAt {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASCDate {
                    transform: rotate(180deg);
                }
            }
        }

        .action-icon {
            display: flex;

            svg {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }
        }

        .Switchicon {
            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }
        }
    }
`;

export const CollectionTitle = styled.div`
    h2 {
        display: flex;
        align-items: center;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;
    }

    span {
        display: flex;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    p {
        color: #d52b2b;
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }
    .searchbox {
        input {
            background: rgba(73, 65, 65, 0.4);
            border: 2px solid var(--form-bordercolor);
            border-radius: 48px;
            height: 64px;
            margin-bottom: 32px;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: var(--white-color);
        }
    }
`;

export const CollectionSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const DetailPage = styled.div`
    .checkbox-field {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        p {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            margin: 0 0 0 16px;
        }
    }

    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    .form-control:disabled {
        background: rgba(73, 65, 65, 0.4);
        -webkit-text-fill-color: #ffffff;
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button {
        opacity: 1;
    }

    input::-webkit-calendar-picker-indicator {
        background: url("${Images.calendar}") no-repeat;
        width: 32px;
        height: 32px;
        position: relative;
        top: 7px;
        right: -10px;
    }

    textarea.form-control {
        min-height: 124px;
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 24px;
        margin-bottom: 32px;
        color: #ffffff;
    }

    .radio {
        :not(:last-child) {
            margin-right: 48px;
        }
        label {
            display: flex;
            align-items: center;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;

            input {
                height: unset;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    form {
        .describedata {
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.75);
        }

        .infonumber {
            display: flex;
            justify-content: space-between;

            .number {
                margin-right: 27px;
            }
        }
    }

    .errorMessage {
        position: relative;
        top: -25px;
        color: red;
    }

    .dategroup {
        input {
            display: flex;
            align-items: center;
            /* -webkit-text-fill-color: #ffffff; */
        }
    }
`;

export const InfoText = styled.span`
    font-family: ${FONTS.latoFont};
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.75);
`;

export const DetailHeader = styled.div`
    margin-bottom: 51px;

    .detailHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767.98px) {
            display: initial;
        }

        button {
            width: 218px;
        }
    }

    &.sticky {
        background-color: white;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
        transition: all 0.5s ease;
        animation: smoothScroll 0.5s forwards;
        padding: 28px 0;

        @keyframes smoothScroll {
            0% {
                transform: translateY(-142px);
            }

            100% {
                transform: translateY(0px);
            }
        }

        h1 {
            color: #221f1f;
        }
    }
`;

export const FormSection = styled.div`
    max-width: 598px;
    margin-left: 109px;

    @media (max-width: 767.98px) {
        margin: 0 auto;
    }
`;

export const ProfileCircle = styled.div`
    a {
        width: 56px;
        height: 56px;
        background: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 53px;
    }
`;

export const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;

    @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 46px;
    }
`;

export const DetailInfo = styled.div`
    display: flex;
`;
export const SaveInfoBtnWrapper = styled.div`
    height: 64px;
`;

export const BannerCont = styled.div`
    position: relative;
    width: 100%;
    height: 205px;
    background: rgba(47, 47, 47, 0.4);
    margin: 50px 0 0;
    border: 3px dashed rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    & input {
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;
        overflow: hidden;
        z-index: -1;
    }
    & img {
        object-fit: cover;
    }
    & .banner {
        width: 100%;
        height: 100%;
        z-index: 5;
    }
    & .addBtn {
        z-index: 10;
        position: absolute;
        right: 10px;
        top: 15px;
        background: transparent;
        display: flex;
        align-items: center;
        margin-left: auto;
        color: #d52b2b;
        border: 0;
        cursor: pointer;
        & img {
            height: 14px;
            width: 14px;
            margin-left: 10px;
            margin-right: 10px;
        }
        & p {
            margin: 0;
        }
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const ImgCont = styled.label`
    background-color: rgba(47, 47, 47, 0.4);
    border-radius: 50%;
    width: 172px;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 30px;
    border: 3px dashed rgba(255, 255, 255, 0.2);
    z-index: 9;
    cursor: pointer;
    & input {
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;
        overflow: hidden;
        z-index: -1;
    }
    @media only screen and (max-width: 576px) {
        width: 132px;
        height: 132px;
        & .plus {
            width: 32px;
            height: 32px;
        }
    }
    & .plus {
        height: 32px;
        width: 32px;
        margin-bottom: 5px;
    }
    & .logo {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    & p {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #d52b2b;
        width: 100%;
        text-align: center;
    }
`;

export const RadioButton = styled.div`
    display: flex;
    margin-bottom: 48px;
`;

export const ProfileCont = styled.div`
    position: relative;
    margin-bottom: 30px;
`;

export const ProfileAdd = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -88px;
`;

export const EditFormData = styled.div`
    max-width: 624px;
    margin-left: 109px;

    @media (max-width: 767.98px) {
        margin: 0 auto;
    }
`;

export const RulesStyled = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    input {
                        cursor: pointer;
                        background: red;
                    }
                    &:first-child {
                        border-radius: 16px 0 0 16px;
                        vertical-align: middle;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }
        tr {
            .logo {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASC {
                    transform: rotate(180deg);
                }
            }
        }
        .date-createdAt {
            display: flex;
            align-items: center;
            width: 170px;

            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASCDate {
                    transform: rotate(180deg);
                }
            }
        }

        .action-icon {
            display: flex;

            svg {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }
        }

        .Switchicon {
            text-align: center;

            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }
        }
    }

    .preferredrulebutton {
        width: 100%;
    }

    .text-center {
        text-align: center;
    }
`;

export const RuleTitle = styled.div`
    h2 {
        display: flex;
        align-items: center;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;
    }

    span {
        display: flex;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    p {
        color: #d52b2b;
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }
`;

export const RuleSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
`;

export const CreateBox = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
    }

    h3 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;
        padding: 0 50px;

        @media (max-width: 767.98px) {
            font-size: 23px;
            line-height: 30px;
            margin-bottom: 32px;
        }
    }

    .rulebox {
        @media (max-width: 767.98px) {
            width: 100%;
        }
    }
`;

export const RuleCreatePage = styled.div`
    margin-bottom: 30px;

    form {
        max-width: 624px;
        margin-left: 109px;

        @media (max-width: 767.98px) {
            margin: 0 auto;
        }
    }

    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button {
        opacity: 0;
    }
`;

export const ErrorMessage = styled.div`
    position: relative;
    top: -25px;
    color: red;
`;

export const CategoryWraper = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    input {
                        cursor: pointer;
                        background: red;
                    }
                    &:first-child {
                        border-radius: 16px 0 0 16px;
                        vertical-align: middle;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }
        tr {
            .logo {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASC {
                    transform: rotate(180deg);
                }
            }
        }
        .date-createdAt {
            display: flex;
            align-items: center;
            width: 170px;

            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASCDate {
                    transform: rotate(180deg);
                }
            }
        }

        .action-icon {
            display: flex;

            svg {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }
        }

        .Switchicon {
            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }
        }
    }
`;

export const CategoryTitle = styled.div`
    h2 {
        display: flex;
        align-items: center;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;
    }

    span {
        display: flex;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    p {
        color: #d52b2b;
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }
`;

export const CategoryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

export const CollectionStatus = styled.ul`
    span {
        width: 40px !important;
        height: 40px !important;

        &.approved {
            svg {
                fill: none !important;
            }
            border: 1px solid #828282 !important;
            &.active {
                border: 1px solid #54b864 !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
            &.selected {
                border: 1px solid #54b864 !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
        }
        &.declined {
            border: 1px solid #828282 !important;

            svg {
                fill: none !important;
            }
            &.active {
                background-color: #838383 !important;
                border: 1px solid #838383 !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
            &.selected {
                background-color: transparent !important;
                border: 1px solid #d52b2b !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
        }
        &.suspended {
            border: 1px solid #828282 !important;
            &.active {
                background-color: #c80c2d !important;
                border: 1px solid #838383 !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
`;
export const APCollectionStatus = styled.ul`
    span {
        width: 40px !important;
        height: 40px !important;

        &.suspended {
            border: 1px solid #828282 !important;

            svg {
                fill: #838383;
            }
            &.active {
                background-color: #fff !important;
                border: 1px solid #fff !important;
                svg {
                    fill: none !important;
                    filter: brightness(0);
                }
            }
        }
        &.subscribed {
            border: 1px solid #828282 !important;
            svg {
                fill: #838383;
            }
            &.active {
                background-color: #c80c2d !important;
                border: 1px solid #c80c2d !important;
                svg {
                    fill: none !important;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
`;

export const TableHeaderTr = styled.tr`
    th {
        vertical-align: middle !important ;
    }
`;
export const TableBodyTr = styled.tr`
    &:first-child {
        td {
            padding-top: 32px !important;
        }
    }
    td {
        padding-top: 13px;
        padding-bottom: 13px;
        vertical-align: middle !important;

        .eye-action-icon {
            filter: brightness(0) invert(1);
        }

        .contractAddrTab {
            max-width: 200px !important;
            width: 100% !important;
        }
    }
`;

export const CollectionFilterTab = styled.div`
    display: flex;
    justify-content: start;
    margin-bottom: 25px;
`;
export const FilterTab = styled.div`
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    padding: 0 16px 10px 16px;
    &.active {
        color: #fff;
        border-bottom: 2px solid #d52b2b;
    }
    &.notifyDot {
        &:after {
            content: "";
            height: 15px;
            width: 15px;
            border-radius: 100%;
            background: var(--button-bgcolor);
            display: inline-block;
            margin-left: 10px;
            margin-bottom: -1px;
        }
    }
`;

export const SelectorDropDown = styled.div`
    input {
        background: rgba(73, 65, 65, 0.4);
    }

    /**
 * Main wrapper
 */

    .select-search {
        --select-search-theme-background: #fef5e6;
        --select-search-background: #ffffff;
        --select-search-border: #dce0e8;
        --select-search-selected: #3e1810;
        --select-search-text: #000;
        --select-search-subtle-text: #6c6f85;
        --select-search-inverted-text: var(--select-search-background);
        --select-search-highlight: #eff1f5;
        --select-search-font: "Poppins", sans-serif;

        width: 100%;
        max-width: 446px;
        position: relative;
        font-family: var(--select-search-font);
        color: var(--select-search-text);
        box-sizing: border-box;
    }

    @supports (font-variation-settings: normal) {
        .select-search {
            --select-search-font: "Poppins", sans-serif;
        }
    }

    @media (prefers-color-scheme: dark) {
        .select-search {
            --select-search-background: #000;
            --select-search-border: #313244;
            --select-search-selected: #3e1810;
            --select-search-text: #fff;
            --select-search-subtle-text: #a6adc8;
            --select-search-highlight: #1e1e2e;
        }
    }

    body.is-dark-mode .select-search {
        --select-search-background: #000;
        --select-search-border: #313244;
        --select-search-selected: #3e1810;
        --select-search-text: #fff;
        --select-search-subtle-text: #a6adc8;
        --select-search-highlight: #1e1e2e;
    }

    body.is-light-mode .select-search {
        --select-search-background: #fff;
        --select-search-border: #dce0e8;
        --select-search-selected: #3e1810;
        --select-search-text: #000;
        --select-search-subtle-text: #6c6f85;
        --select-search-highlight: #eff1f5;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    /**
 * Value wrapper
 */
    .select-search__value {
        position: relative;
        z-index: 1;
        height: 64px;
    }

    .select-search__value::before {
        content: "";
        background-image: url("../images/flag.svg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 21px;
        width: 24px;
        height: 100%;
    }

    .select-search__value::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
 * Input
 */
    .select-search__input {
        display: block;
        height: 100%;
        width: 100%;
        padding: 0 16px 0 24px;
        /* background: var(--select-search-theme-background); */
        color: var(--select-search-text);
        border-radius: 48px !important;
        outline: none;
        font-family: var(--select-search-font);
        text-align: left;
        text-overflow: ellipsis;
        letter-spacing: 0.01rem;
        -webkit-appearance: none;
        -webkit-font-smoothing: antialiased;
        border: 2px solid #424141;
        border-radius: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.5px;
        color: #ffffff;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
 * Options wrapper
 */
    .select-search__select {
        background: #ffffff !important;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        border: 2px solid #424141;
        border-radius: 16px !important;
        top: 62px !important;
        overflow: hidden;
        z-index: 50 !important;
    }

    /**
 * Options
 */
    .select-search__options {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    /**
 * Option
 */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 42px;
        width: 100%;
        padding: 0 16px 0 24px;
        background: var(--select-search-background);
        border: none;
        outline: none;
        font-family: var(--select-search-font);
        color: var(--select-search-text);
        font-size: 16px;
        text-align: left;
        letter-spacing: 0.01rem;
        cursor: pointer;
        -webkit-font-smoothing: antialiased;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        font-weight: bold;
        color: var(--select-search-selected);
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background-color: #ffffff;
        color: var(--select-search-text);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        color: var(--select-search-selected);
    }

    .select-search__options button {
        box-shadow: none;
    }

    .select-search__option:disabled {
        background: transparent !important;
        cursor: not-allowed;
        color: var(--select-search-subtle-text);
    }

    /**
 * Group
 */
    .select-search__group-header {
        font-size: 12px;
        text-transform: uppercase;
        background: var(--select-search-border);
        color: var(--select-search-subtle-text);
        letter-spacing: 0.1rem;
        padding: 10px 16px;
    }

    .select-search__row:not(:first-child) .select-search__group-header {
        margin-top: 10px;
    }

    .select-search__row:not(:last-child) .select-search__group-header {
        margin-bottom: 10px;
    }

    /**
 * States
 */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }

    .select-search.is-disabled .select-search__input:hover {
        cursor: not-allowed;
    }

    /**
 * Modifiers
 */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(180deg);
        border-right: none;
        border-bottom: none;
        pointer-events: none;
        width: 32px;
        height: 32px;
        top: 0;
        bottom: 0;
        margin: auto;

        background-image: url(${Images.selectSearch});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .select-search.has-focus .select-search__value::after {
        transform: rotate(0deg) !important;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__input:hover,
    .select-search:not(.select-search--multiple).has-focus .select-search__input {
        border-color: #424141;
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 48px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 235px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 2px solid var(--select-search-border);
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: var(--select-search-subtle-text);
    }

    .custom-select-search {
        margin-bottom: 40px;
    }

    .custom-select-search::before {
        content: "Country";
        position: absolute;
        top: -12px !important;
        left: 20px !important;
        /* display: inline-block !important; */
        display: none !important;
        background-color: #ffffff !important;
        padding: 5px 8px !important;
        z-index: 2 !important;
        font-family: var(--select-search-font);
        line-height: 16px !important;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        color: var(--font-primary-color);
    }

    .custom-select-search.has-focus::before {
        content: "Select Country";
    }

    /* Scrollbar for country select box */

    .select-search__select {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .select-search__select::-webkit-scrollbar {
        display: none;
    }

    button {
        border-radius: 0 !important;

        :hover {
            background-color: #ffffff !important;
        }

        :focus {
            background-color: #ffffff !important;
        }

        :active {
            :focus {
                background-color: #ffffff !important;
            }
        }
    }
`;
