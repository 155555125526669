import { BulkDeletePayload, DeletePayload } from "../types/model";
import apiCall, { Callback, Fail } from "./apiUtils/ApiCall";
import config from "./Config";

const categoryApi = {
    getCategories(callback: Callback, fail: Fail, params: Record<string, number>): void {
        apiCall.makeGetRequest(config.category.categoryDetail, callback, fail, params);
    },
    categoryDelete(callback: Callback, fail: Fail, payload: DeletePayload): void {
        apiCall.makeDeleteRequest(config.category.removeCategory, callback, fail, payload);
    },
    multipleBulkDelete(callback: Callback, fail: Fail, payload: BulkDeletePayload): void {
        apiCall.makePostRequest(config.category.removeMultipleCategories, callback, fail, payload, {});
    },
    addCategory(callback: Callback, fail: Fail, payload: FormData): void {
        apiCall.makePostRequestWithFormData(config.category.add, callback, fail, payload, {});
    },
    updateCategory(callback: Callback, fail: Fail, payload: FormData, id?: string): void {
        const URL = `${config.category.udpate}${id}`;
        apiCall.makePutRequest(URL, callback, fail, payload, {});
    },
    detail(callback: Callback, fail: Fail, id: string): void {
        apiCall.makeGetRequest(`${config.category.detail}${id}`, callback, fail, {});
    }
};

export default categoryApi;
