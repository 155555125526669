import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { serializeQueryParams } from "./apiUtils";
import { getHeader } from "../../config/MethodUtils";
import { LOGIN } from "../../store/reducers/types";
import store from "../../store";

export type Callback = (response: AxiosResponse) => Promise<void> | void;
export type Fail = (e: any) => void;

type Param = Record<string, any>;
type Payload = Record<string, any>;

const handleUnauthorized = (e: any) => {
    console.log("Unauthorized", e?.response?.status);
    if (e?.response?.status === 401) {
        store.store.dispatch({ type: LOGIN, payload: {} });
        window.location.href = "/login";
    }
};

const apiCall = {
    makeGetRequest(path: string, callback: Callback, fail: Fail, params: Param): void {
        path += serializeQueryParams(params);
        axios
            .get(path, { headers: getHeader() })
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    },

    makePostRequest(path: string, callback: Callback, fail: Fail, payload: Payload, params: Param): void {
        if (params != null) {
            path += serializeQueryParams(params);
        }
        axios
            .post(path, payload, { headers: getHeader() })
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    },

    makePostRequestWithFormData(path: string, callback: Callback, fail: Fail, payload: FormData, params: Param): void {
        if (params != null) {
            path += serializeQueryParams(params);
        }
        const config: AxiosRequestConfig = {
            headers: { ...getHeader(), "Content-Type": "multipart/form-data" }
        };
        axios
            .post(path, payload, config)
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    },

    makeDeleteRequest(path: string, callback: Callback, fail: Fail, payload: Payload): void {
        const config = {
            method: "delete",
            url: path,
            headers: getHeader(),
            data: payload
        };

        axios(config)
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    },

    makePutRequest(path: string, callback: Callback, fail: Fail, payload: Payload, params: Param): void {
        if (params != null) {
            path += serializeQueryParams(params);
        }
        axios
            .put(path, payload, { headers: getHeader() })
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    },

    uploadFile(path: string, callback: Callback, fail: Fail, payload: FormData, params: Param): void {
        path += serializeQueryParams(params);
        axios
            .post(path, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                    ...getHeader()
                }
            })
            .then(callback)
            .catch((e) => {
                handleUnauthorized(e);
                fail(e);
            });
    }
};

export default apiCall;
