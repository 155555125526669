import { AxiosError, AxiosResponse } from "axios";
import categoryApi from "../../../api/categoryApi";
import { handleSnackToast } from "../../../components/common/Snackbar";
import { AppDispatch, BulkDeletePayload, DeletePayload } from "../../../types/model";
import { Severities } from "../../reducers/toast";
import { CategoryDelete, CategoryList } from "../../reducers/types";

type Callback = (...args: unknown[]) => unknown;
export const getCategories = (offset: number, limit: number, callback?: Callback) => {
    const params = {
        limit,
        offset
    };

    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CategoryList, payload: { data: response?.data?.data } });
            }
            if (callback) callback(response);
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        categoryApi.getCategories(onSuccess, onError, params);
    };
};

export const removeCategory = (payload: DeletePayload, categoryId: number, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CategoryDelete, payload: { data: response?.data?.data, categoryId } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        categoryApi.categoryDelete(onSuccess, onError, payload);
    };
};

export const removeBulkCategories = (payload: BulkDeletePayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CategoryDelete, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        categoryApi.multipleBulkDelete(onSuccess, onError, payload);
    };
};

export const createCategory = (payload: FormData, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            console.log("Error ", err);
            handleSnackToast(err?.response?.data?.message, Severities.ERROR);
        };

        categoryApi.addCategory(onSuccess, onError, payload);
    };
};

export const getCategory = (id: string, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        categoryApi.detail(onSuccess, onError, id);
    };
};

export const updateCategory = (payload: FormData, callback: Callback, id?: string) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            handleSnackToast(err?.response?.data?.message, Severities.ERROR);
            console.log("Error ", err);
        };
        categoryApi.updateCategory(onSuccess, onError, payload, id);
    };
};
