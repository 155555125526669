import { forwardRef, ReactElement } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSelector } from "react-redux";
import store, { IRootState } from "../../store";
import { Severity } from "../../store/reducers/toast";
import { HideToast, ShowToast } from "../../store/reducers/types";

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Alert.displayName = "Alert";

export const handleSnackToast = (text: string, severity: Severity): void => {
    const {
        store: { dispatch }
    } = store;
    dispatch({
        type: ShowToast,
        payload: {
            text,
            severity
        }
    });
};

export const handleClose = (): void => {
    const {
        store: { dispatch }
    } = store;
    dispatch({
        type: HideToast,
        payload: {}
    });
};

const SnackBar = (): ReactElement => {
    const { open, text, severity } = useSelector((state: IRootState) => state.toast);

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert className="snackbarAlert" severity={severity} sx={{ width: "100%" }} onClose={handleClose}>
                {text}
            </Alert>
        </Snackbar>
    );
};

export default SnackBar;
