// CategoryList

import { Category, PaginatedResponse } from "../../types/model";
import { CategoryList, PayloadAction } from "./types";

interface InitialValues {
    categoriesList: PaginatedResponse<Category> | null;
}

const initialValues: InitialValues = {
    categoriesList: null
};

const CategoryReducer = (state = initialValues, action: PayloadAction): InitialValues => {
    switch (action.type) {
        case CategoryList:
            return {
                ...state,
                categoriesList: action?.payload?.data
            };

        default:
            return state;
    }
};

export default CategoryReducer;
