const BASE_PATH = `${process.env.REACT_APP_BASE_URL}/api/`;

const Config = {
    auth: {
        login: BASE_PATH + "admin/login",
        updateProfile: BASE_PATH + "admin/update-profile",
        getProfile: BASE_PATH + "admin/get-profile"
    },
    dashboard: {
        brandList: BASE_PATH + "admin/brand-list",
        updateBrand: BASE_PATH + "admin/brand-update",
        brandDelete: BASE_PATH + "admin/delete",
        brandEnableDisable: BASE_PATH + "admin/brand-enable-disable/",
        multipleBrandDelete: BASE_PATH + "admin/bulk-delete",
        createBrand: BASE_PATH + "admin/add-brand",
        brandDetail: BASE_PATH + "admin/brand/",
        uploadLogo: BASE_PATH + "admin/brand/",
        uploadCSV: BASE_PATH + "admin/brand/bulk-upload"
    },
    brandsCollection: {
        post: BASE_PATH + "admin/read",
        exportCSV: BASE_PATH + "admin/export-csv"
    },
    category: {
        categoryDetail: BASE_PATH + "admin/category-list",
        removeCategory: BASE_PATH + "admin/delete",
        removeMultipleCategories: BASE_PATH + "admin/bulk-delete",
        add: BASE_PATH + "admin/add-category",
        udpate: BASE_PATH + "admin/update-category/",
        detail: BASE_PATH + "admin/category/"
    },
    rule: {
        list: BASE_PATH + "admin/rules",
        store: BASE_PATH + "admin/add-rule",
        update: BASE_PATH + "admin/rule-update/",
        delete: BASE_PATH + "admin/delete",
        bulkDelete: BASE_PATH + "admin/bulk-delete",
        details: BASE_PATH + "admin/rule/",
        statusToggle: BASE_PATH + "admin/rules-enable"
    },
    user: {
        list: BASE_PATH + "admin/user-list",
        delete: BASE_PATH + "admin/delete",
        bulkDelete: BASE_PATH + "admin/bulk-delete",
        enableDisable: BASE_PATH + "admin/update/",
        approvedUnapproved: BASE_PATH + "admin/update/",
        email: BASE_PATH + "admin/user-bulk-email"
    },
    collection: {
        get: BASE_PATH + "admin/collection-list",
        collectionEnableDisable: BASE_PATH + "admin/collection-enable/",
        collectionDetail: BASE_PATH + "admin/collection-details/",
        collectionAutopilotStatus: BASE_PATH + "admin/collection-autopilot-status",
        updateCollection: BASE_PATH + "admin/collection-update/",
        uploadCollectionLogo: BASE_PATH + "admin/collection-logo-upload/",
        updateAccountStatus: BASE_PATH + "admin/update",
        collectionApproveDeclined: BASE_PATH + "admin/collection-approved-declined"
    },
    read: {
        post: BASE_PATH + "admin/read"
    },
    perk: {
        create: BASE_PATH + "admin/perks",
        list: BASE_PATH + "admin/perks-list",
        getPerk: BASE_PATH + "admin/perk-details",
        update: BASE_PATH + "admin/perks",
        uploadPerkCSV: BASE_PATH + "admin/perks/bulk-upload"
    },
    redemption: { log: BASE_PATH + "admin/redemption/log" }
};

export default Config;
