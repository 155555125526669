import { FC, ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Header from "./common/Header";
import { Container } from "react-bootstrap";
import FONTS from "../config/fonts";

const DashboardPage = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 64px;

    @media (max-width: 767.98px) {
        gap: 16px;
    }

    a {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        padding: 0 16px 10px 16px;

        &.active {
            color: #ffffff;
            border-bottom: 2px solid #d52b2b;
        }
    }

    .react-tabs__tab-list {
        border-bottom: unset;
        .react-tabs__tab {
            border: unset !important;

            &:first-child {
                padding-left: 0;
            }

            &.react-tabs__tab--selected {
                border-color: #c41919 !important;
                color: white;
                border-width: 0 0 2px 0 !important;
                background: transparent;

                &:focus {
                    border-width: 0 0 2px 0 !important;

                    &:after {
                        height: 2px;
                        background-color: #d52b2b;
                    }
                }
            }
        }
    }
`;

const TabsList = [
    { title: "Autopilot", href: "/autopilot" },
    // { title: "Brands", href: "/brands" },
    { title: "Perks", href: "/perks" },
    { title: "Collections", href: "/collections" },
    { title: "Rules", href: "/rules" },
    { title: "Users", href: "/users" },
    // { title: "Categories", href: "/categories" },
    { title: "Redemption log", href: "/logs" }
];

interface Props {
    children: JSX.Element;
}

const Layout: FC<Props> = ({ children }): ReactElement => {
    const location = useLocation();

    return (
        <>
            <Header />
            <Container>
                <DashboardPage>
                    {TabsList.map((item) => (
                        <Link to={item.href} className={location.pathname.includes(item.href) ? "active" : ""} key={item.href}>
                            {item.title}
                        </Link>
                    ))}
                </DashboardPage>
                <>{children}</>
            </Container>
        </>
    );
};

export default Layout;
