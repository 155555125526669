import { PaginatedResponse, Rule } from "../../types/model";
import {
    FetchPreferredRulesEnd,
    FetchPreferredRulesStart,
    FetchRulesEnd,
    FetchRulesStart,
    PayloadAction,
    PreferredRulesEnableDisable,
    RulesEnableDisable
} from "./types";

interface InitialValues {
    rulesList: PaginatedResponse<Rule> | null;
    prefferedRulesList: PaginatedResponse<Rule> | null;
}

const initialValues: InitialValues = {
    rulesList: null,
    prefferedRulesList: null
};

const RulesReducer = (state = initialValues, action: PayloadAction): InitialValues => {
    switch (action.type) {
        case FetchRulesStart:
            return {
                ...state,
                rulesList: null
            };

        case FetchRulesEnd:
            return {
                ...state,
                rulesList: action.payload?.data
            };

        case RulesEnableDisable:
            if (state.rulesList) {
                const temp = [...state.rulesList.result.rows];
                const tempIndex = temp.findIndex((item): any => item?.id === Number(action?.payload?.id));

                if (tempIndex !== -1) {
                    temp[tempIndex] = { ...temp[tempIndex], isEnable: !temp?.[tempIndex]?.isEnable };
                }
                return {
                    ...state,
                    rulesList: {
                        ...state.rulesList,
                        result: {
                            ...state.rulesList.result,
                            rows: [...temp]
                        }
                    }
                };
            } else {
                return state;
            }
        case PreferredRulesEnableDisable:
            if (state.prefferedRulesList) {
                const temp = [...state.prefferedRulesList.result.rows];
                const tempIndex = temp.findIndex((item): any => item?.id === Number(action?.payload?.id));

                if (tempIndex !== -1) {
                    temp[tempIndex] = { ...temp[tempIndex], isEnable: !temp?.[tempIndex]?.isEnable };
                }
                return {
                    ...state,
                    prefferedRulesList: {
                        ...state.prefferedRulesList,
                        result: {
                            ...state.prefferedRulesList.result,
                            rows: [...temp]
                        }
                    }
                };
            } else {
                return state;
            }

        case FetchPreferredRulesStart:
            return {
                ...state,
                prefferedRulesList: null
            };

        case FetchPreferredRulesEnd:
            return {
                ...state,
                prefferedRulesList: action.payload?.data
            };

        default:
            return state;
    }
};

export default RulesReducer;
