import { PerkListParams, PerkListPayload } from "../pages/autopilot/types";
import { RedemptionLogParams, RedemptionLogPayload } from "../pages/log/types";
import apiCall, { Callback, Fail } from "./apiUtils/ApiCall";
import config from "./Config";

export interface brandUpdatePayload {
    id: number;
    name: string;
    siteUrl: string;
    twitter: string;
    discord: string;
    medium: string;
    description: string | number;
}
export interface collectionUpdatePayload {
    id: number;
    name: string;
    siteUrl: string;
    twitter: string;
    discord: string;
    medium: string;
    description: string | number;
}

export interface collectionAutopilotStatusPayload {
    id: number;
    isAutopilot: 1 | 0;
}
export interface brandDeletepayload {
    modelName: string;
    id: number;
}
export interface CategoriesDeletePayload {
    modelName: string;
    id: number;
}

export interface brandCollectionPayload {
    modelName: string;
}
export interface multipleBrandDeletePayload {
    modelName: string;
    id: [];
}
export interface createBrandPayload {
    name: string;
    siteUrl: string;
    twitter: string;
    discord: string;
    medium: string;
    description: string;
    banner: string;
    logo: string;
}

export interface uploadLogoPayload {
    logo: string;
    type: string;
}
export interface uploadCSVPayload {
    logo: string;
}

export interface UpdateAccountStatusInt {
    id: number;
    status: string;
}
export interface CollectionApproveDeclinedPayload {
    collectionId: number;
    status: string;
}
export interface ReadAPIPayload {
    modelName: "category" | "collection";
}

const dashboardApi = {
    getBrandList(
        callback: Callback,
        fail: Fail,
        offset?: number,
        limit?: number,
        order?: string,
        collectionId?: number,
        field?: string
    ): void {
        const params: any = {
            offset,
            limit,
            order
        };
        if (collectionId) {
            params.collectionId = collectionId;
        }
        if (field) {
            params.field = field;
        }
        const URL = `${config.dashboard.brandList}`;
        apiCall.makeGetRequest(URL, callback, fail, params);
    },

    brandUpdate(callback: Callback, fail: Fail, payload: brandUpdatePayload): void {
        apiCall.makePostRequest(config.dashboard.updateBrand, callback, fail, payload, {});
    },
    brandDelete(callback: Callback, fail: Fail, payload: brandDeletepayload): void {
        apiCall.makeDeleteRequest(config.dashboard.brandDelete, callback, fail, payload);
    },
    postBrandCollection(callback: Callback, fail: Fail, payload: Record<string, any>): void {
        apiCall.makePostRequest(config.brandsCollection.post, callback, fail, payload, {});
    },
    brandEnableDisable(callback: Callback, fail: Fail, id: number): void {
        const URL = `${config.dashboard.brandEnableDisable}${id}`;
        apiCall.makePostRequest(URL, callback, fail, {}, {});
    },
    multipleBrandDelete(callback: Callback, fail: Fail, payload: multipleBrandDeletePayload): void {
        apiCall.makePostRequest(config.dashboard.multipleBrandDelete, callback, fail, payload, {});
    },
    createBrand(callback: Callback, fail: Fail, payload: createBrandPayload): void {
        apiCall.makePostRequest(config.dashboard.createBrand, callback, fail, payload, {});
    },
    brandDetail(callback: Callback, fail: Fail, id: string): void {
        apiCall.makeGetRequest(`${config.dashboard.brandDetail}${id}`, callback, fail, {});
    },
    uploadLogo(callback: Callback, fail: Fail, payload: uploadLogoPayload, id: string): void {
        apiCall.makePostRequest(`${config.dashboard.uploadLogo}${id}/logo-upload`, callback, fail, payload, {});
    },
    exportCSV(callback: Callback, fail: Fail, payload: Record<string, any>): void {
        apiCall.makePostRequest(config.brandsCollection.exportCSV, callback, fail, payload, {});
    },
    uploadCSV(callback: Callback, fail: Fail, payload: uploadCSVPayload): void {
        apiCall.makePostRequest(config.dashboard.uploadCSV, callback, fail, payload, {});
    },
    getCollectionList(
        callback: Callback,
        fail: Fail,
        offset?: number,
        limit?: number,
        order?: string,
        collectionId?: number,
        field?: string,
        activeTabName?: string,
        search?: string
    ): void {
        const params: any = {
            offset,
            limit,
            order
        };
        if (collectionId) {
            params.collectionId = collectionId;
        }
        if (field) {
            params.field = field;
        }
        if (activeTabName) {
            params.type = activeTabName;
        }
        if (search) {
            params.search = search;
        }
        apiCall.makeGetRequest(config.collection.get, callback, fail, params);
    },
    collectionUpdate(callback: Callback, fail: Fail, payload: collectionUpdatePayload, id: number): void {
        apiCall.makePutRequest(`${config.collection.updateCollection}${id}`, callback, fail, payload, {});
    },
    collectionAutopilotStatus(callback: Callback, fail: Fail, payload: collectionAutopilotStatusPayload): void {
        apiCall.makePostRequest(config.collection.collectionAutopilotStatus, callback, fail, payload, {});
    },
    updateAccountStatusApi(callback: Callback, fail: Fail, payload: UpdateAccountStatusInt): void {
        apiCall.makePutRequest(config.collection.updateAccountStatus, callback, fail, payload, {});
    },
    collectionEnableDisable(callback: Callback, fail: Fail, id: number): void {
        const URL = `${config.collection.collectionEnableDisable}${id}`;
        apiCall.makePostRequest(URL, callback, fail, {}, {});
    },
    collectionDetail(callback: Callback, fail: Fail, id: string): void {
        apiCall.makeGetRequest(`${config.collection.collectionDetail}${id}`, callback, fail, {});
    },
    uploadCollectionLogo(callback: Callback, fail: Fail, payload: uploadLogoPayload, id: string): void {
        apiCall.makePostRequest(`${config.collection.uploadCollectionLogo}${id}`, callback, fail, payload, {});
    },
    readAPI(callback: Callback, fail: Fail, payload: ReadAPIPayload): void {
        apiCall.makePostRequest(config.read.post, callback, fail, payload, {});
    },
    createPerk(callback: Callback, fail: Fail, payload: FormData): void {
        apiCall.makePostRequestWithFormData(config.perk.create, callback, fail, payload, {});
    },
    listPerk(callback: Callback, fail: Fail, payload: PerkListPayload, params: PerkListParams): void {
        apiCall.makePostRequest(config.perk.list, callback, fail, payload, params);
    },
    getPerk(id: string, callback: Callback, fail: Fail): void {
        apiCall.makeGetRequest(`${config.perk.getPerk}/${id}`, callback, fail, {});
    },
    updatePerk(id: string, callback: Callback, fail: Fail, payload: FormData): void {
        apiCall.makePutRequest(`${config.perk.update}/${id}`, callback, fail, payload, {});
    },
    uploadPerkCSV(callback: Callback, fail: Fail, payload: uploadCSVPayload): void {
        apiCall.makePostRequest(config.perk.uploadPerkCSV, callback, fail, payload, {});
    },
    getRedemptionLog(callback: Callback, fail: Fail, payload: RedemptionLogPayload, params: RedemptionLogParams): void {
        apiCall.makePostRequest(config.redemption.log, callback, fail, payload, params);
    },
    collectionApproveDeclined(callback: Callback, fail: Fail, payload: CollectionApproveDeclinedPayload): void {
        apiCall.makePostRequest(config.collection.collectionApproveDeclined, callback, fail, payload, {});
    }
};

export default dashboardApi;
