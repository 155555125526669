export enum AUTOPILOT_TYPE {
    ACTIVE = "active",
    SCHEDULED = "scheduled",
    COMPLETED = "completed",
    DRAFT = "draft"
}

export interface PerkListPayload {
    type: AUTOPILOT_TYPE;
    collectionId?: number | string;
    categoryId?: number | string;
    isAutoPilot: 1;
}

export interface PerkListParams {
    limit: number;
    offset: number;
    order: string;
    field: string;
}

export type ACTIVE_TAB_TYPE = {
    tabStatus: "active" | "scheduled" | "completed" | "draft";
};
