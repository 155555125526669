// eslint-disable-next-line no-use-before-define
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./index.css";

const root = document.getElementById("root");
const rootElem = ReactDOM.createRoot(root as any);

rootElem.render(
    <React.StrictMode>
        <Provider store={store.store}>
            <PersistGate loading={null} persistor={store?.persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
reportWebVitals();
