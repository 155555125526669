import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import dashboardApi, {
    brandCollectionPayload,
    brandDeletepayload,
    brandUpdatePayload,
    collectionUpdatePayload,
    createBrandPayload,
    multipleBrandDeletePayload,
    uploadCSVPayload,
    uploadLogoPayload,
    UpdateAccountStatusInt,
    ReadAPIPayload,
    collectionAutopilotStatusPayload,
    CollectionApproveDeclinedPayload
} from "../../../api/dashboardApi";
import { handleSnackToast } from "../../../components/common/Snackbar";
import { PerkListParams, PerkListPayload } from "../../../pages/autopilot/types";
import { RedemptionLogParams, RedemptionLogPayload } from "../../../pages/log/types";
import { AppDispatch } from "../../../types/model";
import { Severities } from "../../reducers/toast";
import {
    BrandCollection,
    BrandCollectionStart,
    BrandDelete,
    BrandDetail,
    BrandDetailStart,
    BrandEnableDisable,
    BrandListEnd,
    BrandListStart,
    CreateBrand,
    MultipleBrandDelete,
    UpdateBrand,
    UploadCSV,
    UploadLogo,
    // ExportCSV,
    CollectionListStart,
    CollectionListEnd,
    CollectionEnableDisable,
    CollectionDetailStart,
    CollectionDetail,
    UpdateCollection,
    UpdateAccountStatus,
    UploadCollectionLogo
} from "../../reducers/types";

type Callback = (...args: unknown[]) => unknown;

export const getBrandList = (
    offset?: number,
    limit?: number,
    callTypes?: boolean,
    order?: string,
    collectionId?: number,
    field?: string,
    callback?: Callback
) => {
    return (dispatch: AppDispatch): void => {
        if (callTypes) {
            dispatch({ type: BrandListStart });
        }
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: BrandListEnd, payload: { data: response?.data?.data } });
            }
            if (callback) callback(response);
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.getBrandList(onSuccess, onError, offset, limit, order, collectionId, field);
    };
};

export const updateBrand = (payload: brandUpdatePayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UpdateBrand, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            handleSnackToast(err?.response?.data?.message, Severities.ERROR);
            console.log("Error ", err);
        };
        dashboardApi.brandUpdate(onSuccess, onError, payload);
    };
};
export const deleteBrand = (payload: brandDeletepayload, brandId: number, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: BrandDelete, payload: { data: response?.data?.data, brandId } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.brandDelete(onSuccess, onError, payload);
    };
};

export const postBrandList = (payload: brandCollectionPayload) => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: BrandCollectionStart });

        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: BrandCollection, payload: { data: response?.data?.data } });
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.postBrandCollection(onSuccess, onError, payload);
    };
};

export const brandEnableDisable = (id: number, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: BrandEnableDisable, payload: { data: response?.data?.data, id } });
                if (callback) {
                    callback(response?.data?.data);
                }
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.brandEnableDisable(onSuccess, onError, id);
    };
};
export const multipleBrandDelete = (payload: multipleBrandDeletePayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: MultipleBrandDelete, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
            handleSnackToast(err?.message, Severities.ERROR);
        };
        dashboardApi.multipleBrandDelete(onSuccess, onError, payload);
    };
};
export const createBrand = (payload: createBrandPayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CreateBrand, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.createBrand(onSuccess, onError, payload);
    };
};
export const getBrandDetail = (id: string, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: BrandDetailStart });
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: BrandDetail, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.brandDetail(onSuccess, onError, id);
    };
};

export const uploadLogoBanner = (id: string, payload: uploadLogoPayload, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UploadLogo, payload: { data: response?.data?.data } });
                if (callback) callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.uploadLogo(onSuccess, onError, payload, id);
    };
};

export const exportCSV = (payload: Record<string, any>, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
                callback(response?.data);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.exportCSV(onSuccess, onError, payload);
    };
};
export const uploadCSV = (payload: uploadCSVPayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UploadCSV, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.uploadCSV(onSuccess, onError, payload);
    };
};

export const getCollectionList = (
    offset?: number,
    limit?: number,
    callTypes?: boolean,
    order?: string,
    collectionId?: number,
    field?: string,
    activeTabName?: string,
    search?: string,
    callback?: Callback
) => {
    return (dispatch: AppDispatch): void => {
        if (callTypes) {
            dispatch({ type: CollectionListStart });
        }
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CollectionListEnd, payload: { data: response?.data?.data } });
            }
            if (callback) callback(response);
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.getCollectionList(onSuccess, onError, offset, limit, order, collectionId, field, activeTabName, search);
    };
};

export const updateCollection = (payload: collectionUpdatePayload, id: any, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UpdateCollection, payload: { data: response?.data?.data } });
                if (callback) callback(true);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                if (callback) callback(false);
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            if (callback) callback(false);
            console.log("Error ", err);
            handleSnackToast(err?.response?.data?.message || err?.message, Severities.ERROR);
        };
        dashboardApi.collectionUpdate(onSuccess, onError, payload, id);
    };
};

export const collectionAutopilotStatus = (values: collectionAutopilotStatusPayload, callback: (status: boolean) => void) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(true);
            } else {
                callback(false);
            }
        };
        const onError = (err: AxiosError) => {
            toast.error(err.message);
            callback(false);
            console.log("Error ", err);
        };
        dashboardApi.collectionAutopilotStatus(onSuccess, onError, values);
    };
};

export const updateAccountStatusApiAction = (payload: UpdateAccountStatusInt, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UpdateAccountStatus, payload: { data: response?.data?.data } });
                if (callback) callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.updateAccountStatusApi(onSuccess, onError, payload);
    };
};

export const collectionEnableDisable = (id: number, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CollectionEnableDisable, payload: { data: response?.data?.data, id } });
                if (callback) callback(response);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.collectionEnableDisable(onSuccess, onError, id);
    };
};

export const getCollectionDetail = (id: string, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: CollectionDetailStart });
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: CollectionDetail, payload: { data: response?.data?.data } });
                if (callback) callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.collectionDetail(onSuccess, onError, id);
    };
};

export const uploadCollectionLogoBanner = (id: string, payload: uploadLogoPayload, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UploadCollectionLogo, payload: { data: response?.data?.data } });
                if (callback) callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.uploadCollectionLogo(onSuccess, onError, payload, id);
    };
};

export const readAPI = (payload: ReadAPIPayload, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.readAPI(onSuccess, onError, payload);
    };
};

export const createPerk = (payload: FormData, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                handleSnackToast("Perk created successfully", Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
            handleSnackToast(err?.message, Severities.ERROR);
        };
        dashboardApi.createPerk(onSuccess, onError, payload);
    };
};

export const listPerk = (payload: PerkListPayload, params: PerkListParams, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.listPerk(onSuccess, onError, payload, params);
    };
};

export const getPerk = (id: string, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        dashboardApi.getPerk(id, onSuccess, onError);
    };
};

export const updatePerk = (id: string, payload: FormData, setIsSubmitting: (isSubmitting: boolean) => void, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                setIsSubmitting(false);
                handleSnackToast("Perk updated successfully", Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
            setIsSubmitting(false);
            handleSnackToast(err?.message, Severities.ERROR);
        };
        dashboardApi.updatePerk(id, onSuccess, onError, payload);
    };
};

export const uploadPerkCSV = (payload: uploadCSVPayload, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
            handleSnackToast(err?.message, Severities.ERROR);
        };
        dashboardApi.uploadPerkCSV(onSuccess, onError, payload);
    };
};

export const getRedemptionLog = (payload: RedemptionLogPayload, params: RedemptionLogParams, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback({ status: true, res: response?.data?.data });
            } else {
                callback({ status: false });
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            callback({ status: false });
            handleSnackToast(err?.message, Severities.ERROR);
            console.log("Error ", err);
        };
        dashboardApi.getRedemptionLog(onSuccess, onError, payload, params);
    };
};

export const collectionApproveDeclined = (payload: CollectionApproveDeclinedPayload, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UpdateAccountStatus, payload: { data: response?.data?.data } });
                if (callback) callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            handleSnackToast(err?.message, Severities.ERROR);
            console.log("Error ", err);
        };
        dashboardApi.collectionApproveDeclined(onSuccess, onError, payload);
    };
};
