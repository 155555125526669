import { PaginatedResponse, User } from "../../types/model";
import { ApprovedUnapproved, EnableDisable, UserList, UserPayloadAction } from "./types";

interface InitialValues {
    userList: PaginatedResponse<User> | null;
}

const InitialValues: InitialValues = {
    userList: null
};

const UserReducer = (state = InitialValues, action: UserPayloadAction): InitialValues => {
    switch (action.type) {
        case UserList:
            return {
                ...state,
                userList: action?.payload?.data
            };
        case EnableDisable:
            if (state.userList) {
                const temp = [...state.userList.result.rows];
                const tempIndex = temp.findIndex((item): any => item?.id === action?.payload?.userId);

                if (tempIndex !== -1) {
                    temp[tempIndex] = { ...temp[tempIndex], active: !temp?.[tempIndex]?.active };
                }
                return {
                    ...state,
                    userList: {
                        ...state.userList,
                        result: {
                            ...state.userList.result,
                            rows: [...temp]
                        }
                    }
                };
            } else {
                return state;
            }
        case ApprovedUnapproved:
            if (state.userList) {
                const temp = [...state.userList.result.rows];
                const tempIndex = temp.findIndex((item): any => item?.id === action?.payload?.userId);

                if (tempIndex !== -1) {
                    temp[tempIndex] = { ...temp[tempIndex], providerApproved: !temp?.[tempIndex]?.providerApproved };
                }
                return {
                    ...state,
                    userList: {
                        ...state.userList,
                        result: {
                            ...state.userList.result,
                            rows: [...temp]
                        }
                    }
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};
export default UserReducer;
