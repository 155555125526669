import { ReactElement, useEffect, useMemo, useState } from "react";
import { AppDispatch, BulkDeletePayload, Collection, Rule } from "../../types/model";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store";

import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import Images from "../../config/images";
import Table from "../../components/table";
import { Topbar } from "./../../components/common/Header";
import { useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";
import useTable from "../../hooks/useTable";
import { bulkDelete, deleteRule, getRules, toggleRuleStatus } from "../../store/actions/rules";
import { RulesStyled, RuleTitle, ButtonSection, DropdownSection, RuleSection } from "./../indexStyle";
import AlertModal from "../../components/alertModal";
import Spinner from "../../components/common/Spinner";
import FONTS from "../../config/fonts";
import styled from "styled-components";

const Title = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 16px;
`;

const SubTitle = styled.h4`
    font-family: ${FONTS.interFont};
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
`;

const Rules = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const { collectionList } = useSelector((state: IRootState) => state?.dashboard);
    const { rulesList, prefferedRulesList } = useSelector((state: IRootState) => state?.rules);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);

    const [collectionId, setCollectionId] = useState<string>("");
    const [selectedRuleId, setSelectedRuleId] = useState<number>(0);

    const { pageSize, setPageSize, offset, setOffset, order, field, selectedItems, setSelectedItems, isLoading, setIsLoading } = useTable();
    const {
        pageSize: preferredPageSize,
        setPageSize: preferredSetPageSize,
        offset: preferredOffset,
        setOffset: preferredSetOffset,
        selectedItems: preferredSelectedItems,
        setSelectedItems: preferredSetSelectedItems,
        isLoading: preferredIsLoading,
        setIsLoading: preferredSetIsLoading
    } = useTable();

    const [, setBulkDeleteRule] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        setBulkDeleteRule(false);
        dispatch(
            getRules(offset, pageSize, "0", collectionId, () => {
                setIsLoading(false);
            })
        );
    }, [pageSize, offset, collectionId, order, field]);

    useEffect(() => {
        preferredSetIsLoading(true);
        dispatch(
            getRules(preferredOffset, preferredPageSize, "1", "", () => {
                preferredSetIsLoading(false);
            })
        );
    }, [preferredPageSize, preferredOffset]);

    const allSelected = useMemo(
        () => rulesList?.result.rows.length && selectedItems.length === rulesList?.result.rows.length,
        [selectedItems, rulesList?.result]
    );

    const allSelectedPreferred = useMemo(
        () => prefferedRulesList?.result.rows.length && preferredSelectedItems.length === prefferedRulesList?.result.rows.length,
        [preferredSelectedItems, prefferedRulesList?.result]
    );

    const ruleListLength = rulesList?.result.rows.length ?? 0;
    const prefferedRulesListLength = prefferedRulesList?.result.rows.length ?? 0;

    const handleRulePostAction = () => {
        setOffset(0);
        preferredSetOffset(0);
        setShowDeleteConfirmation(false);
        setSelectedItems([]);

        dispatch(
            getRules(0, pageSize, "0", collectionId, () => {
                setIsLoading(false);
            })
        );
        dispatch(
            getRules(0, pageSize, "1", collectionId, () => {
                setIsLoading(false);
            })
        );
    };

    const handleDeleteRule = (id: number) => {
        setShowDeleteConfirmation(true);
        setSelectedRuleId(id);
    };

    const handleDeleteYesFunction = () => {
        setIsLoading(true);
        dispatch(deleteRule(selectedRuleId, handleRulePostAction));
    };
    const BulkDelete = () => {
        setShowDeleteAllConfirmation(true);
    };

    const handleBulkDelete = () => {
        setIsLoading(true);
        const BulkDeleteObj: BulkDeletePayload = {
            modelName: "rule",
            ids: selectedItems?.concat(preferredSelectedItems)
        };
        dispatch(
            bulkDelete(BulkDeleteObj, () => {
                setSelectedItems([]);
                preferredSetSelectedItems([]);
                setIsLoading(false);
                setShowDeleteAllConfirmation(false);

                dispatch(
                    getRules(offset, pageSize, "0", collectionId, () => {
                        setIsLoading(false);
                    })
                );
                dispatch(
                    getRules(offset, pageSize, "1", collectionId, () => {
                        setIsLoading(false);
                    })
                );
            })
        );
    };

    const handleToggle = (id: string, isPreferred: number) => {
        dispatch(toggleRuleStatus(id, isPreferred));
    };

    const prefferedHeader = () => {
        return (
            <tr>
                <th>
                    <label className="custom_checkbox">
                        <input
                            type="checkbox"
                            checked={preferredSelectedItems.length === prefferedRulesListLength}
                            onChange={() => {
                                if (allSelectedPreferred) {
                                    preferredSetSelectedItems([]);
                                } else {
                                    preferredSetSelectedItems(prefferedRulesList?.result.rows?.map((rule: Rule) => rule?.id) ?? []);
                                }
                            }}
                        />
                        <span
                            className={`checkmark 
${prefferedRulesList?.result?.rows?.length && preferredSelectedItems.length === prefferedRulesList?.result.rows.length ? "" : ""} 
${preferredSelectedItems.length > 0 && preferredSelectedItems.length !== prefferedRulesList?.result.rows.length ? "dash" : ""}`}
                        ></span>
                    </label>
                </th>
                <th>#</th>
                <th className="external-link">NAME</th>
                <th className="text-center">TIME HELD (DAYS)</th>
                <th className="text-center">Amount held</th>
                <th className="text-center">Pool randomization</th>
                <th className="text-center">Action</th>
                <th className="text-center">Status</th>
            </tr>
        );
    };

    const renderPreferredRules = (rule: Rule, index: number) => {
        return (
            <tr key={rule.id}>
                <td>
                    <label className="custom_checkbox">
                        <input
                            checked={preferredSelectedItems.includes(rule.id)}
                            type="checkbox"
                            onChange={() => {
                                if (preferredSelectedItems.includes(rule.id)) {
                                    preferredSetSelectedItems(preferredSelectedItems.filter((item) => item !== rule.id));
                                } else {
                                    preferredSetSelectedItems([...preferredSelectedItems, rule.id]);
                                }
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </td>
                <td>{index + 1}</td>
                <td>{rule.title}</td>
                <td className="text-center">{rule.time}</td>
                <td className="text-center">{rule.amount}</td>
                <td className="text-center">
                    <img src={Images.checkmark} alt="" />
                </td>
                <td className="action-icon d-flex justify-content-center">
                    <EditIcon onClick={() => navigate(`/rules/edit/${rule?.id}`)} />
                    <DeleteIcon onClick={() => handleDeleteRule(rule.id)} />
                </td>
                <td className="Switchicon">
                    <label className="switch">
                        <input type="checkbox" checked={rule?.isEnable} onChange={() => handleToggle("" + rule.id, 1)} />
                        <span className="slider round"></span>
                    </label>
                </td>
            </tr>
        );
    };

    const renderHeader = () => {
        return (
            <tr>
                <th>
                    <label className="custom_checkbox">
                        <input
                            type="checkbox"
                            checked={selectedItems.length === ruleListLength}
                            onChange={() => {
                                if (allSelected) {
                                    setSelectedItems([]);
                                } else {
                                    setSelectedItems(rulesList?.result.rows?.map((rule: Rule) => rule?.id) ?? []);
                                }
                            }}
                        />
                        <span
                            className={`checkmark ${
                                rulesList?.result?.rows?.length && selectedItems.length === rulesList?.result.rows.length ? "" : ""
                            } ${selectedItems.length > 0 && selectedItems.length !== rulesList?.result.rows.length ? "dash" : ""}`}
                        ></span>
                    </label>
                </th>
                <th>#</th>
                <th className="external-link">NAME</th>
                <th>Collection</th>
                <th className="text-center">TIME HELD (DAYS)</th>
                <th className="text-center">Amount held</th>
                <th className="text-center">Pool randomization</th>
                <th className="text-center">Action</th>
                <th className="text-center">Status</th>
            </tr>
        );
    };

    const renderRules = (rule: Rule, index: number) => {
        return (
            <tr key={rule.id}>
                <td>
                    <label className="custom_checkbox">
                        <input
                            checked={selectedItems.includes(rule.id)}
                            type="checkbox"
                            onChange={() => {
                                if (selectedItems.includes(rule.id)) {
                                    setSelectedItems(selectedItems.filter((item) => item !== rule.id));
                                } else {
                                    setSelectedItems([...selectedItems, rule.id]);
                                }
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </td>
                <td>{index + 1}</td>
                <td>{rule?.title}</td>
                <td>{rule.collection?.name ?? "-"}</td>
                <td className="text-center">{rule.time}</td>
                <td className="text-center">{rule.amount}</td>
                <td className="text-center">
                    <img src={Images.checkmark} alt="" />
                </td>
                <td className="action-icon d-flex justify-content-center">
                    <EditIcon onClick={() => navigate(`/rules/edit/${rule?.id}`)} />
                    <DeleteIcon onClick={() => handleDeleteRule(rule.id)} />
                </td>
                <td className="Switchicon">
                    <label className="switch">
                        <input type="checkbox" checked={rule?.isEnable} onChange={() => handleToggle("" + rule.id, 0)} />
                        <span className="slider round"></span>
                    </label>
                </td>
            </tr>
        );
    };

    // eslint-disable-next-line no-constant-condition
    if (true) {
        return (
            <>
                <Title>Rules</Title>
                <SubTitle>Coming soon</SubTitle>
            </>
        );
    }

    return (
        <>
            <Topbar
                selectedBrand={selectedItems.length + preferredSelectedItems.length}
                classname={selectedItems?.length + preferredSelectedItems.length ? "" : "d-none"}
                handleDeleteMultipleBrand={BulkDelete}
                setSelectedBrand={setSelectedItems}
                preferredSetSelectedItems={preferredSetSelectedItems}
                bulkDelete={true}
            />

            <RulesStyled className="d-flex flex-column">
                <RuleSection>
                    <RuleTitle>
                        <h2>
                            Preffered rule{" "}
                            {prefferedRulesList?.result.count && (
                                <>
                                    <sub className="ms-2">
                                        <span>
                                            (<p>{prefferedRulesList?.result.count}</p>)
                                        </span>
                                    </sub>
                                </>
                            )}
                        </h2>
                    </RuleTitle>
                    <ButtonSection>
                        <span className="loader"></span>
                        <Button variant="primary" type="submit" onClick={() => navigate("/rules/create")} className="preferredrulebutton">
                            <Plus />
                            Add Preferred Rule
                        </Button>
                    </ButtonSection>
                </RuleSection>
                <Table
                    renderHeader={prefferedHeader}
                    renderData={renderPreferredRules}
                    data={prefferedRulesList}
                    isLoading={preferredIsLoading}
                    pageSize={preferredPageSize}
                    offset={preferredOffset}
                    setPageSize={preferredSetPageSize}
                    setOffset={preferredSetOffset}
                    setSelected={preferredSetSelectedItems}
                />

                <RuleSection>
                    <RuleTitle>
                        <h2>
                            User rules
                            {rulesList?.result.count !== 0 && (
                                <>
                                    <sub className="ms-2">
                                        <span>
                                            (<p>{rulesList?.result.count}</p>)
                                        </span>
                                    </sub>
                                </>
                            )}
                        </h2>
                    </RuleTitle>
                </RuleSection>
                <DropdownSection>
                    <select
                        onChange={(e: any) => {
                            setCollectionId(e.target.value);
                            setSelectedItems([]);
                        }}
                    >
                        <option value="">All Collections</option>
                        {collectionList?.result.map((collection: Collection, index: number) => (
                            <option value={collection.id} key={index}>
                                {collection.name}
                            </option>
                        ))}
                    </select>
                </DropdownSection>
                <Table
                    renderHeader={renderHeader}
                    renderData={renderRules}
                    data={rulesList}
                    isLoading={isLoading}
                    pageSize={pageSize}
                    offset={offset}
                    setPageSize={setPageSize}
                    setOffset={setOffset}
                    setSelected={setSelectedItems}
                />
                <AlertModal
                    open={showDeleteConfirmation}
                    string={"Delete rule"}
                    description={""}
                    setOpen={setShowDeleteConfirmation}
                    handleRemove={handleDeleteYesFunction}
                    image={Images.removeIcon}
                    closeButton={"Cancel"}
                    yesButton={isLoading ? <Spinner /> : "Delete"}
                />
                <AlertModal
                    open={showDeleteAllConfirmation}
                    string={"Delete rules"}
                    description={""}
                    setOpen={setShowDeleteAllConfirmation}
                    handleRemove={handleBulkDelete}
                    image={Images.removeIcon}
                    closeButton={"Cancel"}
                    yesButton={isLoading ? <Spinner /> : "Delete"}
                />
            </RulesStyled>
        </>
    );
};

export default Rules;
