import styled from "styled-components";
import FONTS from "../config/fonts";
import Chevrondown from "../assets/images/chevrondown.svg";

export const TableComponent = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    &:first-child {
                        border-radius: 16px 0 0 16px;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
            }
        }

        .action-icon {
            display: flex;

            svg {
                cursor: pointer;
                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }
        }

        .Switchicon {
            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                    }
                }
            }
        }
    }
`;

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageNumber = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 64px;

    h6 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 0;
    }
`;

export const PageSelect = styled.div`
    width: 125px;
    padding-right: 16px;

    select {
        width: 100%;
        height: 48px;
        background: #2f2f2f;
        border: 1px solid rgba(95, 90, 90, 0.4);
        border-radius: 8px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        padding: 12px 16px 14px 24px;
        appearance: none;
        background-image: url("${Chevrondown}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;

        &:focus-visible {
            outline: none;
        }
    }
`;

export const PaginationPage = styled.div`
    margin-bottom: 49px;

    ul {
        justify-content: center;
    }

    .page-link {
        background: #373636;
        border: 1px solid #4f4f4f;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        width: 65.6px;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 !important;
    }

    .page-item.disabled .page-link {
        background: #221f1f;
        border: 1px solid #4f4f4f;
    }

    .page-item.active .page-link {
        background: #d52b2b;
        border: transparent;
    }

    .page-link:focus {
        box-shadow: none;
    }

    .previous {
        span {
            &.page-link {
                border-top-left-radius: 6px !important;
                border-bottom-left-radius: 6px !important;
            }
        }
    }

    .next {
        span {
            &.page-link {
                border-top-right-radius: 6px !important;
                border-bottom-right-radius: 6px !important;
            }
        }
    }
`;
