import { ReactElement } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import FONTS from "../config/fonts";

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleRemove: () => void;
    string: string;
    description?: any;
    image?: any;
    closeButton?: string;
    yesButton?: ReactElement | string;
    showInputField?: boolean;
    setMessege?: any;
    onCancelButtonClick?: any
}

const ModalCont = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fbfbfb;
    width: 480px;
    padding: 24px 24px 32px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    @media (max-width: 576px) {
        width: 90%;
    }
    &:focus-visible {
        outline: none;
    }

    .button-group {
        @media (max-width: 575.98px) {
            display: grid;
        }

        button {
            @media (max-width: 575.98px) {
                margin-left: 0 !important;
                margin-right: 0 !important;

                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;
        margin-bottom: 18px;
    }
    .form-control {
        border: 2px solid rgba(95, 90, 90, 0.4);
        border-radius: 16px;
    }
    .form-control:focus {
        outline: none;
        box-shadow: none;
    }
    .form-control:focus-visible {
        border: 2px solid rgba(95, 90, 90, 0.4);
    }
    &.emailmodal {
        width: 624px;

        button {
            width: 257px;
        }
    }
    textarea.form-control {
        min-height: 124px;
    }
`;

const IoMdCloseSvg = styled(IoMdClose)`
    color: #221f1f;
    cursor: pointer;
    height: 32px;
    width: 32px;
`;

const Button = styled.button<{ bgRed?: boolean }>`
    font-family: ${FONTS.interFont};
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    border-radius: 50px;
    padding: 20px 44px;
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border: none;
    background: ${(props) => (props.bgRed ? "#d52b2b" : "#231f20")};
    &:hover {
        background: ${(props) => (props.bgRed ? "#d52b2b" : "white")};
        color: ${(props) => (props.bgRed ? "" : "#d52b2b")};
    }
`;

const AlertModal = (props: IProps): ReactElement => {
    const {
        open,
        setOpen,
        handleRemove,
        string,
        description,
        image,
        closeButton,
        yesButton,
        showInputField,
        setMessege,
        onCancelButtonClick
    } = props;

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            sx={{ backdropFilter: "blur(5px)" }}
        >
            <ModalCont className={showInputField ? "emailmodal" : ""}>
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <IoMdCloseSvg onClick={handleClose} />
                </Box>
                <div style={{ textAlign: "center" }}>
                    <img src={image} />
                </div>
                <Box mb={3}>
                    <Typography color="#221F1F" fontSize="32px" fontWeight="600" component="div" align="center">
                        {string}
                    </Typography>
                    <Typography color="#221F1F" fontSize="18px" fontWeight="400" component="div" align="center">
                        {description}
                    </Typography>
                </Box>
                {showInputField === true ? (
                    <Box mb={3}>
                        <label htmlFor="exa mpleFormControlTextarea1" className="form-label">
                            Message
                        </label>
                        <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            style={{ resize: "none" }}
                            onKeyUp={(e: any) => setMessege(e.target.value)}
                        ></textarea>
                    </Box>
                ) : (
                    ""
                )}
                <Box display="flex" justifyContent="center">
                    <Button style={{ marginRight: 8 }} bgRed={false} onClick={onCancelButtonClick || handleClose}>
                        {closeButton}
                    </Button>
                    <Button style={{ marginLeft: 8 }} bgRed onClick={handleRemove}>
                        {yesButton}
                    </Button>
                </Box>
            </ModalCont>
        </Modal>
    );
};

export default AlertModal;
