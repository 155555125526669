import { Country } from "./types";
export const countriesList: Country[] = [
    {
        name: "Afghanistan",
        code: "Afghanistan",
        value: "AF"
    },
    {
        name: "Åland Islands",
        code: "Åland Islands",
        value: "AX"
    },
    {
        name: "Albania",
        code: "Albania",
        value: "AL"
    },
    {
        name: "Algeria",
        code: "Algeria",
        value: "DZ"
    },
    {
        name: "American Samoa",
        code: "American Samoa",
        value: "AS"
    },
    {
        name: "AndorrA",
        code: "AndorrA",
        value: "AD"
    },
    {
        name: "Angola",
        code: "Angola",
        value: "AO"
    },
    {
        name: "Anguilla",
        code: "Anguilla",
        value: "AI"
    },
    {
        name: "Antarctica",
        code: "Antarctica",
        value: "AQ"
    },
    {
        name: "Antigua and Barbuda",
        code: "Antigua and Barbuda",
        value: "AG"
    },
    {
        name: "Argentina",
        code: "Argentina",
        value: "AR"
    },
    {
        name: "Armenia",
        code: "Armenia",
        value: "AM"
    },
    {
        name: "Aruba",
        code: "Aruba",
        value: "AW"
    },
    {
        name: "Australia",
        code: "Australia",
        value: "AU"
    },
    {
        name: "Austria",
        code: "Austria",
        value: "AT"
    },
    {
        name: "Azerbaijan",
        code: "Azerbaijan",
        value: "AZ"
    },
    {
        name: "Bahamas",
        code: "Bahamas",
        value: "BS"
    },
    {
        name: "Bahrain",
        code: "Bahrain",
        value: "BH"
    },
    {
        name: "Bangladesh",
        code: "Bangladesh",
        value: "BD"
    },
    {
        name: "Barbados",
        code: "Barbados",
        value: "BB"
    },
    {
        name: "Belarus",
        code: "Belarus",
        value: "BY"
    },
    {
        name: "Belgium",
        code: "Belgium",
        value: "BE"
    },
    {
        name: "Belize",
        code: "Belize",
        value: "BZ"
    },
    {
        name: "Benin",
        code: "Benin",
        value: "BJ"
    },
    {
        name: "Bermuda",
        code: "Bermuda",
        value: "BM"
    },
    {
        name: "Bhutan",
        code: "Bhutan",
        value: "BT"
    },
    {
        name: "Bolivia",
        code: "Bolivia",
        value: "BO"
    },
    {
        name: "Bosnia and Herzegovina",
        code: "Bosnia and Herzegovina",
        value: "BA"
    },
    {
        name: "Botswana",
        code: "Botswana",
        value: "BW"
    },
    {
        name: "Bouvet Island",
        code: "Bouvet Island",
        value: "BV"
    },
    {
        name: "Brazil",
        code: "Brazil",
        value: "BR"
    },
    {
        name: "British Indian Ocean Territory",
        code: "British Indian Ocean Territory",
        value: "IO"
    },
    {
        name: "Brunei Darussalam",
        code: "Brunei Darussalam",
        value: "BN"
    },
    {
        name: "Bulgaria",
        code: "Bulgaria",
        value: "BG"
    },
    {
        name: "Burkina Faso",
        code: "Burkina Faso",
        value: "BF"
    },
    {
        name: "Burundi",
        code: "Burundi",
        value: "BI"
    },
    {
        name: "Cambodia",
        code: "Cambodia",
        value: "KH"
    },
    {
        name: "Cameroon",
        code: "Cameroon",
        value: "CM"
    },
    {
        name: "Canada",
        code: "Canada",
        value: "CA"
    },
    {
        name: "Cape Verde",
        code: "Cape Verde",
        value: "CV"
    },
    {
        name: "Cayman Islands",
        code: "Cayman Islands",
        value: "KY"
    },
    {
        name: "Central African Republic",
        code: "Central African Republic",
        value: "CF"
    },
    {
        name: "Chad",
        code: "Chad",
        value: "TD"
    },
    {
        name: "Chile",
        code: "Chile",
        value: "CL"
    },
    {
        name: "China",
        code: "China",
        value: "CN"
    },
    {
        name: "Christmas Island",
        code: "Christmas Island",
        value: "CX"
    },
    {
        name: "Cocos (Keeling) Islands",
        code: "Cocos (Keeling) Islands",
        value: "CC"
    },
    {
        name: "Colombia",
        code: "Colombia",
        value: "CO"
    },
    {
        name: "Comoros",
        code: "Comoros",
        value: "KM"
    },
    {
        name: "Congo",
        code: "Congo",
        value: "CG"
    },
    {
        name: "Congo, The Democratic Republic of the",
        code: "Congo, The Democratic Republic of the",
        value: "CD"
    },
    {
        name: "Cook Islands",
        code: "Cook Islands",
        value: "CK"
    },
    {
        name: "Costa Rica",
        code: "Costa Rica",
        value: "CR"
    },
    {
        name: "Cote D'Ivoire",
        code: "Cote D'Ivoire",
        value: "CI"
    },
    {
        name: "Croatia",
        code: "Croatia",
        value: "HR"
    },
    {
        name: "Cuba",
        code: "Cuba",
        value: "CU"
    },
    {
        name: "Cyprus",
        code: "Cyprus",
        value: "CY"
    },
    {
        name: "Czech Republic",
        code: "Czech Republic",
        value: "CZ"
    },
    {
        name: "Denmark",
        code: "Denmark",
        value: "DK"
    },
    {
        name: "Djibouti",
        code: "Djibouti",
        value: "DJ"
    },
    {
        name: "Dominica",
        code: "Dominica",
        value: "DM"
    },
    {
        name: "Dominican Republic",
        code: "Dominican Republic",
        value: "DO"
    },
    {
        name: "Ecuador",
        code: "Ecuador",
        value: "EC"
    },
    {
        name: "Egypt",
        code: "Egypt",
        value: "EG"
    },
    {
        name: "El Salvador",
        code: "El Salvador",
        value: "SV"
    },
    {
        name: "Equatorial Guinea",
        code: "Equatorial Guinea",
        value: "GQ"
    },
    {
        name: "Eritrea",
        code: "Eritrea",
        value: "ER"
    },
    {
        name: "Estonia",
        code: "Estonia",
        value: "EE"
    },
    {
        name: "Ethiopia",
        code: "Ethiopia",
        value: "ET"
    },
    {
        name: "Falkland Islands (Malvinas)",
        code: "Falkland Islands (Malvinas)",
        value: "FK"
    },
    {
        name: "Faroe Islands",
        code: "Faroe Islands",
        value: "FO"
    },
    {
        name: "Fiji",
        code: "Fiji",
        value: "FJ"
    },
    {
        name: "Finland",
        code: "Finland",
        value: "FI"
    },
    {
        name: "France",
        code: "France",
        value: "FR"
    },
    {
        name: "French Guiana",
        code: "French Guiana",
        value: "GF"
    },
    {
        name: "French Polynesia",
        code: "French Polynesia",
        value: "PF"
    },
    {
        name: "French Southern Territories",
        code: "French Southern Territories",
        value: "TF"
    },
    {
        name: "Gabon",
        code: "Gabon",
        value: "GA"
    },
    {
        name: "Gambia",
        code: "Gambia",
        value: "GM"
    },
    {
        name: "Georgia",
        code: "Georgia",
        value: "GE"
    },
    {
        name: "Germany",
        code: "Germany",
        value: "DE"
    },
    {
        name: "Ghana",
        code: "Ghana",
        value: "GH"
    },
    {
        name: "Gibraltar",
        code: "Gibraltar",
        value: "GI"
    },
    {
        name: "Greece",
        code: "Greece",
        value: "GR"
    },
    {
        name: "Greenland",
        code: "Greenland",
        value: "GL"
    },
    {
        name: "Grenada",
        code: "Grenada",
        value: "GD"
    },
    {
        name: "Guadeloupe",
        code: "Guadeloupe",
        value: "GP"
    },
    {
        name: "Guam",
        code: "Guam",
        value: "GU"
    },
    {
        name: "Guatemala",
        code: "Guatemala",
        value: "GT"
    },
    {
        name: "Guernsey",
        code: "Guernsey",
        value: "GG"
    },
    {
        name: "Guinea",
        code: "Guinea",
        value: "GN"
    },
    {
        name: "Guinea-Bissau",
        code: "Guinea-Bissau",
        value: "GW"
    },
    {
        name: "Guyana",
        code: "Guyana",
        value: "GY"
    },
    {
        name: "Haiti",
        code: "Haiti",
        value: "HT"
    },
    {
        name: "Heard Island and Mcdonald Islands",
        code: "Heard Island and Mcdonald Islands",
        value: "HM"
    },
    {
        name: "Holy See (Vatican City State)",
        code: "Holy See (Vatican City State)",
        value: "VA"
    },
    {
        name: "Honduras",
        code: "Honduras",
        value: "HN"
    },
    {
        name: "Hong Kong",
        code: "Hong Kong",
        value: "HK"
    },
    {
        name: "Hungary",
        code: "Hungary",
        value: "HU"
    },
    {
        name: "Iceland",
        code: "Iceland",
        value: "IS"
    },
    {
        name: "India",
        code: "India",
        value: "IN"
    },
    {
        name: "Indonesia",
        code: "Indonesia",
        value: "ID"
    },
    {
        name: "Iran, Islamic Republic Of",
        code: "Iran, Islamic Republic Of",
        value: "IR"
    },
    {
        name: "Iraq",
        code: "Iraq",
        value: "IQ"
    },
    {
        name: "Ireland",
        code: "Ireland",
        value: "IE"
    },
    {
        name: "Isle of Man",
        code: "Isle of Man",
        value: "IM"
    },
    {
        name: "Israel",
        code: "Israel",
        value: "IL"
    },
    {
        name: "Italy",
        code: "Italy",
        value: "IT"
    },
    {
        name: "Jamaica",
        code: "Jamaica",
        value: "JM"
    },
    {
        name: "Japan",
        code: "Japan",
        value: "JP"
    },
    {
        name: "Jersey",
        code: "Jersey",
        value: "JE"
    },
    {
        name: "Jordan",
        code: "Jordan",
        value: "JO"
    },
    {
        name: "Kazakhstan",
        code: "Kazakhstan",
        value: "KZ"
    },
    {
        name: "Kenya",
        code: "Kenya",
        value: "KE"
    },
    {
        name: "Kiribati",
        code: "Kiribati",
        value: "KI"
    },
    {
        name: "Korea, Democratic People'S Republic of",
        code: "Korea, Democratic People'S Republic of",
        value: "KP"
    },
    {
        name: "Korea, Republic of",
        code: "Korea, Republic of",
        value: "KR"
    },
    {
        name: "Kuwait",
        code: "Kuwait",
        value: "KW"
    },
    {
        name: "Kyrgyzstan",
        code: "Kyrgyzstan",
        value: "KG"
    },
    {
        name: "Lao People'S Democratic Republic",
        code: "Lao People'S Democratic Republic",
        value: "LA"
    },
    {
        name: "Latvia",
        code: "Latvia",
        value: "LV"
    },
    {
        name: "Lebanon",
        code: "Lebanon",
        value: "LB"
    },
    {
        name: "Lesotho",
        code: "Lesotho",
        value: "LS"
    },
    {
        name: "Liberia",
        code: "Liberia",
        value: "LR"
    },
    {
        name: "Libyan Arab Jamahiriya",
        code: "Libyan Arab Jamahiriya",
        value: "LY"
    },
    {
        name: "Liechtenstein",
        code: "Liechtenstein",
        value: "LI"
    },
    {
        name: "Lithuania",
        code: "Lithuania",
        value: "LT"
    },
    {
        name: "Luxembourg",
        code: "Luxembourg",
        value: "LU"
    },
    {
        name: "Macao",
        code: "Macao",
        value: "MO"
    },
    {
        name: "Macedonia, The Former Yugoslav Republic of",
        code: "Macedonia, The Former Yugoslav Republic of",
        value: "MK"
    },
    {
        name: "Madagascar",
        code: "Madagascar",
        value: "MG"
    },
    {
        name: "Malawi",
        code: "Malawi",
        value: "MW"
    },
    {
        name: "Malaysia",
        code: "Malaysia",
        value: "MY"
    },
    {
        name: "Maldives",
        code: "Maldives",
        value: "MV"
    },
    {
        name: "Mali",
        code: "Mali",
        value: "ML"
    },
    {
        name: "Malta",
        code: "Malta",
        value: "MT"
    },
    {
        name: "Marshall Islands",
        code: "Marshall Islands",
        value: "MH"
    },
    {
        name: "Martinique",
        code: "Martinique",
        value: "MQ"
    },
    {
        name: "Mauritania",
        code: "Mauritania",
        value: "MR"
    },
    {
        name: "Mauritius",
        code: "Mauritius",
        value: "MU"
    },
    {
        name: "Mayotte",
        code: "Mayotte",
        value: "YT"
    },
    {
        name: "Mexico",
        code: "Mexico",
        value: "MX"
    },
    {
        name: "Micronesia, Federated States of",
        code: "Micronesia, Federated States of",
        value: "FM"
    },
    {
        name: "Moldova, Republic of",
        code: "Moldova, Republic of",
        value: "MD"
    },
    {
        name: "Monaco",
        code: "Monaco",
        value: "MC"
    },
    {
        name: "Mongolia",
        code: "Mongolia",
        value: "MN"
    },
    {
        name: "Montserrat",
        code: "Montserrat",
        value: "MS"
    },
    {
        name: "Morocco",
        code: "Morocco",
        value: "MA"
    },
    {
        name: "Mozambique",
        code: "Mozambique",
        value: "MZ"
    },
    {
        name: "Myanmar",
        code: "Myanmar",
        value: "MM"
    },
    {
        name: "Namibia",
        code: "Namibia",
        value: "NA"
    },
    {
        name: "Nauru",
        code: "Nauru",
        value: "NR"
    },
    {
        name: "Nepal",
        code: "Nepal",
        value: "NP"
    },
    {
        name: "Netherlands",
        code: "Netherlands",
        value: "NL"
    },
    {
        name: "Netherlands Antilles",
        code: "Netherlands Antilles",
        value: "AN"
    },
    {
        name: "New Caledonia",
        code: "New Caledonia",
        value: "NC"
    },
    {
        name: "New Zealand",
        code: "New Zealand",
        value: "NZ"
    },
    {
        name: "Nicaragua",
        code: "Nicaragua",
        value: "NI"
    },
    {
        name: "Niger",
        code: "Niger",
        value: "NE"
    },
    {
        name: "Nigeria",
        code: "Nigeria",
        value: "NG"
    },
    {
        name: "Niue",
        code: "Niue",
        value: "NU"
    },
    {
        name: "Norfolk Island",
        code: "Norfolk Island",
        value: "NF"
    },
    {
        name: "Northern Mariana Islands",
        code: "Northern Mariana Islands",
        value: "MP"
    },
    {
        name: "Norway",
        code: "Norway",
        value: "NO"
    },
    {
        name: "Oman",
        code: "Oman",
        value: "OM"
    },
    {
        name: "Pakistan",
        code: "Pakistan",
        value: "PK"
    },
    {
        name: "Palau",
        code: "Palau",
        value: "PW"
    },
    {
        name: "Palestinian Territory, Occupied",
        code: "Palestinian Territory, Occupied",
        value: "PS"
    },
    {
        name: "Panama",
        code: "Panama",
        value: "PA"
    },
    {
        name: "Papua New Guinea",
        code: "Papua New Guinea",
        value: "PG"
    },
    {
        name: "Paraguay",
        code: "Paraguay",
        value: "PY"
    },
    {
        name: "Peru",
        code: "Peru",
        value: "PE"
    },
    {
        name: "Philippines",
        code: "Philippines",
        value: "PH"
    },
    {
        name: "Pitcairn",
        code: "Pitcairn",
        value: "PN"
    },
    {
        name: "Poland",
        code: "Poland",
        value: "PL"
    },
    {
        name: "Portugal",
        code: "Portugal",
        value: "PT"
    },
    {
        name: "Puerto Rico",
        code: "Puerto Rico",
        value: "PR"
    },
    {
        name: "Qatar",
        code: "Qatar",
        value: "QA"
    },
    {
        name: "Reunion",
        code: "Reunion",
        value: "RE"
    },
    {
        name: "Romania",
        code: "Romania",
        value: "RO"
    },
    {
        name: "Russian Federation",
        code: "Russian Federation",
        value: "RU"
    },
    {
        name: "RWANDA",
        code: "RWANDA",
        value: "RW"
    },
    {
        name: "Saint Helena",
        code: "Saint Helena",
        value: "SH"
    },
    {
        name: "Saint Kitts and Nevis",
        code: "Saint Kitts and Nevis",
        value: "KN"
    },
    {
        name: "Saint Lucia",
        code: "Saint Lucia",
        value: "LC"
    },
    {
        name: "Saint Pierre and Miquelon",
        code: "Saint Pierre and Miquelon",
        value: "PM"
    },
    {
        name: "Saint Vincent and the Grenadines",
        code: "Saint Vincent and the Grenadines",
        value: "VC"
    },
    {
        name: "Samoa",
        code: "Samoa",
        value: "WS"
    },
    {
        name: "San Marino",
        code: "San Marino",
        value: "SM"
    },
    {
        name: "Sao Tome and Principe",
        code: "Sao Tome and Principe",
        value: "ST"
    },
    {
        name: "Saudi Arabia",
        code: "Saudi Arabia",
        value: "SA"
    },
    {
        name: "Senegal",
        code: "Senegal",
        value: "SN"
    },
    {
        name: "Serbia and Montenegro",
        code: "Serbia and Montenegro",
        value: "CS"
    },
    {
        name: "Seychelles",
        code: "Seychelles",
        value: "SC"
    },
    {
        name: "Sierra Leone",
        code: "Sierra Leone",
        value: "SL"
    },
    {
        name: "Singapore",
        code: "Singapore",
        value: "SG"
    },
    {
        name: "Slovakia",
        code: "Slovakia",
        value: "SK"
    },
    {
        name: "Slovenia",
        code: "Slovenia",
        value: "SI"
    },
    {
        name: "Solomon Islands",
        code: "Solomon Islands",
        value: "SB"
    },
    {
        name: "Somalia",
        code: "Somalia",
        value: "SO"
    },
    {
        name: "South Africa",
        code: "South Africa",
        value: "ZA"
    },
    {
        name: "South Georgia and the South Sandwich Islands",
        code: "South Georgia and the South Sandwich Islands",
        value: "GS"
    },
    {
        name: "Spain",
        code: "Spain",
        value: "ES"
    },
    {
        name: "Sri Lanka",
        code: "Sri Lanka",
        value: "LK"
    },
    {
        name: "Sudan",
        code: "Sudan",
        value: "SD"
    },
    {
        name: "Suriname",
        code: "Suriname",
        value: "SR"
    },
    {
        name: "Svalbard and Jan Mayen",
        code: "Svalbard and Jan Mayen",
        value: "SJ"
    },
    {
        name: "Swaziland",
        code: "Swaziland",
        value: "SZ"
    },
    {
        name: "Sweden",
        code: "Sweden",
        value: "SE"
    },
    {
        name: "Switzerland",
        code: "Switzerland",
        value: "CH"
    },
    {
        name: "Syrian Arab Republic",
        code: "Syrian Arab Republic",
        value: "SY"
    },
    {
        name: "Taiwan, Province of China",
        code: "Taiwan, Province of China",
        value: "TW"
    },
    {
        name: "Tajikistan",
        code: "Tajikistan",
        value: "TJ"
    },
    {
        name: "Tanzania, United Republic of",
        code: "Tanzania, United Republic of",
        value: "TZ"
    },
    {
        name: "Thailand",
        code: "Thailand",
        value: "TH"
    },
    {
        name: "Timor-Leste",
        code: "Timor-Leste",
        value: "TL"
    },
    {
        name: "Togo",
        code: "Togo",
        value: "TG"
    },
    {
        name: "Tokelau",
        code: "Tokelau",
        value: "TK"
    },
    {
        name: "Tonga",
        code: "Tonga",
        value: "TO"
    },
    {
        name: "Trinidad and Tobago",
        code: "Trinidad and Tobago",
        value: "TT"
    },
    {
        name: "Tunisia",
        code: "Tunisia",
        value: "TN"
    },
    {
        name: "Turkey",
        code: "Turkey",
        value: "TR"
    },
    {
        name: "Turkmenistan",
        code: "Turkmenistan",
        value: "TM"
    },
    {
        name: "Turks and Caicos Islands",
        code: "Turks and Caicos Islands",
        value: "TC"
    },
    {
        name: "Tuvalu",
        code: "Tuvalu",
        value: "TV"
    },
    {
        name: "Uganda",
        code: "Uganda",
        value: "UG"
    },
    {
        name: "Ukraine",
        code: "Ukraine",
        value: "UA"
    },
    {
        name: "United Arab Emirates",
        code: "United Arab Emirates",
        value: "AE"
    },
    {
        name: "United Kingdom",
        code: "United Kingdom",
        value: "GB"
    },
    {
        name: "United States",
        code: "United States",
        value: "US"
    },
    {
        name: "United States Minor Outlying Islands",
        code: "United States Minor Outlying Islands",
        value: "UM"
    },
    {
        name: "Uruguay",
        code: "Uruguay",
        value: "UY"
    },
    {
        name: "Uzbekistan",
        code: "Uzbekistan",
        value: "UZ"
    },
    {
        name: "Vanuatu",
        code: "Vanuatu",
        value: "VU"
    },
    {
        name: "Venezuela",
        code: "Venezuela",
        value: "VE"
    },
    {
        name: "Viet Nam",
        code: "Viet Nam",
        value: "VN"
    },
    {
        name: "Virgin Islands, British",
        code: "Virgin Islands, British",
        value: "VG"
    },
    {
        name: "Virgin Islands, U.S.",
        code: "Virgin Islands, U.S.",
        value: "VI"
    },
    {
        name: "Wallis and Futuna",
        code: "Wallis and Futuna",
        value: "WF"
    },
    {
        name: "Western Sahara",
        code: "Western Sahara",
        value: "EH"
    },
    {
        name: "Yemen",
        code: "Yemen",
        value: "YE"
    },
    {
        name: "Zambia",
        code: "Zambia",
        value: "ZM"
    },
    {
        name: "Zimbabwe",
        code: "Zimbabwe",
        value: "ZW"
    }
];
