import { Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/common/Header";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import FONTS from "../../config/fonts";
import { ReactElement } from "react";

const DetailPage = styled.div`
    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button {
        opacity: 1;
    }

    input::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    textarea.form-control {
        min-height: 124px;
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 24px;
        margin-bottom: 32px;
    }

    .radio {
        :not(:last-child) {
            margin-right: 48px;
        }
        label {
            display: flex;
            align-items: center;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;

            input {
                height: unset;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    form {
        max-width: 624px;
        margin-left: 109px;

        @media (max-width: 767.98px) {
            margin: 0 auto;
        }

        .describedata {
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.75);
        }

        .infonumber {
            display: flex;
            justify-content: space-between;

            .number {
                margin-right: 27px;
            }
        }
    }
`;

const InfoText = styled.span`
    font-family: ${FONTS.latoFont};
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.75);
`;

const DetailHeader = styled.div`
    max-width: 733px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;

    @media (max-width: 767.98px) {
        display: initial;
    }

    button {
        width: 158px;
    }
`;

const ProfileCircle = styled.div`
    width: 56px;
    height: 56px;
    background: var(--button-bgcolor);
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 53px;

    @media (max-width: 767.98px) {
        margin-right: 16px;
    }
`;

const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;

    @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 46px;
    }
`;

const DetailInfo = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        margin-bottom: 10px;
    }
`;

const RadioButton = styled.div`
    display: flex;
    margin-bottom: 48px;

    @media (max-width: 575.98px) {
        display: inherit;
    }
`;

const FormTitle = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #fbfbfb;
    margin-bottom: 32px;

    @media (max-width: 575.98px) {
        margin-top: 10px;
    }
`;

const DateGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    div {
        width: 297px;
    }

    input {
        display: flex;
        align-items: center;
        -webkit-text-fill-color: #ffffff;
    }
`;

const Detail = (): ReactElement => {
    return (
        <>
            <Header />
            <DetailPage>
                <Container>
                    <DetailHeader>
                        <DetailInfo>
                            <ProfileCircle>
                                <Link to={"/"}>
                                    <ArrowLeft />
                                </Link>
                            </ProfileCircle>
                            <Title>Brand details</Title>
                        </DetailInfo>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </DetailHeader>
                    <Form>
                        <FormTitle>Brand availability</FormTitle>
                        <RadioButton>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="option1" defaultChecked={true} />
                                    Online
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="option2" />
                                    Local
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input type="radio" value="option3" />
                                    Both
                                </label>
                            </div>
                        </RadioButton>
                        <Form.Group>
                            <Form.Label>
                                Hodlperk title <span className="astrik">*</span>
                            </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="infonumber">
                                <div>
                                    Detailed description <span className="astrik">*</span>
                                </div>
                                <div className="number">
                                    <InfoText>120</InfoText>
                                </div>
                            </Form.Label>
                            <Form.Control as="textarea" style={{ resize: "none" }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Website URL <InfoText>(if applicable)</InfoText>
                            </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <FormTitle>Claim settings</FormTitle>
                        <DateGroup>
                            <Form.Group>
                                <Form.Label>
                                    Hodlperk start date <span className="astrik">*</span>
                                </Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Hodlperk end date <span className="astrik">*</span>
                                </Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Total quantity available <span className="astrik">*</span>
                                </Form.Label>
                                <Form.Control type="number" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Quantity (per person) <span className="astrik">*</span>
                                </Form.Label>
                                <Form.Control type="number" />
                            </Form.Group>
                        </DateGroup>
                        <FormTitle>Redemption settings</FormTitle>
                        <Form.Group>
                            <Form.Label className="infonumber">
                                <div>
                                    Offer instructions <span className="astrik">*</span>
                                </div>
                                <div className="number">
                                    <InfoText>120</InfoText>
                                </div>
                            </Form.Label>
                            <p className="describedata">
                                Describe the actions that has to be performed in order to redeem the perk. If you have an URL or code that
                                hodlers should use during this process, please paste it in the fields below.
                            </p>
                            <Form.Control as="textarea" style={{ resize: "none" }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Code <InfoText>(optional)</InfoText>
                            </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Redemption URL <InfoText>(if applicable)</InfoText>
                            </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Form>
                </Container>
            </DetailPage>
        </>
    );
};

export default Detail;
