/* eslint-disable multiline-ternary */
import { Button, Container, Form, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/common/Header";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import Images from "../../config/images";
import { AppDispatch } from "../../types/model";
import { useDispatch, useSelector } from "react-redux";
import { updateCollection, getCollectionDetail, uploadCollectionLogoBanner } from "../../store/actions/dashboard";
import { IRootState } from "../../store";
import { ReactElement, RefObject, useEffect, useRef, useState } from "react";
import string from "../../utils/string";
import Spinner from "../../components/common/Spinner";

import { DetailPage, DetailHeader, ProfileCircle, Title, DetailInfo, SaveInfoBtnWrapper, FormSection } from "../indexStyle";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import moment from "moment";
import AlertModal from "../../components/alertModal";
import { toast } from "react-toastify";

const EditCollection = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();
    const { id } = useParams();
    const { collectionDetail } = useSelector((state: IRootState) => state?.dashboard);
    const elementRef = useRef() as RefObject<HTMLDivElement>;

    const [isLoader, setIsLoader] = useState(true);
    const [, setBannerImageData] = useState<any>(null);
    const [, setImageData] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });

    const editCollectionValidationSchema = Yup.object().shape({
        name: Yup.string().trim().required(string.titleErrorMessage),
        description: Yup.string().trim().required(string.descriptionErrorMessage),
        siteUrl: Yup.string().trim().required(string.siteUrlErrorMessage),
        twitter: Yup.string().trim().required(string.twitterErrorMessage),
        discord: Yup.string().trim().required(string.discordErrorMessage),
        medium: Yup.string().required(string.mediumErrorMessage),
        opensea: Yup.string().required(string.openseaErrorMessage)
    });

    useEffect(() => {
        if (id) {
            dispatch(
                getCollectionDetail(id, (res: any) => {
                    setIsLoader(false);
                    setImageData(res?.logo_image_url);
                    setBannerImageData(res?.banner_image_url);
                })
            );
        }
    }, []);
    const initialValues = {
        name: collectionDetail?.name || "",
        siteUrl: collectionDetail?.siteUrl || "",
        twitter: collectionDetail?.twitter || "",
        discord: collectionDetail?.discord || "",
        medium: collectionDetail?.medium || "",
        opensea: collectionDetail?.opensea,
        description: collectionDetail?.description || "",
        banner: collectionDetail?.banner_image_url || "",
        logo: collectionDetail?.logo_image_url || "",
        contractAddr: collectionDetail?.contractAddr || "",
        blockchain: collectionDetail?.blockchain || "",
        tokenStandard: collectionDetail?.tokenStandard || "",
        email: collectionDetail?.nftadmin.email,
        metadata: collectionDetail?.metadata,
        items: collectionDetail?.items,
        isFeatured: collectionDetail?.isFeatured,
        isDefaultRule: collectionDetail?.isDefaultRule,
        startDate: collectionDetail?.startDate,
        endDate: collectionDetail?.endDate
    };

    const onSubmit = (values: any) => {
        setIsSubmitting(true);

        const payload: any = {
            id,
            name: values.name,
            siteUrl: values.siteUrl,
            twitter: values.twitter,
            discord: values.discord,
            medium: values.medium,
            opensea: values.opensea,
            contractAddr: values.contractAddr,
            description: values.description,
            blockchain: values.blockchain,
            tokenStandard: values.tokenStandard,
            marketLink: values.marketLink,
            metadata: values.metadata,
            endDate: values.endDate ? moment(values?.endDate).format("DD/MM/YY") : null,
            startDate: values.startDate ? moment(values?.startDate).format("DD/MM/YY") : null,
            isFeatured: values.isFeatured,
            isDefaultRule: values.isDefaultRule
        };
        dispatch(
            updateCollection(payload, id, (status) => {
                if (status) {
                    navigate("/collections");
                }
                setIsSubmitting(false);
            })
        );
        values?.Banner && updateBanner(values.Banner);
        values?.Logo && updateLogo(values.Logo);
    };

    const updateBanner = (values: FileList) => {
        const formData: any = new FormData();
        formData.append("logo", values);
        formData.append("type", "Banner");
        if (id) {
            dispatch(uploadCollectionLogoBanner(id, formData));
        }
    };
    const updateLogo = (values: FileList) => {
        const formData: any = new FormData();
        formData.append("logo", values);
        formData.append("type", "Logo");
        if (id) {
            dispatch(uploadCollectionLogoBanner(id, formData));
        }
    };
    // const handleLogoBannerChange = async (e: any, setFieldValue: any, type: string) => {
    //     const [file] = e.target.files;
    //     if (type === "Banner") {
    //         setBannerImageData(URL.createObjectURL(file));
    //     } else {
    //         setImageData(URL.createObjectURL(file));
    //     }
    //     setFieldValue(type, e?.target?.files?.[0]);
    // };

    // handle scroll event
    const handleScroll = (elTopOffset: number, elHeight: number) => {
        if (window.pageYOffset > elTopOffset + elHeight) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    // add/remove scroll event listener
    useEffect(() => {
        const handleScrollEvent = () => {
            const header: any = elementRef?.current?.getBoundingClientRect();
            handleScroll(header?.top, header?.height);
        };

        window.addEventListener("scroll", handleScrollEvent);

        return () => {
            window.removeEventListener("scroll", handleScrollEvent);
        };
    }, [elementRef]);

    return (
        <>
            <Header />
            {!isLoader ? (
                <DetailPage>
                    <Formik initialValues={initialValues} validationSchema={editCollectionValidationSchema} onSubmit={onSubmit}>
                        {({ values, touched, handleSubmit, errors, handleChange, handleBlur, setFieldValue, dirty, isValid }) => (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <DetailHeader ref={elementRef} className={`${sticky.isSticky ? " sticky" : ""}`}>
                                        <Container>
                                            <div className="detailHeader">
                                                <DetailInfo>
                                                    <ProfileCircle>
                                                        <Link
                                                            onClick={() => setConfirmationModal(dirty)}
                                                            to={dirty ? location?.pathname : "/collections"}
                                                            state={location?.state}
                                                        >
                                                            <ArrowLeft />
                                                        </Link>
                                                    </ProfileCircle>
                                                    <Title>Collection details</Title>
                                                </DetailInfo>
                                                <SaveInfoBtnWrapper>
                                                    {dirty ? (
                                                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                            {isSubmitting && !confirmationModal ? <Spinner /> : "Save changes"}
                                                        </Button>
                                                    ) : null}
                                                </SaveInfoBtnWrapper>
                                            </div>
                                        </Container>
                                    </DetailHeader>
                                    {/* <ProfileCont>
                                            <BannerCont>
                                                <input
                                                    id="banner"
                                                    type="file"
                                                    accept="image/jpg,image/png,image/jpeg"
                                                    capture={true}
                                                    onChange={(e): any => handleLogoBannerChange(e, setFieldValue, "Banner")}
                                                />
                                                {bannerImageData && (
                                                    <img src={bannerImageData} alt="" className="banner image_object_cover" />
                                                )}
                                                <label htmlFor="banner" className="addBtn">
                                                    <p>+ Add a banner</p>
                                                </label>
                                            </BannerCont>
                                            <ProfileAdd>
                                                <ImgCont htmlFor="logo">
                                                    <input
                                                        id="logo"
                                                        type="file"
                                                        accept="image/jpg,image/png,image/jpeg"
                                                        capture={true}
                                                        onChange={(e): any => handleLogoBannerChange(e, setFieldValue, "Logo")}
                                                    />
                                                    {imageData ? (
                                                        <img src={imageData} alt="" className="logo image_object_cover" />
                                                    ) : (
                                                        <p>+ Add logo</p>
                                                    )}
                                                </ImgCont>
                                            </ProfileAdd>
                                            {errors.logo && touched.logo && (
                                                <div id="InputFeedback" className="errorMessage">
                                                    <img src={Images.errorIcon} /> {errors.logo}
                                                </div>
                                            )}
                                            {errors.banner && touched.banner && (
                                                <div id="InputFeedback" className="errorMessage">
                                                    <img src={Images.errorIcon} /> {errors.banner}
                                                </div>
                                            )}
                                        </ProfileCont> */}
                                    <Container>
                                        <FormSection>
                                            <Form.Group>
                                                <Form.Label>Contact email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values?.email}
                                                    disabled
                                                />
                                                {errors.name && touched.name && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.name}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>NFT collection name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values.name}
                                                    disabled
                                                />
                                                {errors.name && touched.name && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.name}
                                                    </div>
                                                )}
                                            </Form.Group>

                                            <Form.Group className="checkbox-field">
                                                <label
                                                    className={`custom_checkbox ${
                                                        collectionDetail &&
                                                        collectionDetail?.featuredCount === 3 &&
                                                        !collectionDetail?.isFeatured
                                                            ? "disabled"
                                                            : ""
                                                    }`}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={values.isFeatured}
                                                        onChange={() => {
                                                            if (
                                                                collectionDetail &&
                                                                collectionDetail?.featuredCount === 3 &&
                                                                !collectionDetail?.isFeatured
                                                            ) {
                                                                return false;
                                                            }

                                                            setFieldValue("isFeatured", !values.isFeatured);
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <p>Featured</p>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Contract address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="contractAddr"
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values.contractAddr}
                                                    disabled
                                                />
                                                {errors.contractAddr && touched.contractAddr && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.contractAddr}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Links</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="siteUrl"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder="Website"
                                                    value={values.siteUrl}
                                                />
                                                {errors.siteUrl && touched.siteUrl && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.siteUrl}
                                                    </div>
                                                )}
                                                <Form.Control
                                                    type="text"
                                                    name="discord"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder="Discord"
                                                    value={values.discord}
                                                />
                                                {errors.discord && touched.discord && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.discord}
                                                    </div>
                                                )}
                                                <Form.Control
                                                    type="text"
                                                    name="twitter"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder="Twitter"
                                                    value={values.twitter}
                                                />
                                                {errors.twitter && touched.twitter && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.twitter}
                                                    </div>
                                                )}
                                                <Form.Control
                                                    type="text"
                                                    name="medium"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder="Medium"
                                                    value={values.medium}
                                                />
                                                {errors.medium && touched.medium && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.medium}
                                                    </div>
                                                )}
                                                <Form.Control
                                                    type="text"
                                                    name="opensea"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder="Opensea"
                                                    value={values.opensea}
                                                />
                                                {errors.opensea && touched.opensea && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.opensea}
                                                    </div>
                                                )}
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className="infonumber">
                                                    <div>
                                                        Description <span className="astrik">*</span>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    type="text"
                                                    name="description"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values.description}
                                                    style={{ resize: "none" }}
                                                />
                                                {errors.description && touched.description && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.description}
                                                    </div>
                                                )}
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Maximum items in collection</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="items"
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values.items}
                                                />
                                                {errors.items && touched.items && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.items}
                                                    </div>
                                                )}
                                            </Form.Group>

                                            <Form.Group className="checkbox-field">
                                                <label className="custom_checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={values.isDefaultRule}
                                                        onChange={() => {
                                                            setFieldValue("isDefaultRule", !values.isDefaultRule);
                                                        }}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <p>Use default rules</p>
                                            </Form.Group>

                                            <Form.Group className="mb-4 d-block">
                                                <Form.Label>Collection&apos;s blockchain</Form.Label>
                                                <RadioGroup defaultValue="" aria-label="blockChain" name="customized-radios">
                                                    <FormControlLabel
                                                        value="ETHEREUM"
                                                        control={
                                                            <Radio
                                                                checked={values.blockchain === "ETHEREUM"}
                                                                onChange={handleChange}
                                                                value="ETHEREUM"
                                                                name="blockchain"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="ETHEREUM"
                                                    />
                                                    <FormControlLabel
                                                        value="MATIC"
                                                        control={
                                                            <Radio
                                                                checked={values.blockchain === "MATIC"}
                                                                onChange={handleChange}
                                                                value="MATIC"
                                                                name="blockchain"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="MATIC"
                                                    />
                                                    <FormControlLabel
                                                        value="Other"
                                                        control={
                                                            <Radio
                                                                checked={values.blockchain === "Other"}
                                                                onChange={handleChange}
                                                                value="Other"
                                                                name="blockchain"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Other"
                                                    />
                                                </RadioGroup>
                                            </Form.Group>

                                            <Form.Group className="mb-4 d-block">
                                                <Form.Label>Contract standard</Form.Label>
                                                <RadioGroup defaultValue="" aria-label="blockChain" name="customized-radios">
                                                    <FormControlLabel
                                                        value="ERC721"
                                                        control={
                                                            <Radio
                                                                checked={values.tokenStandard === "ERC721"}
                                                                onChange={handleChange}
                                                                value="ERC721"
                                                                name="tokenStandard"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="ERC721"
                                                    />
                                                    <FormControlLabel
                                                        value="ERC1155"
                                                        control={
                                                            <Radio
                                                                checked={values.tokenStandard === "ERC1155"}
                                                                onChange={handleChange}
                                                                value="ERC1155"
                                                                name="tokenStandard"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="ERC1155"
                                                    />
                                                    <FormControlLabel
                                                        value="Other"
                                                        control={
                                                            <Radio
                                                                checked={values.tokenStandard === "Other"}
                                                                onChange={handleChange}
                                                                value="Other"
                                                                name="tokenStandard"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Other"
                                                    />
                                                </RadioGroup>
                                            </Form.Group>

                                            <Form.Group className="mb-4 d-block">
                                                <Form.Label>Contract standard</Form.Label>
                                                <RadioGroup defaultValue="" aria-label="blockChain" name="customized-radios">
                                                    <FormControlLabel
                                                        value="Fixed"
                                                        control={
                                                            <Radio
                                                                checked={values.metadata === "Fixed"}
                                                                onChange={handleChange}
                                                                value="Fixed"
                                                                name="metadata"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Fixed"
                                                    />
                                                    <FormControlLabel
                                                        value="Dynamic"
                                                        control={
                                                            <Radio
                                                                checked={values.metadata === "Dynamic"}
                                                                onChange={handleChange}
                                                                value="Dynamic"
                                                                name="metadata"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Dynamic"
                                                    />
                                                </RadioGroup>
                                            </Form.Group>

                                            <Form.Group>
                                                <div className="form-row d-flex dategroup">
                                                    <Col md={6} sm={12} xs={12} className="pe-2">
                                                        <Form.Label>
                                                            Project&apos;s sale start date <small className="fw-light">(optional)</small>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="startDate"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete={"off"}
                                                            placeholder="Start date"
                                                            value={moment(values.startDate).format("YYYY-MM-DD")}
                                                            className={`dateplaceholder ${values.startDate === "" ? "empty" : "not-empty"}`}
                                                        />
                                                    </Col>
                                                    <Col md={6} sm={12} xs={12} className="ps-2">
                                                        <Form.Label>
                                                            Project&apos;s sale end date <small className="fw-light">(optional)</small>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="endDate"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete={"off"}
                                                            placeholder="End date"
                                                            value={moment(values.endDate).format("YYYY-MM-DD")}
                                                            className={`dateplaceholder ${values.endDate === "" ? "empty" : "not-empty"}`}
                                                        />
                                                    </Col>
                                                </div>
                                            </Form.Group>
                                        </FormSection>
                                    </Container>
                                    <AlertModal
                                        string={"Save changes?"}
                                        yesButton={isSubmitting ? <Spinner /> : "Save"}
                                        closeButton="Cancel"
                                        open={confirmationModal}
                                        setOpen={() => setConfirmationModal(false)}
                                        image={Images.saveIcon}
                                        handleRemove={() => {
                                            if (isValid) {
                                                handleSubmit();
                                            } else {
                                                toast.error("Please enter valid data");
                                                setConfirmationModal(false);
                                            }
                                        }}
                                        onCancelButtonClick={() => navigate("/collections", { state: location?.state })}
                                    />
                                </Form>
                            </>
                        )}
                    </Formik>
                </DetailPage>
            ) : null}
        </>
    );
};

export default EditCollection;
