import apiCall, { Fail, Callback } from "./apiUtils/ApiCall";
import config from "./Config";

export interface LoginPayload {
    email: string;
    password: string;
}
export interface editProfilePayload {
    email?: string;
    oldPassword?: string;
    password?: string;
}

const authApi = {
    login(callback: Callback, fail: Fail, payload: LoginPayload): void {
        apiCall.makePostRequest(config.auth.login, callback, fail, payload, {});
    },
    getProfile(callback: Callback, fail: Fail): void {
        apiCall.makeGetRequest(config.auth.getProfile, callback, fail, {});
    },
    updateProfile(callback: Callback, fail: Fail, payload: editProfilePayload): void {
        apiCall.makePostRequest(config.auth.updateProfile, callback, fail, payload, {});
    }
};

export default authApi;
