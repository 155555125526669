import { UserData, Profile } from "../../types/model";
import { PayloadAction, GetProfileStart, GetProfileEnd, UpdateProfile, LOGIN } from "./types";
// import { PayloadAction, LOGIN } from "./types";

interface InitialValues {
    userInfo: UserData | null;
    userProfile: Profile | null;
}

const initialValues: InitialValues = {
    userInfo: null,
    userProfile: null
};

const AuthReducer = (state = initialValues, action: PayloadAction): InitialValues => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                userInfo: action?.payload?.data
            };
        case GetProfileStart:
            return {
                ...state,
                userProfile: null
            };
        case GetProfileEnd:
            return {
                ...state,
                userProfile: action?.payload?.data
            };
        case UpdateProfile:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...action?.payload?.data
                }
            };
        default:
            return state;
    }
};

export default AuthReducer;
