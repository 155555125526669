import { ReactElement } from "react";
import styled from "styled-components";

const Loader = styled.div`
    width: 50px;
    height: 15px;
    justify-content: center;
    display: flex;
    width: 100%;

    .loader {
        top: -21px !important;
        display: flex;
        flex-wrap: nowrap;
    }

    .loader,
    .loader:before,
    .loader:after {
        border-radius: 50%;
        width: 2.5em !important;
        height: 2.5em !important;
        -webkit-animation-fill-mode: both !important;
        animation-fill-mode: both !important;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
    }
    .loader {
        color: #ffffff !important;
        font-size: 5px !important;
        margin: 10px auto !important;
        position: relative;
        text-indent: -9999em !important;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
        content: "";
        position: absolute;
        top: 0;
    }
    .loader:before {
        left: -3.5em !important;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 3.5em !important;
    }
    @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
    @keyframes load7 {
        0%,
        80%,
        100% {
            box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
            box-shadow: 0 2.5em 0 0;
        }
    }
`;

const Spinner = (): ReactElement => {
    return (
        <>
            <Loader>
                <span className="loader"></span>
            </Loader>
        </>
    );
};

export default Spinner;
