export enum AccountStatus {
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
    SUBSCRIBED = "SUBSCRIBED",
    SUSPENDED = "SUSPENDED"
}

export enum SocialLinks {
    TWITTER = "twitter",
    SITEURL = "siteUrl",
    DISCORD = "discord",
    MEDIUM = "medium",
    MARKETLINK = "marketLink",
    FACEBOOK = "facebook",
    OPENSEA = "opensea"
}
