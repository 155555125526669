import { ReactElement, useMemo, useCallback, useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Table as BootstrapTable } from "react-bootstrap";

import Spinner from "./common/Spinner";
import { PageNumber, PageSelect, PaginationPage, SpinnerContainer, TableComponent } from "./styled";
import { TableProps } from "./types";

// eslint-disable-next-line comma-spacing
const Table = <T,>(props: TableProps<T>): ReactElement => {
    const { renderHeader, renderData, data, isLoading, setSelected, pageSize, offset, setPageSize, setOffset } = props;

    const [currentPage, setCurrentPage] = useState(Math.ceil(offset / pageSize));
    const totalPages = useMemo(() => new Array(Math.ceil((data?.result?.count ?? 0) / pageSize)).fill(0), [data?.result?.count, pageSize]);

    const resetSelectedItems = useCallback(() => {
        if (setSelected) setSelected([]);
    }, [setSelected]);

    const handlePrevious = useCallback(() => {
        if (offset <= 0) return;

        setOffset(offset - pageSize);
        resetSelectedItems();
    }, [pageSize, offset]);

    const handleNext = useCallback(() => {
        if (currentPage >= totalPages?.length - 1) return;

        setOffset(offset + pageSize);
        resetSelectedItems();
    }, [pageSize, offset, data?.result?.count, totalPages, currentPage]);

    useEffect(() => {
        setCurrentPage(Math.ceil(offset / pageSize));
    }, [offset, pageSize]);

    return (
        <>
            <TableComponent className="d-flex flex-column">
                <BootstrapTable responsive>
                    <thead>{renderHeader()}</thead>
                    {!isLoading && data ? (
                        <>{data?.result?.rows?.length >= 1 && <tbody>{data?.result?.rows?.map(renderData)}</tbody>}</>
                    ) : null}
                </BootstrapTable>
            </TableComponent>
            {!isLoading && data?.result?.rows?.length === 0 && <p className="dataNotFound">No data is available</p>}
            {isLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : null}
            {data?.result?.rows?.length !== 0 && (
                <PageNumber className="mt-3">
                    <PageSelect>
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setOffset(0);
                                setPageSize(Number(e.target.value));
                                resetSelectedItems();
                            }}
                        >
                            {[10, 25, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </PageSelect>
                    <h6>per page</h6>
                </PageNumber>
            )}
            {data?.result?.rows?.length !== 0 && (
                <>
                    {/* <PaginationPage>
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className={`page-item ${offset <= 0 ? "disabled" : ""}`} onClick={handlePrevious}>
                                    <span className="page-link" tabIndex={-1} aria-disabled="true">
                                        Prev
                                    </span>
                                </li>
                                {totalPages?.map((_, index) => (
                                    <li
                                        className={`page-item ${offset === pageSize * index ? "active" : ""}`}
                                        key={index}
                                        onClick={() => {
                                            setOffset(pageSize * index);
                                            resetSelectedItems();
                                        }}
                                    >
                                        <span className="page-link">{index + 1}</span>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage >= totalPages?.length - 1 ? "disabled" : ""}`} onClick={handleNext}>
                                    <span className="page-link">Next</span>
                                </li>
                            </ul>
                        </nav>
                    </PaginationPage> */}
                    <PaginationPage>
                        <nav aria-label="...">
                            <ReactPaginate
                                nextLabel={
                                    <li
                                        className={`page-item ${currentPage >= totalPages?.length - 1 ? "disabled" : ""}`}
                                        onClick={handleNext}
                                    >
                                        <span className="page-link">Next</span>
                                    </li>
                                }
                                className="pagination"
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={totalPages?.length || 0}
                                previousLabel={
                                    <li className={`page-item ${offset <= 0 ? "disabled" : ""}`} onClick={handlePrevious}>
                                        <span className="page-link" tabIndex={-1} aria-disabled="true">
                                            Prev
                                        </span>
                                    </li>
                                }
                                pageClassName="page-item"
                                breakLabel={
                                    <li className={"page-item"}>
                                        <span className="page-link" aria-disabled="true">
                                            ...
                                        </span>
                                    </li>
                                }
                                containerClassName="pagination"
                                forcePage={(offset / pageSize)}
                                activeClassName="active"
                                renderOnZeroPageCount={() => null}
                                pageLabelBuilder={(page) => (
                                    <li
                                        className={`page-item ${offset === pageSize * (page - 1) ? "active" : ""}`}
                                        onClick={() => {
                                            setOffset(pageSize * (page - 1));
                                            resetSelectedItems();
                                        }}
                                    >
                                        <span className="page-link">{page}</span>
                                    </li>
                                )}
                            />
                        </nav>
                    </PaginationPage>
                </>
            )}
        </>
    );
};

export default Table;
