import styled from "styled-components";
import FONTS from "../../config/fonts";

export const DetailPage = styled.div`
    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button {
        opacity: 1;
    }

    input::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    textarea.form-control {
        min-height: 124px;
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 24px;
        margin-bottom: 32px;
        color: #ffffff;
    }

    .radio {
        :not(:last-child) {
            margin-right: 48px;
        }
        label {
            display: flex;
            align-items: center;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;

            input {
                height: unset;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    form {
        max-width: 624px;
        margin-left: 109px;

        .describedata {
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.75);
        }

        .infonumber {
            display: flex;
            justify-content: space-between;

            .number {
                margin-right: 27px;
            }
        }
    }

    .errorMessage {
        position: relative;
        top: -25px;
    }
`;

export const DetailHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;

    button {
        width: 158px;
    }
`;

export const ProfileCircle = styled.div`
    width: 56px;
    height: 56px;
    background: var(--button-bgcolor);
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 53px;
`;

export const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;
`;

export const DetailInfo = styled.div`
    display: flex;
`;

export const ProfileCont = styled.div`
    position: relative;
    margin-bottom: 30px;
`;

export const ProfileAdd = styled.div`
    display: flex;
    justify-content: center;
`;

export const ImgCont = styled.label`
    background-color: rgba(47, 47, 47, 0.4);
    border-radius: 50%;
    width: 172px;
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 30px;
    border: 3px dashed rgba(255, 255, 255, 0.2);
    z-index: 9;
    cursor: pointer;
    & input {
        width: 1px;
        height: 1px;
        opacity: 0;
        position: absolute;
        overflow: hidden;
        z-index: -1;
    }
    @media only screen and (max-width: 576px) {
        width: 132px;
        height: 132px;
        & .plus {
            width: 32px;
            height: 32px;
        }
    }
    & .plus {
        height: 32px;
        width: 32px;
        margin-bottom: 5px;
    }
    & .logo {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
    & p {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #d52b2b;
        width: 100%;
        text-align: center;
    }
`;
