import { ReactElement, useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import debouce from "lodash/debounce";

import { AppDispatch, BulkDeletePayload, DeletePayload, EmailPayload, UpdateProviderApproved, User } from "../../types/model";
import { IRootState } from "../../store";
import { uploadCSV, exportCSV } from "../../store/actions/dashboard";
// import { ReactComponent as UpArrow } from "../../assets/images/upArrow.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/copyicon.svg";
import AlertModal from "../../components/alertModal";
import Images from "../../config/images";
import { Topbar } from "./../../components/common/Header";
import Spinner from "../../components/common/Spinner";
import { UsersStyled, UserTitle, UserSection, UserType } from "./styled";
import { approvedUnapproved, DeleteUser, ListUser, removeBulkDelete, sentMail } from "../../store/actions/user";
import useTable from "../../hooks/useTable";
import Table from "../../components/table";
import UploadModel from "../../components/uploadModel";
import { handleSnackToast } from "../../components/common/Snackbar";
import { Severities } from "../../store/reducers/toast";
import { Tooltip } from "@mui/material";

const Users = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();

    const { userList } = useSelector((state: IRootState) => state?.user);

    const [show, setShow] = useState(false);
    const [userId, setUserId] = useState(0);
    const [enableModalShow, setEnableModalShow] = useState(false);
    const [order, setOrder] = useState("ASC");
    const [field, setField] = useState("");
    const [enableMultipleUserShow, setEnableMultipleUserShow] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [csv, setCsv] = useState("");
    const [csvFile, setCsvFile] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [userType, setUserType] = useState("");
    const [search, setSearch] = useState("");
    const [showMailBox, setShowMailBox] = useState(false);
    const [messege, setMessege] = useState<any>();

    const { pageSize, setPageSize, offset, setOffset, selectedItems, setSelectedItems, isLoading, setIsLoading } = useTable();

    useEffect(() => {
        setIsLoading(true);
        dispatch(
            ListUser(offset, pageSize, false, order, field, userType, search, () => {
                setIsLoading(false);
            })
        );
    }, [pageSize, offset, order, search, userType]);

    const userTypeArr = [
        { type: "", text: "All" },
        { type: "collection", text: "Collection owner" },
        // { type: "brand", text: "Brand owner" },
        { type: "moderator", text: "Moderator" },
        { type: "end_user", text: "End user" }
    ];

    const handleFilter = (type: string) => {
        setActiveFilter(type);
        setUserType(type);
        dispatch(ListUser(offset, pageSize, false, order, field, type, search));
    };

    // const allSelected = useMemo(
    //     () => userList?.result.rows.length && selectedItems.length === userList?.result.rows.length,
    //     [selectedItems, userList?.result]
    // );

    // const userListLength = userList?.result.rows.length ?? 0;

    const handlePostAction = () => {
        setOffset(0);
        setField("");
        setOrder("ASC");
        setShowUploadModal(false);
        setCsv("");
        setCsvFile("");
        setSelectedItems([]);

        dispatch(
            ListUser(0, pageSize, false, "ASC", field, "", search, () => {
                setIsLoading(false);
            })
        );
        setIsSubmitting(false);
    };

    const handleDelete = () => {
        setIsSubmitting(true);
        setIsLoading(true);

        const payload: DeletePayload = {
            modelName: "user",
            id: userId
        };

        dispatch(DeleteUser(payload, userId, handlePostAction));
        setShow(false);
    };

    const handleBulkDelete = () => {
        setIsLoading(true);
        setIsSubmitting(true);
        const payload: BulkDeletePayload = {
            modelName: "user",
            ids: selectedItems
        };
        dispatch(removeBulkDelete(payload, handlePostAction));
        setEnableMultipleUserShow(false);
    };

    // const handleSwitch = (user: any) => {
    //     setUserId(user.id);
    //     if (user.active === 1) {
    //         setEnableModalShow(true);
    //     } else {
    //         const payload: UpdateStatus = {
    //             modelName: "user",
    //             value: {
    //                 active: Number(user.active) === 1 ? 0 : 1
    //             },
    //             id: user.id
    //         };
    //         dispatch(enableDisable(payload, user?.id));
    //     }
    // };

    const handleEnableUser = () => {
        setIsSubmitting(true);
    };

    const handleDeleteMultipleUser = () => {
        setEnableMultipleUserShow(true);
    };

    const handleExportCSV = () => {
        const payload: any = {
            type: "user",
            id: selectedItems
        };
        dispatch(
            exportCSV(payload, (res: any) => {
                window.open(res?.data, "_blank");
            })
        );
    };

    const uploadCsv = () => {
        setIsLoading(true);
        const formData: any = new FormData();
        formData.append("logo", csvFile);
        dispatch(uploadCSV(formData, handlePostAction));
    };

    const handleUploadCsv = (e: any) => {
        setCsv(e.username);
        setCsvFile(e);
    };

    const handleUserSearch = debouce((e: any) => {
        setOffset(0);
        setSearch(e.target.value);
    }, 500);

    const handleUserEmail = () => {
        setShowMailBox(true);
    };
    const handleEmailSend = () => {
        const payload: EmailPayload = {
            id: selectedItems,
            message: messege
        };
        dispatch(sentMail(payload, handlePostAction));
        setShowMailBox(false);
    };

    const renderHeader = () => {
        return (
            <tr>
                {/* <th>
                    <label className="custom_checkbox">
                        <input
                            type="checkbox"
                            checked={selectedItems.length === userListLength}
                            onChange={() => {
                                if (allSelected) {
                                    setSelectedItems([]);
                                } else {
                                    setSelectedItems(userList?.result?.rows?.map((user: User) => user.id) ?? []);
                                }
                            }}
                        />
                        <span
                            className={`checkmark ${
                                userList?.result?.rows?.length && selectedItems.length === userList?.result.rows.length ? "" : ""
                            } ${selectedItems.length > 0 && selectedItems.length !== userList?.result.rows.length ? "dash" : ""}`}
                        ></span>
                    </label>
                </th> */}
                <th>#</th>
                <th className="text-center">USER STATUS</th>
                <th>USER TYPE</th>
                <th className="external-link">
                    NAME
                    {/* <UpArrow
                        onClick={() => {
                            setOrder(order === "ASC" ? "DESC" : "ASC");
                            setField("username");
                        }}
                        className={order === "ASC" ? "DESC" : "ASC"}
                    /> */}
                </th>
                <th>EMAIL</th>
                <th className="text-center">WALLET ADDRESS</th>
                {/* <th>Status</th> */}
            </tr>
        );
    };

    const handleCopyAddress = () => {
        handleSnackToast("Wallet address copied", Severities.SUCCESS);
    };

    const handleProvider = (user: any) => {
        setUserId(user.id);
        if (user.providerApproved !== 1) {
            const payload: UpdateProviderApproved = {
                modelName: "user",
                value: {
                    providerApproved: Number(user.providerApproved) === 1 ? 0 : 1
                },
                id: user.id
            };
            dispatch(approvedUnapproved(payload, user?.id));
        }
    };

    const renderUser = (user: User, index: number) => {
        const address = user?.addresses?.[0]?.address;
        return (
            <tr key={user.id}>
                {/* <td>
                    <label className="custom_checkbox">
                        <input
                            checked={selectedItems.includes(user.id)}
                            type="checkbox"
                            onChange={() => {
                                if (selectedItems.includes(user.id)) {
                                    setSelectedItems(selectedItems.filter((item) => item !== user.id));
                                } else {
                                    setSelectedItems([...selectedItems, user.id]);
                                }
                            }}
                        />
                        <span className="checkmark"></span>
                    </label>
                </td> */}
                <td className="indexnumber">#{index + 1}</td>
                <td className="provider">
                    <button
                        className={`${user.providerApproved === true ? "active approvedUser" : "approvedUser"}`}
                        onClick={() => {
                            handleProvider(user);
                        }}
                        disabled={user.providerApproved}
                    >
                        {user.providerApproved ? (
                            <Tooltip title="Approved" placement="top">
                                <Images.checkAutopilotIcon />
                            </Tooltip>
                        ) : (
                            // <OverlayTrigger placement="top" overlay={<Tooltip>Approved</Tooltip>}>
                            //     <Image src={Images.approvedIcon} />
                            // </OverlayTrigger>
                            <Tooltip title="Approved" placement="top">
                                <Images.checkAutopilotIcon />
                            </Tooltip>
                        )}
                    </button>
                    <button
                        className={`${user.providerApproved === false ? "active ms-2 rejected" : "ms-2 rejected"}`}
                        onClick={() => {
                            handleProvider(user);
                        }}
                        disabled={!user.providerApproved}
                    >
                        {!user.providerApproved ? (
                            // <OverlayTrigger placement="top" overlay={<Tooltip>Rejected</Tooltip>}>
                            //     <Image src={Images.rejectedIcon} />
                            // </OverlayTrigger>
                            <Tooltip title="Rejected" placement="top">
                                <Images.suspendAutopilotIcon />
                            </Tooltip>
                        ) : (
                            // <OverlayTrigger placement="top" overlay={<Tooltip>Rejected</Tooltip>}>
                            //     <Image src={Images.rejectedIcon} />
                            // </OverlayTrigger>
                            <Tooltip title="Rejected" placement="top">
                                <Images.suspendAutopilotIcon />
                            </Tooltip>
                        )}
                    </button>
                </td>
                <td className="text-capitalize">#{user.tag.join(", ").replace(/_/g, " ")}</td>
                <td>{user.username ? user.username : "-"}</td>
                <td>{user.email ? <a href={`mailto: ${user.email}`}>{user.email}</a> : "-"}</td>
                <td>
                    {user?.addresses?.[0]?.address ? (
                        <span className="copyicon-sec">
                            <h5>
                                {user?.addresses?.[0]?.address.substring(0, 5) +
                                    "..." +
                                    user?.addresses?.[0]?.address.substring(user?.addresses?.[0]?.address.length - 5)}
                            </h5>
                            <CopyToClipboard text={address}>
                                <CopyIcon onClick={handleCopyAddress} />
                            </CopyToClipboard>
                        </span>
                    ) : (
                        <span className="text-center"> -</span>
                    )}
                </td>

                {/* <td className="Switchicon">
                    <label className="switch">
                        <input type="checkbox" checked={user?.active} onChange={() => handleSwitch(user)} />
                        <span className="slider round"></span>
                    </label>
                </td> */}
            </tr>
        );
    };

    return (
        <>
            <Topbar
                selectedBrand={selectedItems.length}
                classname={selectedItems?.length ? "" : "d-none"}
                handleDeleteMultipleBrand={handleDeleteMultipleUser}
                setSelectedBrand={setSelectedItems}
                handleExportCSV={handleExportCSV}
                handleEmail={handleUserEmail}
                bulkEmail={true}
            />

            <UsersStyled className="d-flex flex-column">
                <UserSection>
                    <UserTitle>
                        <h2>
                            Users
                            {userList?.result?.count && (
                                <>
                                    <sub className="ms-2">
                                        <span>
                                            (<p> {userList?.result?.count}</p>)
                                        </span>
                                    </sub>
                                </>
                            )}
                        </h2>
                    </UserTitle>
                    <Form>
                        <Form.Group className="searchbox">
                            <Form.Control type="text" placeholder="Search by name or wallet address" onChange={handleUserSearch} />
                            <span>
                                <Search />
                            </span>
                        </Form.Group>
                    </Form>
                </UserSection>
                <UserType>
                    {userTypeArr.map((user, index: number) => (
                        <div key={index}>
                            <h4 onClick={() => handleFilter(user.type)} className={`${activeFilter === user.type ? "active" : null}`}>
                                #{user.text}
                            </h4>
                        </div>
                    ))}
                </UserType>

                <Table
                    renderHeader={renderHeader}
                    renderData={renderUser}
                    data={userList}
                    isLoading={isLoading}
                    offset={offset}
                    pageSize={pageSize}
                    setOffset={setOffset}
                    setPageSize={setPageSize}
                    setSelected={setSelectedItems}
                />
                <AlertModal
                    open={enableModalShow}
                    string={"Disable user"}
                    setOpen={setEnableModalShow}
                    handleRemove={handleEnableUser}
                    closeButton={"No"}
                    yesButton={isSubmitting ? <Spinner /> : "Yes"}
                />
                <AlertModal
                    open={show}
                    string={"Remove User?"}
                    setOpen={setShow}
                    handleRemove={handleDelete}
                    image={Images.removeIcon}
                    closeButton={"Cancel"}
                    yesButton={isSubmitting ? <Spinner /> : "Remove"}
                />
                <AlertModal
                    open={enableMultipleUserShow}
                    string={`Remove ${selectedItems?.length} users?`}
                    setOpen={setEnableMultipleUserShow}
                    handleRemove={handleBulkDelete}
                    image={Images.removeIcon1}
                    closeButton={"Cancel"}
                    yesButton={isSubmitting ? <Spinner /> : "Remove"}
                />
                <AlertModal
                    open={showMailBox}
                    string={"Send email"}
                    setOpen={setShowMailBox}
                    handleRemove={handleEmailSend}
                    description={selectedItems}
                    image={Images.emailIconRounded}
                    closeButton={"Cancel"}
                    yesButton={isSubmitting ? <Spinner /> : "Submit"}
                    showInputField={true}
                    setMessege={setMessege}
                />
                <UploadModel
                    show={showUploadModal}
                    setShow={setShowUploadModal}
                    handleUploadCsv={handleUploadCsv}
                    csv={csv}
                    setCsv={setCsv}
                    uploadCsv={uploadCsv}
                    setCsvFile={setCsvFile}
                />
            </UsersStyled>
        </>
    );
};

export default Users;
