import { ReactElement } from "react";
import styled from "styled-components";
import FONTS from "../../config/fonts";

const Title = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 16px;
`;

const SubTitle = styled.h4`
    font-family: ${FONTS.interFont};
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0;
`;

const Perks = (): ReactElement => {
    return (
        <>
            <Title>Perks</Title>
            <SubTitle>Coming soon</SubTitle>
        </>
    );
};

export default Perks;
