import { ReactElement, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import FONTS from "../../config/fonts";
import { Tooltip } from "@mui/material";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import Calendar from "../../assets/images/calendar.svg";
import moment from "moment";
import Images from "../../config/images";
import useTable from "../../hooks/useTable";
import Table from "../../components/table";

import { ReactComponent as EyeIcon } from "../../assets/images/eyeIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/copyicon.svg";
import { handleSnackToast } from "../../components/common/Snackbar";
import { Severities } from "../../store/reducers/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { RedemptionLogParams, RedemptionLogPayload } from "./types";
import { AppDispatch } from "../../types/model";
import { useDispatch } from "react-redux";
import { getRedemptionLog } from "../../store/actions/dashboard";

const LogStyled = styled.div`
    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    .searchbox {
        position: relative;
        width: 514px;

        input {
            margin-bottom: 0;
        }

        span {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            right: 20px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .copyicon-sec {
        background-color: #ffffff;
        border-radius: 48px;
        padding: 9px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 194px;
        height: 40px;

        h5 {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #221f1f;
            margin-bottom: 0;
            margin-right: 12px;
        }

        svg {
            cursor: pointer;
        }
    }

    .action-icon {
        &.log-action-icon {
            svg {
                margin-right: 0 !important;
            }
        }
    }
`;

const Redemption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: 767.98px) {
        display: inherit;
    }

    h3 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #ffffff;
        margin-bottom: 0;

        @media (max-width: 767.98px) {
            margin-bottom: 20px;
        }
    }
`;

const DateGroup = styled.div`
    display: flex;
    gap: 30px;

    input {
        width: 297px;
        background-image: url("${Calendar}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;
        display: flex;
        align-items: center;
    }

    input::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    @media (max-width: 767.98px) {
        display: inherit;
    }
`;

const Logs = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const [data, setData] = useState(null);
    const [searchText, setSearchText] = useState<string>(location?.state?.data?.searchText || "");
    const [tillDate, setTillDate] = useState<string>(location?.state?.data?.tillDate || "");
    const [dateFrom, setDateFrom] = useState<string>(location?.state?.data?.dateFrom || "");

    const { pageSize, setPageSize, offset, setOffset, isLoading, setIsLoading } = useTable();

    const handleCopyAddress = () => {
        handleSnackToast("Wallet address copied", Severities.SUCCESS);
    };

    const afterGetRedemptionLog = ({ status, res }: any): void => {
        if (status && res) {
            setData(res);
        }
        setIsLoading(false);
    };

    const handleGetRedemptionLog = () => {
        setIsLoading(true);
        console.log(moment(dateFrom).format("YYYY-MM-DD"), "dateFrom");
        const payload: RedemptionLogPayload = {
            dateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : "",
            tillDate: tillDate ? moment(tillDate).format("YYYY-MM-DD") : "",
            search: searchText
        };
        const params: RedemptionLogParams = {
            offset,
            limit: pageSize
        };
        dispatch(getRedemptionLog(payload, params, afterGetRedemptionLog));
    };

    useEffect(() => {
        handleGetRedemptionLog();
    }, [offset, pageSize, searchText, tillDate, dateFrom]);

    useEffect(() => {
        if (location?.state?.data) {
            setOffset(location?.state?.data?.offset || offset);
            setPageSize(location?.state?.data?.pageSize || pageSize);
        }
    }, [location?.state?.data]);

    const renderRedemptionLogHeader = () => {
        return (
            <tr>
                <th>#</th>
                <th>TITLE</th>
                <th>COLLECTION</th>
                <th className="date-createdAt">DATE</th>
                <th className="date-createdAt">TIME</th>
                <th>WALLET ADDRESS</th>
                <th>ACTIONS</th>
            </tr>
        );
    };

    const renderRedemptionLogData = (item: any, index: number) => {
        return (
            <tr key={item.id}>
                <td className="indexnumber">#{offset + index + 1}</td>
                <td>{item?.perk?.title}</td>
                <td>{item?.collection?.name}</td>
                <td className="noWrapText">
                    <img src={Images.calendar} style={{ marginRight: "11px", verticalAlign: "sub" }} />
                    {moment(item?.createdAt).format("DD/MM/YY")}
                </td>
                <td className="noWrapText">
                    <img src={Images.clockIcon} style={{ marginRight: "11px", verticalAlign: "sub" }} />
                    {moment(item?.createdAt).format("HH:MM:SS A")}
                </td>
                <td>
                    {item?.user?.addresses?.[0]?.address ? (
                        <span className="copyicon-sec">
                            <h5>
                                {item?.user?.addresses?.[0]?.address?.substring(0, 5) +
                                    "..." +
                                    item?.user?.addresses?.[0]?.address?.substring(item?.user?.addresses?.[0]?.address?.length - 5)}
                            </h5>
                            <CopyToClipboard text={item?.user?.addresses?.[0]?.address}>
                                <CopyIcon onClick={handleCopyAddress} />
                            </CopyToClipboard>
                        </span>
                    ) : (
                        <span className="text-center"> -</span>
                    )}
                </td>
                <td className="action-icon d-flex justify-content-end log-action-icon">
                    <Tooltip title="View" placement="top">
                        <EyeIcon
                            className="eye-icon"
                            onClick={() =>
                                navigate(`/edit-perk/${item?.perk?.id}`, {
                                    state: {
                                        from: "/logs",
                                        title: "Perk details",
                                        isAllDisabled: true,
                                        data: { pageSize, offset, dateFrom, searchText, tillDate }
                                    }
                                })
                            }
                        />
                    </Tooltip>
                </td>
            </tr>
        );
    };

    return (
        <LogStyled>
            <Form>
                <Redemption>
                    <h3>Redemption log</h3>
                    <Form.Group className="searchbox">
                        <Form.Control
                            type="text"
                            placeholder="Search by perk id or title"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <span>
                            <Search />
                        </span>
                    </Form.Group>
                </Redemption>
                <DateGroup>
                    <Form.Group>
                        <Form.Control
                            type="date"
                            placeholder="Date from"
                            value={dateFrom}
                            className={`dateplaceholder ${dateFrom === "" ? "empty" : "not-empty"}`}
                            onChange={(e) => setDateFrom(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            type="date"
                            placeholder="Till today"
                            value={tillDate}
                            className={`dateplaceholder ${tillDate === "" ? "empty" : "not-empty"}`}
                            onChange={(e) => setTillDate(e.target.value)}
                        />
                    </Form.Group>
                </DateGroup>
            </Form>
            <Table
                renderHeader={renderRedemptionLogHeader}
                renderData={renderRedemptionLogData}
                data={data}
                isLoading={isLoading}
                pageSize={pageSize}
                offset={offset}
                setPageSize={setPageSize}
                setOffset={setOffset}
            />
        </LogStyled>
    );
};

export default Logs;
