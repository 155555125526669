/* eslint-disable indent */
/* eslint-disable multiline-ternary */
import { Button, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Papa from "papaparse";

import Header from "../../components/common/Header";
import { ReactComponent as CrossICon } from "../../assets/images/cross_red.svg";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import FONTS from "../../config/fonts";
import { ReactElement, RefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../types/model";
import { getPerk, readAPI, updatePerk } from "../../store/actions/dashboard";
import { Formik } from "formik";
import moment from "moment";
import Spinner from "../../components/common/Spinner";
import * as Yup from "yup";
import Images from "../../config/images";
import AlertModal from "../../components/alertModal";
import { toast } from "react-toastify";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import UploadModel from "../../components/uploadModel";
import { SECRET_CODE_TYPE } from "../createperks/types";
import CountrySelector from "../../components/countries";
import CategorySelector from "../../components/categorySelector";
import { SelectorDropDown } from "../indexStyle";

const EditPerkPage = styled.div`
    padding-bottom: 72px;

    .form-group {
        &.country-field {
            margin-bottom: 16px;
        }
    }

    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-group {
        margin-bottom: 40px;
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    .form-control:disabled {
        background: rgba(73, 65, 65, 0.4);
        /* -webkit-text-fill-color: #ffffff; */
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    .errorMessage {
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        opacity: 1;
    }

    input::-webkit-calendar-picker-indicator {
        background: url("${Images.calendar}") no-repeat;
        width: 32px;
        height: 32px;
        position: relative;
        top: 7px;
        right: -10px;
    }

    textarea.form-control {
        min-height: 124px;
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 24px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    .radio {
        :not(:last-child) {
            margin-right: 48px;
        }
        label {
            display: flex;
            align-items: center;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;

            input {
                height: unset;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    form {
        .describedata {
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.75);
        }

        .infonumber {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            .form-label {
                margin-bottom: 0;
            }

            .number {
                margin-right: 27px;
            }

            .fileUpload {
                width: 180px;
                height: 64px;
                border-radius: 50px;
                background: #332d2d;
                display: flex;
                justify-content: center;
                align-items: center;
                color: rgba(255, 255, 255, 0.5);
                position: relative;
                overflow: hidden;
                cursor: pointer;
                img {
                    opacity: 0.5;
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                }
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
                span {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: rgba(255, 255, 255, 0.5);
                }
                &.white-color {
                    background: rgba(255, 255, 255, 1);

                    span {
                        color: #221f1f;
                    }

                    svg {
                        fill: #221f1f;
                        stroke: #221f1f;

                        path {
                            fill: #221f1f;
                            stroke: #221f1f;
                            fill-opacity: 1;
                        }
                    }
                }
            }
        }
        .selectedImage {
            display: flex;
            border-radius: 15px;
            overflow: hidden;
            background: white;
            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                object-position: center;
            }
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 300px;
                color: black;
                font-size: 32px;
                font-weight: 600;
            }
        }
        .selectedImageDetail {
            display: flex;
            justify-content: space-between;
            border-radius: 15px;
            background: white;
            padding: 24px 20px;
            margin-top: 20px;
            color: black;
            span {
                cursor: pointer;
            }
        }
    }

    .searchbox {
        position: relative;
        width: 624px;
        margin-bottom: 16px;

        input {
            margin-bottom: 0;
        }

        span {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            right: 18px;

            svg {
                width: 28px;
                height: 28px;
            }
        }
    }

    .checkbox-field {
        display: flex;
        align-items: center;
        padding: 20px 24px;

        &.featured {
            padding: 0px;
        }

        p {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #ffffff;
            margin: 0 0 0 16px;
        }
    }

    .collectiontitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        span {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #d52b2b;
        }
    }

    .pt-40 {
        padding-top: 40px;
    }

    .detailHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767.98px) {
            display: initial;
        }

        button {
            width: 210px;
        }
    }
`;

const FormSection = styled.div`
    max-width: 624px;
    margin-left: 109px;

    @media (max-width: 767.98px) {
        margin: 0 auto;
    }
`;

const InfoText = styled.span`
    font-family: ${FONTS.latoFont};
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.75);
`;

const DetailHeader = styled.div`
    padding: 28px 0;
    margin-bottom: 0;
    background-color: #221f1f;

    &.sticky {
        background-color: white;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
        transition: all 0.5s ease;
        animation: smoothScroll 0.5s forwards;

        @keyframes smoothScroll {
            0% {
                transform: translateY(-142px);
            }

            100% {
                transform: translateY(0px);
            }
        }

        h1 {
            color: #221f1f;
        }
    }
`;

const ProfileCircle = styled(Link)`
    width: 56px;
    height: 56px;
    background: var(--button-bgcolor);
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 53px;

    @media (max-width: 767.98px) {
        margin-right: 16px;
    }
`;

const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;

    @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 46px;
    }
`;

const DetailInfo = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        margin-bottom: 10px;
    }
`;

const BtnFormSubmit = styled.div`
    .btn-primary {
        margin-left: 31px;
    }

    .btn-white {
        :hover {
            color: #000;
        }
    }
`;

const FormTitle = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #fbfbfb;
    margin-bottom: 32px;

    @media (max-width: 575.98px) {
        margin-top: 10px;
    }

    span {
        display: flex;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    p {
        color: #d52b2b;
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-size: 18px;
        line-height: 22px;
    }

    &.countnumber {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
`;

const DateGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    div {
        width: 297px;
    }

    input {
        display: flex;
        align-items: center;
        /* -webkit-text-fill-color: #ffffff; */
    }
`;

const InputRedeemCode = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;

    @media screen and (max-width: 580px) {
        flex-direction: column;
    }
    [type="text"] {
        width: 100%;
        @media screen and (max-width: 580px) {
            max-width: 100%;
            margin-bottom: 10px !important;
        }
    }
    button,
    button:hover,
    button:focus {
        width: 214px;
        background: white;
        color: var(--theme-primary);
        @media screen and (max-width: 767px) {
            width: 175px;
        }
    }
    button[disabled] {
        background: rgba(111, 108, 109, 0.3) !important;
        color: rgba(255, 255, 255, 0.5);

        svg {
            opacity: 0.3;
            path {
                fill: #fff;
            }
        }
    }
`;

const AddNewCode = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap;
`;
const Code = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 296px;

    .code_input {
        input {
            margin: 0 !important;
        }
        .crossIcon {
            right: 0;
            top: 0;
            height: 100%;
            width: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
`;

const UploadCSV = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        font-family: "Inter";
        font-style: italic;
        letter-spacing: 0.5px;
    }
    button,
    button:hover,
    button:focus {
        width: 235px;
        background: #fff;
        color: #000;

        svg {
            opacity: 1;
            path {
                fill: #000;
                fill-opacity: 1;
            }
        }
        [type="file"] {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
    button[disabled] {
        background: rgba(111, 108, 109, 0.3);
        color: rgba(255, 255, 255, 0.5);

        svg {
            opacity: 0.3;
            path {
                fill: #fff;
            }
        }
    }
`;

const FileUpload = styled.div`
    max-width: 640px;
    min-height: 64px;
    margin: 16px auto 0;
    background: #ffffff;
    border: 2px solid rgba(111, 108, 109, 0.3);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    input {
        display: none;
    }

    p {
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #2f2627;
        margin-bottom: 0;
    }

    .fileuploaddata {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;

        label {
            margin-right: 32px;
        }

        span {
            color: #d52b2b;
        }
    }
`;

const EditPerk = (): ReactElement => {
    const navigate = useNavigate();
    const elementRef = useRef() as RefObject<HTMLDivElement>;
    const imgInputRef = useRef() as RefObject<HTMLInputElement>;
    const fileInputRef = useRef() as RefObject<HTMLInputElement>;
    const dispatch: AppDispatch = useDispatch();
    const location: any = useLocation();
    const { id } = useParams();

    const [categories, setCategories] = useState<any[] | null>(null);
    const [collections, setCollections] = useState<any[] | null>(null);
    const [searchString, setSearchString] = useState("");
    const [initialValues, setInitialValues] = useState<any>(null);
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [csv, setCsv] = useState<any>("");
    const [csvFileDataLength, setCsvFileDataLength] = useState<number>();
    const [codeError, setCodeError] = useState("");

    useEffect(() => {
        sessionStorage.setItem("tabStatus", location?.state?.data?.type);
    }, []);

    useEffect(() => {
        dispatch(
            readAPI({ modelName: "category" }, (response: any) => {
                const finalCategories = response?.result?.filter((data: any) => data.typeId !== null);
                setCategories(finalCategories);
            })
        );
        dispatch(
            readAPI({ modelName: "collection" }, (response: any) => {
                setCollections(
                    response?.result?.filter(
                        (item: any) =>
                            (item?.status === "APPROVED" || item?.status === "SUSPENDED") &&
                            item?.isAutopilot === true &&
                            item?.deletedAt === null
                    )
                );
            })
        );
        id &&
            dispatch(
                getPerk(id, (response: any) => {
                    const newData: any = {
                        ...response,
                        codes: response?.perkCodes?.length ? response?.perkCodes.map((e: any) => e.code) : [response?.code],
                        redeemCode: response?.perkCodes?.length ? SECRET_CODE_TYPE.ENTER_MANUALLY : SECRET_CODE_TYPE.BATCH_UPLOAD,
                        categoryId: response?.category?.id,
                        collectionIds: response?.collections?.map((item: any) => item?.id),
                        startDate: response?.startDate ? moment(response?.startDate).format("yyyy-MM-DD") : null,
                        expiryDate: response?.expiryDate ? moment(response?.expiryDate).format("yyyy-MM-DD") : null,
                        country: response.country === null ? "" : response.country,
                        countryRadio: response.country === null ? "Global" : "Country specific"
                    };
                    setInitialValues(newData);
                })
            );
    }, []);

    // handle scroll event
    const handleScroll = (elTopOffset: number, elHeight: number) => {
        if (window.pageYOffset > elTopOffset + elHeight) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    // add/remove scroll event listener
    useEffect(() => {
        const handleScrollEvent = () => {
            const header: any = elementRef?.current?.getBoundingClientRect();
            handleScroll(header?.top, header?.height);
        };

        window.addEventListener("scroll", handleScrollEvent);

        return () => {
            window.removeEventListener("scroll", handleScrollEvent);
        };
    }, [elementRef]);

    const renderItem = (setFieldValue: (field: string, value: any) => void, collectionsValue: any[]) => {
        const list = searchString
            ? collections?.filter((item) => item.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1)
            : collections;
        return (
            <>
                {list?.map((item) => (
                    <Form.Group className="checkbox-field" key={item?.id}>
                        <label className={item?.status === "SUSPENDED" ? "custom_DisabledCheckbox" : "custom_checkbox"}>
                            <input
                                type="checkbox"
                                checked={collectionsValue.includes(item?.id)}
                                onChange={() => {
                                    let values = collectionsValue;
                                    if (values?.includes(item?.id)) {
                                        values = values?.filter((data) => {
                                            return data !== item?.id;
                                        });
                                    } else {
                                        values.push(item?.id);
                                    }
                                    setFieldValue("collectionIds", values);
                                }}
                                disabled={item?.status === "SUSPENDED" || location?.state?.isAllDisabled}
                            />
                            <span className="checkmark"></span>
                        </label>
                        <p className={item?.status === "SUSPENDED" ? "disabledCollection" : ""}>{item?.name}</p>
                    </Form.Group>
                ))}
            </>
        );
    };

    const getImageUrl = (img: string | File): string => {
        if (typeof img === "string") {
            return img;
        } else if (img) {
            return URL.createObjectURL(img);
        }
        return "";
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string().required("Please enter perk title"),
            otherwise: Yup.string()
        }),
        detail: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string().required("Please enter perk description").max(120, "A perk description should not exceed 120 characters"),
            otherwise: Yup.string().max(120, "A perk description should not exceed 120 characters")
        }),
        redemptionUrl: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string().required("Please enter redemption URL").url("Please enter a valid URL").nullable(),
            otherwise: Yup.string().url("Please enter a valid URL").nullable()
        }),
        domain: Yup.string().url("Please enter a valid domain").nullable(),
        total: Yup.number().when("isRequired", {
            is: true,
            then: Yup.number().required("Please enter total quantity").min(1, "Minimum value is 1").nullable(),
            otherwise: Yup.number().min(1, "Minimum value is 1").nullable()
        }),
        qtyPerPerson: Yup.number().when("isRequired", {
            is: true,
            then: Yup.number().required("Please enter quantity").min(1, "Minimum value is 1").nullable(),
            otherwise: Yup.number().min(1, "Minimum value is 1").nullable()
        }),
        startDate: Yup.date().when("isRequired", {
            is: true,
            then: Yup.date().required("Please enter start date").nullable(),
            otherwise: Yup.date().nullable()
        }),
        expiryDate: Yup.date().when("isRequired", {
            is: true,
            then: Yup.date().min(Yup.ref("startDate"), "end date can't be before start date").required("Please enter end date").nullable(),
            otherwise: Yup.date().min(Yup.ref("startDate"), "end date can't be before start date").nullable()
        }),
        codes: Yup.array().when("isRequired", {
            is: true,
            then: Yup.array()
                .required("Please enter code")
                .test("equality", "Must be equal", (value, context) => {
                    return value
                        ? value?.length === context.parent.total ||
                              (value?.length === 1 && context.parent.total === 1) ||
                              (context.parent.total > 1 && value?.length === 1)
                        : false;
                }),
            otherwise: Yup.array()
        }),
        categoryId: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string().required("Please select category").nullable(),
            otherwise: Yup.string().nullable()
        }),
        url: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string().required("Please enter website URL").url("Please enter a valid URL").nullable(),
            otherwise: Yup.string().url("Please enter a valid URL").nullable()
        }),
        offerInstruction: Yup.string().when("isRequired", {
            is: true,
            then: Yup.string()
                .required("Please enter offer instructions")
                .max(225, "offer instructions must be exactly 225 characters")
                .nullable(),
            otherwise: Yup.string().max(225, "offer instructions must be exactly 225 characters").nullable()
        }),
        collectionIds: Yup.array().when("isRequired", {
            is: true,
            then: Yup.array().required("Please select atleast one collection").min(1, "Please select atleast one collection"),
            otherwise: Yup.array()
        }),
        country: Yup.string()
            .trim()
            .when(["countryRadio"], (countryRadio: any): any => {
                if (countryRadio === "Country specific") {
                    return Yup.string().trim().required("Please select country");
                }
            })
    });

    const onSubmit = (values: any) => {
        setIsSubmitting(true);
        const formData = new FormData();
        values?.categoryId && formData.append("categoryId", values?.categoryId);
        values?.collectionIds?.forEach((id: number, index: number) => {
            formData.append(`collectionIds[${index}]`, String(id));
        });
        values?.detail && formData.append("detail", values?.detail);
        values?.startDate && formData.append("startDate", moment(values?.startDate).format("DD/MM/YY"));
        values?.expiryDate && formData.append("expiryDate", moment(values?.expiryDate).format("DD/MM/YY"));
        values?.image && formData.append("image", values?.image);
        values?.isAutopilot && formData.append("isAutopilot", String(values?.isAutopilot));
        values?.offerInstruction && formData.append("offerInstruction", values?.offerInstruction);
        values?.redemptionUrl && formData.append("redemptionUrl", values?.redemptionUrl);
        values?.domain && formData.append("domain", values?.domain);
        values?.title && formData.append("title", String(values?.title));
        values?.total && formData.append("total", String(values?.total));
        values?.qtyPerPerson && formData.append("qtyPerPerson", String(values?.qtyPerPerson));
        values?.url && formData.append("url", String(values?.url));
        formData.append("isFeatured", values?.isFeatured ? "true" : "false");
        values?.isRequired && formData.append("active", String(values?.isRequired));

        if (values?.redeemCode === SECRET_CODE_TYPE.ENTER_MANUALLY && values?.codes?.length) {
            if (Number(values?.total) === 1 && values?.codes?.length === 1) {
                values?.codes?.forEach((code: any) => {
                    formData.append("codes[0]", (code?.code ?? code) || "N/A");
                });
            } else if (Number(values?.total) > 1) {
                if (values?.codes?.length === Number(values?.total) || values?.codes?.length === 1) {
                    values?.codes?.forEach((code: any, index: number) => {
                        formData.append(`codes[${index}]`, code);
                    });
                }
            } else {
                formData.append("codes[0]", "N/A");
            }
        } else if (values?.redeemCode === SECRET_CODE_TYPE.BATCH_UPLOAD && csv) {
            formData.append("file", csv);
        }

        if (values?.countryRadio === "Country specific" && values?.country !== "") {
            formData.append("country", String(values.country));
        } else {
            formData.append("country", "global");
        }

        id &&
            dispatch(
                updatePerk(id, formData, setIsSubmitting, () => {
                    navigate(location?.state?.from, { state: location?.state });
                })
            );
    };

    const handleUploadCsv = (file: any) => {
        setCsv(file);
    };

    return (
        <>
            <Header />
            {categories && collections && initialValues ? (
                <EditPerkPage>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
                        {({ values, handleChange, setFieldValue, handleSubmit, errors, touched, handleBlur, dirty, isValid }) => {
                            const fields: any[] = [
                                { key: "categoryId", isValid: !!values?.categoryId && !errors?.categoryId },
                                { key: "title", isValid: !!values?.title && !errors?.title },
                                { key: "detail", isValid: !!values?.detail && !errors?.detail },
                                { key: "url", isValid: !!values?.url && !errors?.url },
                                { key: "startDate", isValid: !!values?.startDate && !errors?.startDate },
                                { key: "expiryDate", isValid: !!values?.expiryDate && !errors?.expiryDate },
                                { key: "redemptionUrl", isValid: !!values?.redemptionUrl && !errors?.redemptionUrl },
                                { key: "isAutopilot", isValid: !!values?.isAutopilot && !errors?.isAutopilot },
                                { key: "offerInstruction", isValid: !!values?.offerInstruction && !errors?.offerInstruction },
                                { key: "total", isValid: !!values?.total && !errors?.total },
                                { key: "collectionIds", isValid: !!values?.collectionIds && !errors?.collectionIds }
                            ];

                            useEffect(() => {
                                if (csv) {
                                    Papa.parse(csv, {
                                        header: true,
                                        skipEmptyLines: true,
                                        complete: (results) => {
                                            if (!results?.meta?.fields?.[0] && results?.data?.length === 0) {
                                                setCsvFileDataLength(undefined);
                                                setCodeError("Uploaded file is empty");
                                            } else if (results?.meta?.fields?.[0] !== "code") {
                                                setCsvFileDataLength(undefined);
                                                setCodeError("Header must be code");
                                            } else if (results?.data?.length === 0) {
                                                setCsvFileDataLength(undefined);
                                                setCodeError("No codes found.");
                                            } else if (results?.meta?.fields?.[0] === "code") {
                                                setCsvFileDataLength(results?.data?.length);
                                            }
                                        }
                                    });
                                }
                            }, [csv]);

                            return (
                                <Form onSubmit={handleSubmit}>
                                    <DetailHeader ref={elementRef} className={`${sticky.isSticky ? " sticky" : ""}`}>
                                        <Container>
                                            <div className="detailHeader">
                                                <DetailInfo>
                                                    <ProfileCircle
                                                        onClick={() => !location?.state?.isAllDisabled && setConfirmationModal(dirty)}
                                                        to={dirty ? location?.pathname : location?.state?.from}
                                                        state={location?.state}
                                                    >
                                                        <ArrowLeft />
                                                    </ProfileCircle>
                                                    <Title>{location?.state?.title || "Update perk"}</Title>
                                                </DetailInfo>
                                                <BtnFormSubmit>
                                                    {!location?.state?.isAllDisabled ? (
                                                        <>
                                                            {!initialValues?.active && (
                                                                <Button
                                                                    variant="white"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setFieldValue("isRequired", false);
                                                                    }}
                                                                >
                                                                    {isSubmitting && !confirmationModal ? <Spinner /> : "Save as draft"}
                                                                </Button>
                                                            )}
                                                            {!codeError && fields?.every((item) => item?.isValid) && (
                                                                <Button
                                                                    variant="primary"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setFieldValue("isRequired", true);
                                                                    }}
                                                                >
                                                                    {isSubmitting && !confirmationModal ? <Spinner /> : "Save and Publish"}
                                                                </Button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </BtnFormSubmit>
                                            </div>
                                        </Container>
                                    </DetailHeader>
                                    <Container>
                                        <FormSection>
                                            <FormTitle>General info</FormTitle>
                                            <Form.Group className="form-group">
                                                <Form.Label>Category</Form.Label>
                                                <SelectorDropDown>
                                                    <CategorySelector
                                                        categoryId={values.categoryId}
                                                        onChange={(value: number) => setFieldValue("categoryId", value)}
                                                    ></CategorySelector>
                                                </SelectorDropDown>
                                                {errors.categoryId && touched.categoryId && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.categoryId}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-4 d-block">
                                                <RadioGroup defaultValue="" aria-label="blockChain" name="customized-radios">
                                                    <FormControlLabel
                                                        value="Global"
                                                        control={
                                                            <Radio
                                                                checked={values.countryRadio === "Global"}
                                                                onChange={handleChange}
                                                                value="Global"
                                                                name="countryRadio"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Global"
                                                    />
                                                    <FormControlLabel
                                                        value="Country specific"
                                                        control={
                                                            <Radio
                                                                checked={values.countryRadio === "Country specific"}
                                                                onChange={handleChange}
                                                                value="Country specific"
                                                                name="countryRadio"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#d52b2b"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Country specific"
                                                    />
                                                </RadioGroup>
                                            </Form.Group>

                                            {values?.countryRadio === "Country specific" ? (
                                                <>
                                                    <CountrySelector
                                                        country={values.country}
                                                        setFieldValue={setFieldValue}
                                                    ></CountrySelector>
                                                    {errors.country && touched.country && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <img src={Images?.errorIcon} alt="" />
                                                            <span>{errors?.country as string}</span>
                                                        </div>
                                                    )}
                                                </>
                                            ) : null}

                                            {/* {values?.countryRadio === "Country specific" ? (
                                            <>
                                                <Form.Group className="form-group country-field">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values?.country}
                                                        name="country"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors?.country && touched.country && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <img src={Images.errorIcon} /> {errors.country}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <CountryBox>
                                                    <span
                                                        className={values?.country === "United State" ? "active" : ""}
                                                        onClick={() => setFieldValue("country", "United State")}
                                                    >
                                                        United State
                                                    </span>
                                                    <span
                                                        className={values?.country === "United Kingdom" ? "active" : ""}
                                                        onClick={() => setFieldValue("country", "United Kingdom")}
                                                    >
                                                        United Kingdom
                                                    </span>
                                                    <span
                                                        className={values?.country === "United Arab Emirates" ? "active" : ""}
                                                        onClick={() => setFieldValue("country", "United Arab Emirates")}
                                                    >
                                                        United Arab Emirates
                                                    </span>
                                                    <span
                                                        className={values?.country === "France" ? "active" : ""}
                                                        onClick={() => setFieldValue("country", "France")}
                                                    >
                                                        France
                                                    </span>
                                                    <span
                                                        className={values?.country === "Argentina" ? "active" : ""}
                                                        onClick={() => setFieldValue("country", "Argentina")}
                                                    >
                                                        Argentina
                                                    </span>
                                                </CountryBox>
                                            </>
                                        ) : null} */}
                                            <Form.Group className="form-group">
                                                <Form.Group className="infonumber align-items-center">
                                                    <Form.Label>
                                                        Perk image <small>(optional)</small>
                                                    </Form.Label>
                                                    <div
                                                        className={`fileUpload ${!values?.image ? "white-color" : ""}`}
                                                        onClick={() => imgInputRef?.current?.click()}
                                                    >
                                                        <Images.uploadDark />
                                                        <span className="ms-2">Upload</span>
                                                        <Form.Control
                                                            ref={imgInputRef}
                                                            className="d-none"
                                                            type="file"
                                                            id="image"
                                                            name="image"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (e?.target?.files?.[0]) {
                                                                    setFieldValue("image", e?.target?.files?.[0]);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <div className="selectedImage">
                                                    <img
                                                        className={!values?.image ? "d-none" : ""}
                                                        src={getImageUrl(values?.image)}
                                                        alt="Picture"
                                                    />
                                                </div>
                                                {values?.image ? (
                                                    <div className="selectedImageDetail">
                                                        <div>
                                                            {values?.image?.name ||
                                                                (typeof values?.image === "string" ? values?.image : "")}
                                                        </div>
                                                        <div>
                                                            <span className="replaceImage" onClick={() => imgInputRef?.current?.click()}>
                                                                Replace
                                                            </span>
                                                            <span
                                                                className="text-red ms-3"
                                                                onClick={() => {
                                                                    if (imgInputRef && imgInputRef.current) {
                                                                        imgInputRef.current.value = "";
                                                                    }
                                                                    setFieldValue("image", "");
                                                                }}
                                                            >
                                                                Remove
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Hodlperk title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={values?.title}
                                                    name="title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={location?.state?.isAllDisabled}
                                                />
                                                {errors.title && touched.title && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.title}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label className="infonumber">
                                                    <div>Detailed description</div>
                                                    <div className="number">
                                                        <InfoText>120</InfoText>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ resize: "none" }}
                                                    value={values?.detail}
                                                    name="detail"
                                                    onChange={handleChange}
                                                    disabled={location?.state?.isAllDisabled}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.detail && touched.detail && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.detail}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Website URL</Form.Label>
                                                <Form.Control
                                                    disabled={location?.state?.isAllDisabled}
                                                    type="text"
                                                    value={values?.url}
                                                    name="url"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.url && touched.url && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.url}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="checkbox-field featured">
                                                <label className="custom_checkbox">
                                                    <input
                                                        checked={values?.isFeatured}
                                                        type="checkbox"
                                                        onChange={(e) => setFieldValue("isFeatured", e.target.checked)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <p>Featured perk</p>
                                            </Form.Group>
                                            <FormTitle className="pt-40">Claim settings</FormTitle>
                                            <DateGroup>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Hodlperk start date</Form.Label>
                                                    <Form.Control
                                                        disabled={location?.state?.isAllDisabled}
                                                        type="date"
                                                        value={values?.startDate ?? ""}
                                                        name="startDate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        min={
                                                            values?.startDate
                                                                ? moment(values?.startDate).format("YYYY-MM-DD") >
                                                                  moment().format("YYYY-MM-DD")
                                                                    ? moment().format("YYYY-MM-DD")
                                                                    : moment(values?.startDate).format("YYYY-MM-DD")
                                                                : moment().format("YYYY-MM-DD")
                                                        }
                                                        placeholder="Start date"
                                                        className={`dateplaceholder ${values.startDate === "" ? "empty" : "not-empty"}`}
                                                    />
                                                    {errors.startDate && touched.startDate && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <>
                                                                <img src={Images.errorIcon} /> {errors.startDate}
                                                            </>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Hodlperk end date</Form.Label>
                                                    <Form.Control
                                                        disabled={location?.state?.isAllDisabled}
                                                        type="date"
                                                        value={values?.expiryDate ?? ""}
                                                        name="expiryDate"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        min={
                                                            values?.startDate
                                                                ? moment(values?.startDate).format("YYYY-MM-DD") >
                                                                  moment().format("YYYY-MM-DD")
                                                                    ? moment().format("YYYY-MM-DD")
                                                                    : moment(values?.startDate).format("YYYY-MM-DD")
                                                                : moment().format("YYYY-MM-DD")
                                                        }
                                                        placeholder="End date"
                                                        className={`dateplaceholder ${values.expiryDate === "" ? "empty" : "not-empty"}`}
                                                    />
                                                    {errors.expiryDate && touched.expiryDate && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <>
                                                                <img src={Images.errorIcon} /> {errors.expiryDate}
                                                            </>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Total quantity available</Form.Label>
                                                    <Form.Control
                                                        disabled={location?.state?.isAllDisabled}
                                                        type="number"
                                                        value={values?.total}
                                                        name="total"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        minLength={1}
                                                        min={1}
                                                    />
                                                    {errors.total && touched.total && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <>
                                                                <img src={Images.errorIcon} /> {errors.total}
                                                            </>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Quantity (per person)</Form.Label>
                                                    <Form.Control
                                                        disabled={location?.state?.isAllDisabled}
                                                        type="number"
                                                        value={values?.qtyPerPerson}
                                                        name="qtyPerPerson"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        minLength={1}
                                                        min={1}
                                                    />
                                                    {errors.qtyPerPerson && touched.qtyPerPerson && (
                                                        <div id="InputFeedback" className="errorMessage">
                                                            <>
                                                                <img src={Images.errorIcon} /> {errors.qtyPerPerson}
                                                            </>
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </DateGroup>
                                            <FormTitle className="pt-40">Redemption settings</FormTitle>
                                            <Form.Group className="form-group">
                                                <Form.Label className="infonumber">
                                                    <div>Offer instructions</div>
                                                    <div className="number">
                                                        <InfoText>225</InfoText>
                                                    </div>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ resize: "none" }}
                                                    value={values?.offerInstruction}
                                                    name="offerInstruction"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={location?.state?.isAllDisabled}
                                                />
                                                {errors.offerInstruction && touched.offerInstruction && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.offerInstruction}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label className="mb-0">Redeem codes</Form.Label>
                                                <RadioGroup defaultValue="" name="customized-radios" className="mt-3 radioGroup d-block">
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                onChange={() =>
                                                                    setFieldValue("redeemCode", SECRET_CODE_TYPE.ENTER_MANUALLY)
                                                                }
                                                                name="redeemCode"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#fff"
                                                                    }
                                                                }}
                                                                checked={values?.redeemCode === SECRET_CODE_TYPE.ENTER_MANUALLY}
                                                            />
                                                        }
                                                        label="Enter manually"
                                                        className="mb-3"
                                                    />
                                                    <Row className="justify-content-between px-2">
                                                        <InputRedeemCode>
                                                            <AddNewCode className="align-items-end">
                                                                {values?.codes?.map((code: string, i: number) => (
                                                                    <>
                                                                        <Code className="mb-4 me-5">
                                                                            <label className="mb-2">Code</label>
                                                                            <div className="code_input position-relative">
                                                                                <Form.Control
                                                                                    key={i}
                                                                                    type="text"
                                                                                    value={code}
                                                                                    name="codes"
                                                                                    onBlur={handleBlur}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(
                                                                                            "codes",
                                                                                            values?.codes?.map(
                                                                                                (item: string, itemIndex: number) =>
                                                                                                    itemIndex === i
                                                                                                        ? event?.target?.value
                                                                                                        : item
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                    disabled={
                                                                                        values?.redeemCode !==
                                                                                        SECRET_CODE_TYPE.ENTER_MANUALLY
                                                                                    }
                                                                                />
                                                                                {/* {values?.redeemCode === SECRET_CODE_TYPE.ENTER_MANUALLY
                                                                                && values?.codes?.length - 1 !== i && ( */}
                                                                                {values?.redeemCode === SECRET_CODE_TYPE.ENTER_MANUALLY &&
                                                                                    values?.codes?.length !== 1 && (
                                                                                        <span
                                                                                            className="crossIcon position-absolute"
                                                                                            onClick={() =>
                                                                                                setFieldValue(
                                                                                                    "codes",
                                                                                                    values?.codes?.filter(
                                                                                                        (item: string, index: number) =>
                                                                                                            index !== i
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <CrossICon />
                                                                                        </span>
                                                                                    )}
                                                                            </div>
                                                                        </Code>
                                                                    </>
                                                                ))}
                                                                <Button
                                                                    variant="white"
                                                                    disabled={values?.redeemCode !== SECRET_CODE_TYPE.ENTER_MANUALLY}
                                                                    onClick={() =>
                                                                        setFieldValue("codes", values?.codes ? [...values.codes, ""] : [""])
                                                                    }
                                                                    className={`mb-4 ${
                                                                        values?.redeemCode !== SECRET_CODE_TYPE.ENTER_MANUALLY
                                                                            ? "not_allowed"
                                                                            : "cursor_pointer"
                                                                    }`}
                                                                >
                                                                    + Add New
                                                                </Button>
                                                            </AddNewCode>
                                                        </InputRedeemCode>
                                                    </Row>
                                                </RadioGroup>
                                                <RadioGroup defaultValue="" name="customized-radios" className="mt-3 radioGroup d-block">
                                                    <FormControlLabel
                                                        control={
                                                            <Radio
                                                                checked={values?.redeemCode === SECRET_CODE_TYPE.BATCH_UPLOAD}
                                                                onChange={() => setFieldValue("redeemCode", SECRET_CODE_TYPE.BATCH_UPLOAD)}
                                                                name="redeemCode"
                                                                sx={{
                                                                    color: "#d52b2b",
                                                                    "&.Mui-checked": {
                                                                        color: "#fff"
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Batch upload"
                                                    />
                                                    <Row className="justify-content-between">
                                                        <UploadCSV>
                                                            <span>
                                                                {csvFileDataLength}{" "}
                                                                {csvFileDataLength === 0 || csvFileDataLength === undefined
                                                                    ? "0 codes uploaded"
                                                                    : csvFileDataLength && csvFileDataLength > 1
                                                                    ? "codes uploaded"
                                                                    : "code uploaded"}
                                                            </span>

                                                            <Button
                                                                onClick={() => setShowUploadModal(true)}
                                                                disabled={values?.redeemCode !== SECRET_CODE_TYPE.BATCH_UPLOAD}
                                                                className={`position-relative ${
                                                                    values?.redeemCode !== SECRET_CODE_TYPE.BATCH_UPLOAD
                                                                        ? "not_allowed"
                                                                        : "cursor_pointer"
                                                                }`}
                                                            >
                                                                <Images.uploadDark />{" "}
                                                                {values?.redeemCode !== SECRET_CODE_TYPE.BATCH_UPLOAD
                                                                    ? "Add code"
                                                                    : "Upload CSV"}
                                                            </Button>
                                                        </UploadCSV>
                                                    </Row>
                                                    {csv ? (
                                                        <>
                                                            <FileUpload className="w-100">
                                                                <p>{csv?.name}</p>
                                                                <div className="fileuploaddata">
                                                                    <label onClick={() => setShowUploadModal(true)}>Replace</label>
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setCsv("");
                                                                            setCodeError("");
                                                                            setCsvFileDataLength(undefined);
                                                                            if (fileInputRef.current) fileInputRef.current.value = "";
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </span>
                                                                </div>
                                                            </FileUpload>
                                                        </>
                                                    ) : null}
                                                </RadioGroup>
                                                {errors.codes && touched.codes ? (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.codes}
                                                        </>
                                                    </div>
                                                ) : codeError ? (
                                                    <div className="errorMessage">
                                                        <img src={Images.errorIcon} /> {codeError}
                                                    </div>
                                                ) : null}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Redemption URL</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={values?.redemptionUrl}
                                                    name="redemptionUrl"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={location?.state?.isAllDisabled}
                                                />
                                                {errors.redemptionUrl && touched.redemptionUrl && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.redemptionUrl}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>
                                                    Domain <small className="opac-7">(optional)</small>
                                                </Form.Label>
                                                <p className="opac-7">
                                                    This should match the domain where this perk can be claimed using Hodlperks extension
                                                </p>
                                                <Form.Control
                                                    type="text"
                                                    value={values?.domain}
                                                    name="domain"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={location?.state?.isAllDisabled}
                                                />
                                                {errors.domain && touched.domain && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <>
                                                            <img src={Images.errorIcon} /> {errors.domain}
                                                        </>
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <div className="collectiontitle pt-40">
                                                <FormTitle className="countnumber">
                                                    Apply to collections{" "}
                                                    <>
                                                        <sub className="ms-2">
                                                            <span>
                                                                (<p>{collections?.length}</p>)
                                                            </span>
                                                        </sub>
                                                    </>
                                                </FormTitle>
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            "collectionIds",
                                                            collections
                                                                ?.filter((item) => item.status !== "SUSPENDED")
                                                                ?.map((item) => item?.id)
                                                        )
                                                    }
                                                >
                                                    Select all
                                                </span>
                                            </div>
                                            <Form.Group className="searchbox">
                                                <Form.Control
                                                    disabled={location?.state?.isAllDisabled}
                                                    type="text"
                                                    placeholder="Search collection"
                                                    onChange={(e) => setSearchString(e?.target?.value)}
                                                />
                                                <span>
                                                    <Search />
                                                </span>
                                            </Form.Group>
                                            {touched?.collectionIds && errors?.collectionIds ? (
                                                <div id="InputFeedback" className="errorMessage">
                                                    <>
                                                        <img src={Images.errorIcon} /> {errors.collectionIds}
                                                    </>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {renderItem(setFieldValue, values?.collectionIds)}
                                        </FormSection>
                                    </Container>
                                    <AlertModal
                                        string={"Save changes?"}
                                        yesButton={isSubmitting ? <Spinner /> : "Save"}
                                        closeButton="Cancel"
                                        open={confirmationModal}
                                        setOpen={() => setConfirmationModal(false)}
                                        image={Images.saveIcon}
                                        handleRemove={() => {
                                            if (isValid) {
                                                handleSubmit();
                                            } else {
                                                toast.error("Please enter valid data");
                                                setConfirmationModal(false);
                                            }
                                        }}
                                        onCancelButtonClick={() => navigate(location?.state?.from, { state: location?.state })}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                </EditPerkPage>
            ) : (
                <Spinner />
            )}

            <UploadModel
                show={showUploadModal}
                setShow={setShowUploadModal}
                handleUploadCsv={handleUploadCsv}
                csv={csv}
                setCsv={setCsv}
                acceptFormat=".csv"
                showFileControls={false}
            />
        </>
    );
};

export default EditPerk;
