export const FetchUserDataStart = "fetch_user_start";
export const FetchUserDataEnd = "fetch_user_end";
export const FetchUserDataError = "fetch_user_error";

export const LOGIN = "LOGIN";

export const GetProfileStart = "get_profile_start";
export const GetProfileEnd = "get_profile_end";

export const UpdateProfile = "update_profile";

export const BrandListStart = "brand_list_start";
export const BrandListEnd = "brand_list_end";

export const UpdateBrand = "update_brand";

export const BrandDelete = "brand_delete";

export const BrandCollectionStart = "brand_collection_start";
export const BrandCollection = "brand_collection";

export const BrandEnableDisable = "brand_enable_disable";

export const MultipleBrandDelete = "multiple_brand_delete";

export const CreateBrand = "create_brand";

export const BrandDetailStart = "brand_detail_start";
export const BrandDetail = "brand_detail";

export const UploadLogo = "upload_logo";
export const ExportCSV = "exportCSV";

export const ShowToast = "Show_Toast";
export const HideToast = "Hide_Toast";
export const UploadCSV = "upload_csv";
export const CategoryList = "Category_List";
export const CategoryDelete = "Category_Delete";

// rules
export const FetchRulesStart = "fetch_rules_start";
export const FetchRulesEnd = "fetch_rules_end";
export const FetchPreferredRulesStart = "fetch_preferred_rules_start";
export const FetchPreferredRulesEnd = "fetch_preferred_rules_end";
export const RulesEnableDisable = "rules_enable_disable";
export const PreferredRulesEnableDisable = "preferred_rules_enable_disable";

export const UpdateCollection = "update_collection";
export const CollectionDetailStart = "Collection_Detail_Start";
export const CollectionDetail = "Collection_Detail";
export const CollectionListStart = "Collection_List_Start";
export const CollectionListEnd = "Collection_List_End";

export type PayloadAction = {
    type:
        | typeof FetchUserDataStart
        | typeof FetchUserDataEnd
        | typeof FetchUserDataError
        | typeof GetProfileStart
        | typeof GetProfileEnd
        | typeof UpdateProfile
        | typeof LOGIN
        | typeof BrandListStart
        | typeof BrandListEnd
        | typeof UpdateBrand
        | typeof BrandDelete
        | typeof BrandCollectionStart
        | typeof BrandCollection
        | typeof BrandEnableDisable
        | typeof MultipleBrandDelete
        | typeof CreateBrand
        | typeof BrandDetail
        | typeof BrandDetailStart
        | typeof UploadLogo
        | typeof CollectionDetailStart
        | typeof CollectionDetail
        | typeof CollectionListStart
        | typeof CollectionListEnd
        | typeof ExportCSV
        | typeof ShowToast
        | typeof HideToast
        | typeof UploadCSV
        | typeof UpdateCollection
        | typeof CategoryList
        | typeof CategoryDelete
        | typeof FetchRulesStart
        | typeof FetchRulesEnd
        | typeof PreferredRulesEnableDisable
        | typeof RulesEnableDisable
        | typeof FetchPreferredRulesStart
        | typeof FetchPreferredRulesEnd;
    payload: Record<string, any>;
};

// Users
export const UserList = "User_List";
export const UserDelete = "User_Delete";
export const EnableDisable = "Enable_Disable";
export const ApprovedUnapproved = "Approved_Unapproved";
export const MailSent = "MailSent";

// Collections
export const CollectionEnableDisable = "Collection_Enable_Disable";

export const UploadCollectionLogo = "upload_collection_logo";
export const UpdateAccountStatus = "update_account_status";
export type UserPayloadAction = {
    type:
        | typeof UserList
        | typeof UserDelete
        | typeof EnableDisable
        | typeof MailSent
        | typeof ApprovedUnapproved
        | typeof UploadCSV
        | typeof CollectionEnableDisable
        | typeof UploadCollectionLogo
        | typeof UpdateAccountStatus;
    payload: Record<string, any>;
};
