export enum SECRET_CODE_TYPE {
    ENTER_MANUALLY = "manually",
    BATCH_UPLOAD = "batch"
}

export interface PapaParseCode {
    code: string;
}
export interface createPerkPayload {
    image: "" | File;
    title: string;
    detail: string;
    redemptionUrl: string;
    total: number;
    // qtyPerPerson: number;
    startDate: string;
    expiryDate: string;
    categoryId: any;
    url: string;
    code: string;
    offerInstruction: string;
    collectionIds: any[];
    isAutopilot: number;
    isFeatured: boolean;
}

export interface CreatePerkInitialValues {
    image: "" | File;
    title: string;
    detail: string;
    redemptionUrl: string | null;
    total: number;
    qtyPerPerson: number;
    startDate: string | null;
    expiryDate: string | null;
    categoryId: string;
    url: string | null;
    code: string | null;
    offerInstruction: string;
    collectionIds: any[];
    isAutopilot: number;
    isFeatured: boolean;
    active: boolean;
    isRequired: boolean;
    redeemCode: string;
    codes: string[];
    batchUploadFile: File | null;
    countryRadio: string;
    country: string;
    domain: string | null;
}
