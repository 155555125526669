import { PayloadAction, ShowToast, HideToast } from "./types";

export type Severity = "error" | "warning" | "info" | "success";

export enum Severities {
    ERROR = "error",
    WARNING = "warning",
    INFO = "info",
    SUCCESS = "success"
}

interface InitialValues {
    open: boolean;
    text: string;
    severity: Severity;
}

const initialValues: InitialValues = {
    open: false,
    text: "",
    severity: "success"
};

const ToastReducer = (state = initialValues, action: PayloadAction): InitialValues => {
    switch (action.type) {
        case ShowToast:
            return {
                ...state,
                open: true,
                text: action.payload.text,
                severity: action.payload.severity ?? "success"
            };

        case HideToast:
            return {
                ...state,
                open: false
            };

        default:
            return state;
    }
};

export default ToastReducer;
