import { ReactElement } from "react";
import { Container, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as Hodlperks } from "../../../assets/images/hodlperks.svg";
import { ReactComponent as Admin } from "../../../assets/images/admin.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Images from "../../../config/images";
import FONTS from "../../../config/fonts";

const HeaderSection = styled.div`
    margin: 40px 0;
`;

const ProfileRound = styled.div`
    width: 56px;
    height: 56px;
    background-color: var(--button-bgcolor);
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;

const TitleSec = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 575.98px) {
        display: initial;
    }

    h3 {
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #ffffff;
    }

    .seprator {
        width: 2px;
        height: 40px;
        background: rgba(255, 255, 255, 0.5);

        @media (max-width: 575.98px) {
            display: none;
        }
    }

    svg {
        width: 208px;
        height: auto;
    }
`;

const ProfileSec = styled.div`
    display: flex;
    align-items: center;

    h4 {
        margin-bottom: 0;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
    }
`;

const MainProfile = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TopbarSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #000;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 105px;
    .row {
        flex-direction: row;
        & > div {
            width: auto;
            flex: 0 0 auto;
            align-items: center;
        }
    }
    .selectedItem {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: #2f2627;
        img {
            margin-right: 34px;
        }
    }
    .brandsAction {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;
        a {
            padding: 5px 0;
            margin: 0 23px;
            color: #000;
            padding-right: 23px;
            border-right: 1px solid rgba(111, 108, 109, 0.3);
        }
        span {
            padding: 5px 0;
            margin: 0 23px;
            margin: 0;
        }
    }
`;
interface BrandsAction {
    selectedBrand: number;
    classname: string;
    handleDeleteMultipleBrand: () => void;
    setSelectedBrand: any;
    handleExportCSV?: () => void;
    exportCSVlink?: string;
    style?: React.CSSProperties;
    module?: string;
    handleEmail?: any;
    preferredSetSelectedItems?: any;
    bulkDelete?: boolean;
    exportCSV?: boolean;
    bulkEmail?: boolean;
}

const Header = (): ReactElement => {
    const { userInfo } = useSelector((state: any) => state?.auth);
    return (
        <HeaderSection>
            <Container>
                <MainProfile>
                    <TitleSec>
                        <Hodlperks />
                        <span className="seprator" />
                        <h3>Super admin</h3>
                    </TitleSec>
                    <Link to={"/editprofile"} title="Edit Profile">
                        <ProfileSec>
                            <ProfileRound>
                                <Admin />
                            </ProfileRound>
                            <h4>{userInfo?.user?.username}</h4>
                        </ProfileSec>
                    </Link>
                </MainProfile>
            </Container>
        </HeaderSection>
    );
};

export const Topbar = (props: BrandsAction): ReactElement => {
    const {
        selectedBrand,
        classname,
        handleDeleteMultipleBrand,
        setSelectedBrand,
        handleExportCSV,
        exportCSVlink,
        handleEmail,
        preferredSetSelectedItems,
        bulkDelete,
        exportCSV,
        bulkEmail
    } = props;
    const handleCancleTopbar = () => {
        setSelectedBrand([]);
        preferredSetSelectedItems([]);
    };
    return (
        <>
            <TopbarSection className={`topbarSection ${classname || ""}`} style={props.style}>
                <Container>
                    <Row className="mx-0 justify-content-between">
                        <div className="selectedItem d-flex align-items-center">
                            <Image src={Images?.crossIcon} onClick={handleCancleTopbar} className="cursor_pointer" />
                            {selectedBrand} Selected
                        </div>
                        <div className="brandsAction d-flex align-items-center">
                            {exportCSV && (
                                <a href={exportCSVlink} className="d-flex align-items-center cursor_pointer" onClick={handleExportCSV}>
                                    <Image src={Images?.downloadIcon} alt="Export to CSV" height="26" className="me-2" /> Export to CSV
                                </a>
                            )}
                            {bulkDelete && (
                                <span className="d-flex align-items-center cursor_pointer" onClick={() => handleDeleteMultipleBrand()}>
                                    <Image src={Images?.deleteIcon} alt="Remove" height="26" className="me-2" /> Remove
                                </span>
                            )}
                            {bulkEmail && (
                                <span className="d-flex align-items-center cursor_pointer" onClick={() => handleEmail()}>
                                    <Image src={Images?.emailIcon} alt="Remove" height="26" className="me-2" /> Send email
                                </span>
                            )}
                        </div>
                    </Row>
                </Container>
            </TopbarSection>
        </>
    );
};

export default Header;
