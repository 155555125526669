import { ReactElement, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { ReactComponent as Csvfile } from "../assets/images/csvfile.svg";
import Cross from "../assets/images/cross.svg";
import styled from "styled-components";
import { FileDrop } from "react-file-drop";
import { handleSnackToast } from "./common/Snackbar";
import { Severities } from "../store/reducers/toast";
import FONTS from "../config/fonts";

const ModelData = styled(Modal)`
    z-index: 9999;

    .modal-body,
    .modal-header,
    .modal-footer {
        padding: 0;
    }

    .modal-header,
    .modal-footer {
        border: none;
        border-radius: 0;
    }

    .modal-content {
        background: #2f2f2f;
        border-radius: 16px;
        height: 100%;
    }

    .modal-dialog {
        max-width: 720px;
        min-height: 536px;
    }

    .image-upload {
        text-align: center;
        position: relative;
        cursor: pointer;

        .file-drop {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &:hover label {
            background: #221f1f;
            border: 3px dashed #d52b2b;
        }
    }

    .image-upload > label {
        width: 640px;
        height: 256px;
        background: rgba(47, 47, 47, 0.4);
        border: 3px dashed rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        padding: 48px;
        cursor: pointer;

        @media (max-width: 767.98px) {
            width: 100%;
        }

        h4 {
            margin-top: 24px;
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            color: #d52b2b;
        }

        p {
            margin-top: 16px;
            margin-bottom: 0;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .modal-footer {
        justify-content: center;
        padding: 32px 0 40px 0;

        button {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.5) !important;
            width: 158px;
            height: 64px;
            background-color: rgba(111, 108, 109, 0.3) !important;
            border-radius: 48px;
            border-color: transparent !important;

            &.upload-csv {
                background-color: #d52b2b !important;
                color: rgba(255, 255, 255, 1) !important;
            }
        }

        &:hover,
        &:focus,
        &:active:focus {
            background-color: transparent;
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }

        .btn-primary:focus {
            box-shadow: none;
        }
    }

    .modal-header {
        display: unset;
        position: relative;

        .btn-close {
            background-image: url("${Cross}");
            opacity: 1;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 28px;
            right: 28px;

            &:hover,
            &:focus,
            &:active:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }

    .modal-title {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        color: #ffffff;
        margin: 56px 0 40px 0;
        text-align: center;
    }

    input[type="file"]::-webkit-file-upload-button {
        visibility: hidden;
    }
`;

const FileUpload = styled.div`
    max-width: 640px;
    min-height: 64px;
    margin: 16px auto 0;
    background: #ffffff;
    border: 2px solid rgba(111, 108, 109, 0.3);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    input {
        display: none;
    }

    p {
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #2f2627;
        margin-bottom: 0;
    }

    .fileuploaddata {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;

        label {
            margin-right: 32px;
        }

        span {
            color: #d52b2b;
        }
    }
`;

interface IProps {
    show: boolean;
    setShow: any;
    uploadCsv?: any;
    handleUploadCsv?: any;
    csv?: any;
    setCsv?: any;
    setCsvFile?: any;
    acceptFormat?: any;
    showFileControls?: boolean;
}

const UploadModel = (props: IProps): ReactElement => {
    const { show, setShow, uploadCsv, handleUploadCsv, csv, setCsv, setCsvFile, acceptFormat, showFileControls = true } = props;

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClose = () => setShow(false);

    const handleUploadvalidation = (file: any) => {
        if (!["text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(file.type)) {
            handleSnackToast("File type should be CSV or XLSX", Severities.ERROR);
            return;
        }
        handleUploadCsv(file);
    };

    return (
        <ModelData show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    style={{ display: "none" }}
                    id="file-input"
                    type="file"
                    accept={acceptFormat || ".xlsx"}
                    capture={true}
                    onChange={(e): any => {
                        handleUploadvalidation(e?.target?.files?.[0]);
                    }}
                    ref={fileInputRef}
                />
                <div className="image-upload" onClick={() => fileInputRef.current?.click()}>
                    <FileDrop
                        onDrop={(files: any) => {
                            handleUploadvalidation(files?.[0]);
                        }}
                    >
                        <></>
                    </FileDrop>
                    <label htmlFor="file-input">
                        <Csvfile />
                        <h4>Select a CSV file to upload</h4>
                        <p>or drag and drop it here</p>
                    </label>
                </div>
                {showFileControls ? (
                    csv ? (
                        <>
                            <FileUpload>
                                <p>{csv}</p>
                                <div className="fileuploaddata">
                                    <label htmlFor="file-input">Replace</label>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setCsv("");
                                            setCsvFile("");
                                            if (fileInputRef.current) fileInputRef.current.value = "";
                                        }}
                                    >
                                        Remove
                                    </span>
                                </div>
                            </FileUpload>
                        </>
                    ) : null
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className={csv ? "upload-csv" : ""}
                    disabled={!csv}
                    variant="primary"
                    onClick={() => (showFileControls ? uploadCsv() : handleClose())}
                >
                    Upload
                </Button>
            </Modal.Footer>
        </ModelData>
    );
};

export default UploadModel;
