import ErrorIcon from "../assets/images/errorIcon.svg";
import EyeIcon from "../assets/images/eyeIcon.svg";
import DeleteIcon from "../assets/images/delete.svg";
import RemoveIcon1 from "../assets/images/removeIcon.svg";
import EditIcon from "../assets/images/edit.svg";
import RemoveIcon from "../assets/images/remove.svg";
import UpArrow from "../assets/images/upArrow.svg";
import CrossIcon from "../assets/images/crossIcon.svg";
import DownloadIcon from "../assets/images/downloadIcon.png";
import Calendar from "../assets/images/calendar.svg";
import Upload from "../assets/images/upload.png";
import PasswordHide from "../assets/images/passwordHide.png";
import PasswordShow from "../assets/images/passwordShow.png";
import CheckMark from "../assets/images/checkmark.svg";
import CopyIcon from "../assets/images/copyicon.svg";
import ApprovedIcon from "../assets/images/approvedIcon.png";
import RejectedIcon from "../assets/images/rejectedIcon.png";
import EmailIcon from "../assets/images/emailIcon.svg";
import PlaceholderThumb from "../assets/images/placeholder_thumb.png";
import EmailIconRounded from "../assets/images/emailIconRounded.svg";

import { ReactComponent as ActiveAutopilot } from "../assets/images/activeAutopilot.svg";
import { ReactComponent as DisableAutopilot } from "../assets/images/disableAutopilot.svg";

import { ReactComponent as CheckAutopilotIcon } from "../assets/images/check_icon.svg";
import { ReactComponent as RejectAutopilotIcon } from "../assets/images/reject_icon.svg";
import { ReactComponent as SuspendAutopilotIcon } from "../assets/images/suspend_icon.svg";
import { ReactComponent as UploadDark } from "../assets/images/upload-dark.svg";

import ClockIcon from "../assets/images/clock.svg";
import SaveIcon from "../assets/images/save.svg";
import Decline from "../assets/images/decline.svg";
import SelectSearch from "../assets/images/select-search-arrow-up.svg";

const Images = {
    errorIcon: ErrorIcon,
    eyeIcon: EyeIcon,
    deleteIcon: DeleteIcon,
    removeIcon: RemoveIcon,
    editIcon: EditIcon,
    upArrow: UpArrow,
    removeIcon1: RemoveIcon1,
    crossIcon: CrossIcon,
    downloadIcon: DownloadIcon,
    calendar: Calendar,
    upload: Upload,
    passwordHide: PasswordHide,
    passwordShow: PasswordShow,
    checkmark: CheckMark,
    copyIcon: CopyIcon,
    approvedIcon: ApprovedIcon,
    rejectedIcon: RejectedIcon,
    emailIcon: EmailIcon,
    placeholderThumb: PlaceholderThumb,
    emailIconRounded: EmailIconRounded,
    disableAutopilot: DisableAutopilot,
    activeAutopilot: ActiveAutopilot,
    checkAutopilotIcon: CheckAutopilotIcon,
    rejectAutopilotIcon: RejectAutopilotIcon,
    suspendAutopilotIcon: SuspendAutopilotIcon,
    clockIcon: ClockIcon,
    saveIcon: SaveIcon,
    uploadDark: UploadDark,
    decline: Decline,
    selectSearch: SelectSearch
};

export default Images;
