import { useCallback } from "react";
import SelectSearch from "react-select-search";
import { SelectorDropDown } from "../../pages/indexStyle";
import { countriesList } from "./countriesList";
import { Country } from "./types";

const CountrySelector = (props: any): JSX.Element => {
    const handleFilter = useCallback((items: Country[]) => {
        return (searchValue: string) => {
            if (searchValue.length === 0) {
                return items;
            }

            const filteredItems = items.filter((item: Country) => {
                return (
                    item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    (typeof item.value === "string" && item.value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                );
            });
            return filteredItems;
        };
    }, []);

    return (
        <SelectorDropDown>
            <SelectSearch
                value={props.country}
                filterOptions={handleFilter as any}
                className="select-search custom-select-search"
                options={countriesList}
                search={true}
                placeholder="Start Typing"
                onChange={(v: any) => {
                    props.setFieldValue("country", v);
                }}
                emptyMessage="No country found"
            />
        </SelectorDropDown>
    );
};

export default CountrySelector;
