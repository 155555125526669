import { Button, Container, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "../../components/common/Header";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import Images from "../../config/images";
import { AppDispatch } from "../../types/model";
import { useDispatch } from "react-redux";
import { ReactElement, useEffect, useState } from "react";
import FONTS from "../../config/fonts";
import string from "../../utils/string";
import Spinner from "../../components/common/Spinner";
import { getCategory, updateCategory } from "../../store/actions/categories";

import {} from "./../indexStyle";

const DetailPage = styled.div`
    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;

        & span.astrik {
            color: #d52b2b;
            font-weight: 600;
            font-size: 18px;
        }
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        margin-bottom: 32px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    input[type="number"]::-webkit-inner-spin-button {
        opacity: 1;
    }

    input::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    textarea.form-control {
        min-height: 124px;
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 24px;
        margin-bottom: 32px;
        color: #ffffff;
    }

    .radio {
        :not(:last-child) {
            margin-right: 48px;
        }
        label {
            display: flex;
            align-items: center;
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #ffffff;

            input {
                height: unset;
                margin-bottom: 0;
                margin-right: 16px;
            }
        }
    }

    form {
        max-width: 624px;

        .describedata {
            font-family: ${FONTS.latoFont};
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.75);
        }

        .infonumber {
            display: flex;
            justify-content: space-between;

            .number {
                margin-right: 27px;
            }
        }
    }

    .errorMessage {
        position: relative;
        top: -25px;
    }
`;

const DetailHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 51px;

    button {
        width: 158px;
    }
`;

const ProfileCircle = styled.div`
    a {
        width: 56px;
        height: 56px;
        background: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 53px;
    }
`;

const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;
`;

const DetailInfo = styled.div`
    display: flex;
`;

const ProfileCont = styled.div`
    position: relative;
    margin-bottom: 30px;
`;

const ProfileAdd = styled.div`
    display: flex;
    justify-content: center;
`;

const ImgCont = styled.label`
    background-color: rgba(47, 47, 47, 0.4);
    border-radius: 50%;
    width: 172px;
    height: 172px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 30px;
    border: 3px dashed rgba(255, 255, 255, 0.2);
    z-index: 9;
    cursor: pointer;
    & input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        overflow: hidden;
        z-index: 1;
        margin: 0;
        border-radius: 100%;
    }
    @media only screen and (max-width: 576px) {
        width: 132px;
        height: 132px;
        & .plus {
            width: 32px;
            height: 32px;
        }
    }
    & .plus {
        height: 32px;
        width: 32px;
        margin-bottom: 5px;
    }
    & .logo {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }
    & p {
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #d52b2b;
        width: 100%;
        text-align: center;
    }
`;

const EditFormData = styled.div`
    margin-left: 109px;
`;

const values = {
    name: "",
    image: ""
};

const EditCategory = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoader, setIsLoader] = useState(true);
    const [imageData, setImageData] = useState<any>(null);
    const [initialValues, setInitialValues] = useState<Record<string, string>>(values);

    const ValidationSchema = Yup.object().shape({
        name: Yup.string().trim().required(string.nameErrorMessage),
        image: Yup.string().required(string.imageErrorMessage)
    });

    useEffect(() => {
        if (id) {
            dispatch(
                getCategory(id, (res: any) => {
                    setIsLoader(false);
                    setImageData(res?.image);
                    setInitialValues(res);
                })
            );
        }
    }, []);

    const onSubmit = (values: any) => {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("logo", values.image);

        dispatch(
            updateCategory(
                formData,
                () => {
                    navigate("/categories");
                },
                id
            )
        );
    };

    const handleLogoChange = async (e: any, setFieldValue: any, type: string) => {
        const [file] = e.target.files;
        setImageData(URL.createObjectURL(file));
        setFieldValue(type, e?.target?.files?.[0]);
    };

    return (
        <>
            <Header />
            {!isLoader ? (
                <DetailPage>
                    <Container>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={ValidationSchema}
                            onSubmit={(values) => {
                                onSubmit(values);
                            }}
                            enableReinitialize
                        >
                            {({ values, touched, handleSubmit, errors, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <DetailHeader>
                                            <DetailInfo>
                                                <ProfileCircle>
                                                    <Link to={"/categories"}>
                                                        <ArrowLeft />
                                                    </Link>
                                                </ProfileCircle>
                                                <Title>Category details</Title>
                                            </DetailInfo>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? <Spinner /> : "Submit"}
                                            </Button>
                                        </DetailHeader>
                                        <EditFormData>
                                            <ProfileCont>
                                                <ProfileAdd>
                                                    <ImgCont htmlFor="logo">
                                                        <input
                                                            id="image"
                                                            type="file"
                                                            accept="image/jpg,image/png,image/jpeg"
                                                            capture={true}
                                                            onChange={(e): any => handleLogoChange(e, setFieldValue, "image")}
                                                        />
                                                        {imageData ? (
                                                            <img src={imageData} alt="" className="logo image_object_cover" />
                                                        ) : (
                                                            <p>+ Add logo</p>
                                                        )}
                                                    </ImgCont>
                                                </ProfileAdd>
                                                {errors.image && touched.image && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.image}
                                                    </div>
                                                )}
                                            </ProfileCont>

                                            <Form.Group>
                                                <Form.Label>
                                                    Name <span className="astrik">*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete={"off"}
                                                    placeholder=""
                                                    value={values.name}
                                                />
                                                {errors.name && touched.name && (
                                                    <div id="InputFeedback" className="errorMessage">
                                                        <img src={Images.errorIcon} /> {errors.name}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </EditFormData>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    </Container>
                </DetailPage>
            ) : null}
        </>
    );
};

export default EditCategory;
