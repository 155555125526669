import { BulkDeletePayload, CreateRuleObj, DeletePayload } from "../types/model";
import apiCall, { Callback, Fail } from "./apiUtils/ApiCall";
import config from "./Config";

export interface userRuleUpdatePayload {
    title: string;
    time: number;
    amount: number;
    collectionId?: number;
}

const rule = {
    list(callback: Callback, fail: Fail, params: Record<string, any>): void {
        apiCall.makeGetRequest(config.rule.list, callback, fail, params);
    },
    delete(callback: Callback, fail: Fail, payload: DeletePayload): void {
        apiCall.makeDeleteRequest(config.rule.delete, callback, fail, payload);
    },
    statusToggle(callback: Callback, fail: Fail, id: string): void {
        const url = `${config.rule.statusToggle}/${id}`;
        apiCall.makePostRequest(url, callback, fail, {}, {});
    },
    bulkDelete(callback: Callback, fail: Fail, payload: BulkDeletePayload): void {
        apiCall.makePostRequest(config.rule.bulkDelete, callback, fail, payload, {});
    },
    add(callback: Callback, fail: Fail, payload: CreateRuleObj): void {
        apiCall.makePostRequest(config.rule.store, callback, fail, payload, {});
    },
    update(callback: Callback, fail: Fail, payload: userRuleUpdatePayload, id?: string): void {
        const URL = `${config.rule.update}${id}`;
        apiCall.makePostRequest(URL, callback, fail, payload, {});
    },
    detail(callback: Callback, fail: Fail, id: string): void {
        apiCall.makeGetRequest(`${config.rule.details}${id}`, callback, fail, {});
    }
};

export default rule;
