import { BulkDeletePayload, DeletePayload, EmailPayload, UpdateProviderApproved, UpdateStatus } from "../types/model";
import apiCall, { Callback, Fail } from "./apiUtils/ApiCall";
import config from "./Config";

const user = {
    get(
        callback: Callback,
        fail: Fail,
        offset?: number,
        limit?: number,
        order?: string,
        type?: string,
        field?: string,
        search?: string
    ): void {
        const params: any = {
            offset,
            limit,
            order,
            type,
            field,
            search
        };
        if (field) {
            params.field = field;
        }
        apiCall.makeGetRequest(config.user.list, callback, fail, params);
    },
    delete(callback: Callback, fail: Fail, payload: DeletePayload): void {
        apiCall.makeDeleteRequest(config.user.delete, callback, fail, payload);
    },
    bulkDelete(callback: Callback, fail: Fail, payload: BulkDeletePayload): void {
        apiCall.makePostRequest(config.user.bulkDelete, callback, fail, payload, {});
    },
    enableDisable(callback: Callback, fail: Fail, payload: UpdateStatus): void {
        apiCall.makePutRequest(config.user.enableDisable, callback, fail, payload, {});
    },
    approvedUnapproved(callback: Callback, fail: Fail, payload: UpdateProviderApproved): void {
        apiCall.makePutRequest(config.user.approvedUnapproved, callback, fail, payload, {});
    },
    sendEmail(callback: Callback, fail: Fail, payload: EmailPayload): void {
        apiCall.makePostRequest(config.user.email, callback, fail, payload, {});
    }
};

export default user;
