import { useEffect, useState, useCallback } from "react";
import SelectSearch from "react-select-search";
import { Category } from "./types";
import { AppDispatch } from "../../types/model";
import { useDispatch } from "react-redux";
import { readAPI } from "../../store/actions/dashboard";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CategorySelector = (props: any): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();
    const [categories, setCategories] = useState<any[]>([]);

    const handleFilter = useCallback((items: Category[]) => {
        return (searchValue: string) => {
            if (searchValue.length === 0) {
                return items;
            }

            const filteredItems = items.filter((item: Category) => {
                return (
                    item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
                    (typeof item.value === "string" && item.value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                );
            });
            return filteredItems;
        };
    }, []);

    useEffect(() => {
        dispatch(
            readAPI({ modelName: "category" }, (response: any) => {
                const arr: any[] = [];
                response?.result?.map((value: any) => {
                    if (value.typeId != null) {
                        arr.push({ name: value.name, value: value.id });
                    }
                    return value;
                });
                setCategories(arr);
            })
        );
    }, []);

    return (
        <SelectSearch
            value={props.categoryId}
            filterOptions={handleFilter as any}
            className="select-search custom-select-search"
            options={categories}
            search={false}
            placeholder="Select Category"
            onChange={(v: any) => {
                props?.onChange && props?.onChange(v);
                props?.setFieldValue && props?.setFieldValue(v);
            }}
            emptyMessage="No category found"
        />
    );
};

export default CategorySelector;
