import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import authApi, { editProfilePayload, LoginPayload } from "../../../api/AuthApi";
import { handleSnackToast } from "../../../components/common/Snackbar";
import { AppDispatch } from "../../../types/model";
import { Severities } from "../../reducers/toast";
import { LOGIN, GetProfileStart, GetProfileEnd, UpdateProfile } from "../../reducers/types";

type Callback = (...args: unknown[]) => unknown;

export const login = (values: LoginPayload) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                localStorage.setItem("auth_token", response?.data?.data?.jwt);
                dispatch({ type: LOGIN, payload: { data: response?.data?.data } });
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        authApi.login(onSuccess, onError, values);
    };
};
export const getProfile = () => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: GetProfileStart });
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: GetProfileEnd, payload: { data: response?.data?.data } });
            } else {
                toast(response?.data?.message);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        authApi.getProfile(onSuccess, onError);
    };
};
export const updateProfile = (values: editProfilePayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UpdateProfile, payload: response?.data?.data });
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
                callback(true);
            } else {
                callback(false);
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            console.log("err", err);
            callback(false);
            handleSnackToast(err?.response?.data?.message || err?.message, Severities.ERROR);
        };
        authApi.updateProfile(onSuccess, onError, values);
    };
};
