import { AxiosError, AxiosResponse } from "axios";
import rule, { userRuleUpdatePayload } from "../../../api/ruleApi";
import { handleSnackToast } from "../../../components/common/Snackbar";
import { AppDispatch, BulkDeletePayload, CreateRuleObj, DeletePayload } from "../../../types/model";
import { Severities } from "../../reducers/toast";
import {
    FetchRulesStart,
    FetchRulesEnd,
    FetchPreferredRulesStart,
    FetchPreferredRulesEnd,
    RulesEnableDisable,
    PreferredRulesEnableDisable
} from "../../reducers/types";

type Callback = (...args: unknown[]) => unknown;

export const getRules = (offset?: number, limit?: number, isPreferred?: string, collectionId?: string, callback?: Callback) => {
    const params = {
        limit,
        offset,
        isPreferred,
        collectionId
    };

    return (dispatch: AppDispatch): void => {
        dispatch({ type: isPreferred === "1" ? FetchPreferredRulesStart : FetchRulesStart });

        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: isPreferred === "1" ? FetchPreferredRulesEnd : FetchRulesEnd, payload: { data: response?.data?.data } });
            }
            if (callback) callback(response?.data?.data);
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };

        rule.list(onSuccess, onError, params);
    };
};

export const deleteRule = (id: number, callback: Callback) => {
    const payload: DeletePayload = {
        modelName: "rule",
        id
    };

    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response.data.status) {
                callback();
                handleSnackToast(response.data.message, Severities.SUCCESS);
            }
        };

        const onError = (err: AxiosError) => {
            console.log(err);
        };

        rule.delete(onSuccess, onError, payload);
    };
};

export const toggleRuleStatus = (id: string, isPreferred: number, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response.data.status) {
                dispatch({
                    type: isPreferred ? PreferredRulesEnableDisable : RulesEnableDisable,
                    payload: { data: response?.data?.data, id }
                });
                if (callback) {
                    callback(response?.data?.data);
                }
                handleSnackToast(response.data.message, Severities.SUCCESS);
            }
        };

        const onError = (err: AxiosError) => {
            console.log(err);
        };

        rule.statusToggle(onSuccess, onError, id);
    };
};

export const bulkDelete = (deleteObj: BulkDeletePayload, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response.data.status) {
                callback(response.data);
            }
        };

        const onError = (err: AxiosError) => {
            console.log(err);
        };
        rule.bulkDelete(onSuccess, onError, deleteObj);
    };
};

export const storeRule = (createRuleObj: CreateRuleObj, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response.data.status) {
                callback();
            }
            handleSnackToast(response.data.message, response.data.status ? Severities.SUCCESS : Severities.ERROR);
        };

        const onError = (err: AxiosError) => {
            console.log(err);
        };
        rule.add(onSuccess, onError, createRuleObj);
    };
};

export const updateUserRule = (payload: userRuleUpdatePayload, callback: Callback, id?: string) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: any) => {
            handleSnackToast(err?.response?.data?.message, Severities.ERROR);
            console.log("Error ", err);
        };
        rule.update(onSuccess, onError, payload, id);
    };
};

export const getUserRule = (id: string, callback: Callback) => {
    return (): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                callback(response?.data?.data);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        rule.detail(onSuccess, onError, id);
    };
};
