import { useState } from "react";

const useTable = () => {
    const [pageSize, setPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState("ASC");
    const [field, setField] = useState("");
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    return {
        pageSize,
        setPageSize,
        offset,
        setOffset,
        order,
        setOrder,
        field,
        setField,
        selectedItems,
        setSelectedItems,
        isLoading,
        setIsLoading
    };
};

export default useTable;
