import { combineReducers } from "redux";
import AuthReducer from "./auth";
import CategoryReducer from "./category";
import DashboardReducer from "./dashboard";
import RulesReducer from "./rule";
import ToastReducer from "./toast";
import UserReducer from "./user";

export default combineReducers({
    auth: AuthReducer,
    dashboard: DashboardReducer,
    category: CategoryReducer,
    rules: RulesReducer,
    toast: ToastReducer,
    user: UserReducer
});
