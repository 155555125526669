import styled from "styled-components";
import FONTS from "../../config/fonts";
import Chevrondown from "../../assets/images/chevrondown.svg";

export const AutopilotPage = styled.div`
    display: flex;
    justify-content: left;
    align-items: left;
    a {
        color: #d52b2b;
    }

    select {
        width: 100px;
    }

    .image-upload > input {
        display: none;
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        min-width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }

        &[disabled] {
            background: rgba(95, 90, 90, 0.6);
            color: rgba(255, 255, 255, 0.5);
        }
    }
    table {
        thead {
            tr {
                background: #2f2f2f;
                th {
                    font-family: ${FONTS.interFont};
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;
                    color: #ffffff;
                    border: none;
                    min-height: 56px;
                    padding: 16px 20px;
                    vertical-align: baseline;

                    input {
                        cursor: pointer;
                        background: red;
                    }
                    &:first-child {
                        border-radius: 16px 0 0 16px;
                        vertical-align: middle;
                    }

                    &:last-child {
                        border-radius: 0 16px 16px 0;
                    }
                }
            }
        }
        tr {
            .logo {
                height: 50px;
                width: 50px;
                border-radius: 5px;
                object-fit: cover;
                object-position: center;
            }
        }

        .external-link {
            display: flex;
            align-items: center;
            svg {
                margin-left: 14px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASC {
                    transform: rotate(180deg);
                }
            }
        }
        .date-createdAt {
            display: flex;
            align-items: center;
            svg {
                margin-left: 8px;
                cursor: pointer;
                width: 24px;
                height: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s ease;

                &.ASCDate {
                    transform: rotate(180deg);
                }

                path {
                    fill: #ffffff;
                }
            }
        }

        .action-icon {
            display: flex;

            span {
                cursor: pointer;
            }

            svg {
                width: 32px;
                height: 32px;

                &:first-child {
                    margin-right: 24px;
                }

                path {
                    stroke: #d52b2b;
                }
            }

            .eye-icon {
                path {
                    stroke: #ffffff;
                    fill: #ffffff;
                }
            }
        }

        .Switchicon {
            .switch {
                position: relative;
                display: inline-block;
                width: 60px;
                height: 34px;
            }

            .switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 26px;
                width: 26px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            input:checked + .slider {
                background-color: #d52b2b;
            }

            input:focus + .slider {
                box-shadow: 0 0 1px #d52b2b;
            }

            input:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }

            .slider.round {
                border-radius: 34px;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }

        .total-amount {
            display: table-cell;
            max-width: 141px;

            svg {
                margin-left: 4px;
            }
        }

        .indexnumber {
            color: #d52b2b;
        }

        tbody {
            border-top: none !important;
            margin-bottom: 24px;

            tr {
                td {
                    font-family: ${FONTS.latoFont};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    border: none;
                    padding: 16px 20px;

                    a {
                        color: #ffffff;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-width: 200px;
                    }
                }
            }
        }
    }
`;

export const Title = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
    margin-bottom: 24px;
`;

export const ButtonSection = styled.div`
    display: flex;
    justify-content: flex-end;

    .image-upload {
        width: 235px;
        height: 64px;
        background-color: #ffffff;
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #221f1f;
        cursor: pointer;

        img {
            margin-right: 15px;
        }
    }

    button {
        width: 210px;
        height: 64px;
        margin-left: 31px;

        svg {
            margin-right: 15px;
        }
    }
`;

export const PageNumber = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 64px;

    h6 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 0;
    }
`;

export const PageSelect = styled.div`
    width: 125px;
    padding-right: 16px;

    select {
        width: 100%;
        height: 48px;
        background: #2f2f2f;
        border: 1px solid rgba(95, 90, 90, 0.4);
        border-radius: 48px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        padding: 12px 16px 14px 24px;
        appearance: none;
        background-image: url("${Chevrondown}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;

        &:focus-visible {
            outline: none;
        }
    }
`;

export const PaginationPage = styled.div`
    margin-bottom: 49px;

    ul {
        justify-content: center;
    }

    .page-link {
        background: #373636;
        border: 1px solid #4f4f4f;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        width: 65.6px;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page-item.disabled .page-link {
        background: #221f1f;
        border: 1px solid #4f4f4f;
    }

    .page-item.active .page-link {
        background: #d52b2b;
        border: transparent;
    }

    .page-link:focus {
        box-shadow: none;
    }
`;

export const DropdownSection = styled.div`
    width: 268px;

    select {
        width: 100% !important;
        height: 48px;

        background: #2f2f2f;
        border: 1px solid rgba(95, 90, 90, 0.4);
        border-radius: 8px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #ffffff;
        padding: 12px 16px 14px 24px;
        appearance: none;
        background-image: url("${Chevrondown}");
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 50%;

        &:focus-visible {
            outline: none;
        }
    }
`;

export const DropdownMainSection = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
`;

export const AutopilotTab = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 767.98px) {
        display: inline-block;
    }

    h4 {
        margin-bottom: 0;
        padding: 0 16px 10px 16px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        display: flex;

        &.active {
            color: #ffffff;
            border-bottom: 2px solid #d52b2b;

            span {
                p {
                    color: #d52b2b;
                }
            }
        }

        @media (max-width: 767.98px) {
            margin-bottom: 20px;
            text-align: center;
        }

        span {
            display: flex;
            align-items: baseline;
            margin-left: 5px;
        }

        p {
            font-family: ${FONTS.interFont};
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 0;
        }
    }
`;

export const AutopilotTabSection = styled.div`
    margin-bottom: 24px;
`;
