export default {
    titleErrorMessage: "Please enter title",
    descriptionErrorMessage: "Please enter description",
    siteUrlErrorMessage: "Please enter website url",
    twitterErrorMessage: "Please enter twitter",
    discordErrorMessage: "Please enter discord",
    mediumErrorMessage: "Please enter medium",
    bannerErrorMessage: "Please add banner",
    logoErrorMessage: "Please add logo",
    imageErrorMessage: "Please add image",
    nameErrorMessage: "Please enter name",
    timeErrorMessage: "Please enter time",
    amountErrorMessage: "Please enter amount",
    openseaErrorMessage: "Please enter opensea url"
};
