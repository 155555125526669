import { AxiosError, AxiosResponse } from "axios";
import user from "../../../api/userApi";
import { handleSnackToast } from "../../../components/common/Snackbar";
import { AppDispatch, BulkDeletePayload, DeletePayload, EmailPayload, UpdateProviderApproved, UpdateStatus } from "../../../types/model";
import { Severities } from "../../reducers/toast";
import { ApprovedUnapproved, EnableDisable, MailSent, UserDelete, UserList } from "../../reducers/types";

type Callback = (...args: unknown[]) => unknown;
export const ListUser = (
    offset?: number,
    limit?: number,
    callTypes?: boolean,
    order?: string,
    type?: string,
    field?: string,
    search?: string,
    callback?: Callback
) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UserList, payload: { data: response?.data?.data } });
            } else {
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            }
            if (callback) callback(response?.data?.data);
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.get(onSuccess, onError, offset, limit, order, field, type, search);
    };
};

export const DeleteUser = (payload: DeletePayload, categoryId: number, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UserDelete, payload: { data: response?.data?.data, categoryId } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.delete(onSuccess, onError, payload);
    };
};

export const removeBulkDelete = (payload: BulkDeletePayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: UserDelete, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.bulkDelete(onSuccess, onError, payload);
    };
};

export const enableDisable = (payload: UpdateStatus, userId: number, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.status) {
                dispatch({ type: EnableDisable, payload: { data: response?.data?.data, userId } });
                if (callback) {
                    callback(response?.data?.data);
                }
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.enableDisable(onSuccess, onError, payload);
    };
};

export const approvedUnapproved = (payload: UpdateProviderApproved, userId: number, callback?: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.status) {
                dispatch({ type: ApprovedUnapproved, payload: { data: response?.data?.data, userId } });
                if (callback) {
                    callback(response?.data?.data);
                }
                handleSnackToast(response?.data?.message, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.message, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.approvedUnapproved(onSuccess, onError, payload);
    };
};

export const sentMail = (payload: EmailPayload, callback: Callback) => {
    return (dispatch: AppDispatch): void => {
        const onSuccess = (response: AxiosResponse) => {
            if (response?.data?.status) {
                dispatch({ type: MailSent, payload: { data: response?.data?.data } });
                callback(response?.data?.data);
                handleSnackToast(response?.data?.data, Severities.SUCCESS);
            } else {
                handleSnackToast(response?.data?.data, Severities.ERROR);
            }
        };
        const onError = (err: AxiosError) => {
            console.log("Error ", err);
        };
        user.sendEmail(onSuccess, onError, payload);
    };
};
