import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import EditProfile from "./pages/editprofile";
import Login from "./pages/login";
import { ToastContainer } from "react-toastify";
// import Brand from "./pages/dashboard/brand";
import Layout from "./components/Layout";
import Collections from "./pages/collections/index";
import Rules from "./pages/rules";
import Users from "./pages/users";
import Logs from "./pages/log";
// import CreateBrand from "./pages/brands/createBrand";
// import EditBrand from "./pages/brands/editBrands";
import SnackBar from "./components/common/Snackbar";
import Detail from "./pages/detail";
// import Categories from "./pages/categories";
import AddCategory from "./pages/categories/AddCategory";
import EditCategory from "./pages/categories/EditCategory";
// import RulesCreate from "./pages/rules/createRule";
// import UpdateRule from "./pages/rules/UpdateRule";
import EditCollection from "./pages/collections/EditCollection";
import { IRootState } from "./store";
import CreatePerks from "./pages/createperks";
import Autopilot from "./pages/autopilot";
import EditPerk from "./pages/editperks";
import Perks from "./pages/perks";

function App(): ReactElement {
    const { userInfo } = useSelector((state: IRootState) => state?.auth);
    return (
        <div className="App">
            {!userInfo?.jwt ? (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/editprofile" element={<EditProfile />} />
                    {/* <Route path="/brands/create-brand" element={<CreateBrand />} />
                    <Route path="/brands/edit-brand/:id" element={<EditBrand />} /> */}
                    <Route path="/details" element={<Detail />} />
                    {/* <Route
                        path="/brands"
                        element={
                            <Layout>
                                <Brand />
                            </Layout>
                        }
                    /> */}
                    <Route
                        path="/collections"
                        element={
                            <Layout>
                                <Collections />
                            </Layout>
                        }
                    />
                    <Route
                        path="/rules"
                        element={
                            <Layout>
                                <Rules />
                            </Layout>
                        }
                    />
                    {/* <Route path="/rules/create" element={<RulesCreate />} />
                    <Route path="/rules/edit/:id" element={<UpdateRule />} /> */}
                    {/* <Route
                        path="/categories"
                        element={
                            <Layout>
                                <Categories />
                            </Layout>
                        }
                    /> */}
                    <Route
                        path="/logs"
                        element={
                            <Layout>
                                <Logs />
                            </Layout>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <Layout>
                                <Users />
                            </Layout>
                        }
                    />
                    <Route
                        path="/autopilot"
                        element={
                            <Layout>
                                <Autopilot />
                            </Layout>
                        }
                    />
                    <Route path="*" element={<Navigate to="/autopilot" />} />
                    <Route path="/categories/add" element={<AddCategory />} />
                    <Route path="/categories/edit/:id" element={<EditCategory />} />
                    <Route path="/collections/edit-collection/:id" element={<EditCollection />} />
                    <Route path="/create-perk" element={<CreatePerks />} />
                    <Route path="/edit-perk/:id" element={<EditPerk />} />
                    <Route
                        path="/perks"
                        element={
                            <Layout>
                                <Perks />
                            </Layout>
                        }
                    />
                </Routes>
            )}
            <ToastContainer autoClose={3000} />
            <SnackBar />
        </div>
    );
}

export default App;
