import { Container, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "../../assets/images/arrowleft.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReactElement, useEffect, useState } from "react";
import { getProfile, updateProfile } from "../../store/actions/auth/Auth";
import { AppDispatch } from "../../types/model";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN } from "../../store/reducers/types";
import AlertModal from "../../components/alertModal";
import Images from "../../config/images";
import FONTS from "../../config/fonts";
import Spinner from "../../components/common/Spinner";

const EditprofilePage = styled.div`
    margin-top: 82px;

    .form-label {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--white-color);
        margin-bottom: 18px;
    }

    .form-control:focus {
        color: unset;
        background-color: transparent;
        border-color: 1px solid rgba(255, 255, 255, 0.5);
        outline: none;
        box-shadow: none;
    }

    .form-control:focus-visible {
        border: 1px solid rgba(255, 255, 255, 0.5);
    }

    input {
        background: rgba(73, 65, 65, 0.4);
        border: 2px solid var(--form-bordercolor);
        border-radius: 48px;
        height: 64px;
        font-family: ${FONTS.latoFont};
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: var(--white-color);
    }

    button {
        background-color: var(--button-bgcolor);
        box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
        border-radius: 32px;
        width: 171px;
        height: 64px;
        padding: 10px 20px;
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        border: none;

        &:hover,
        &:focus,
        &:active:focus {
            background-color: var(--button-bgcolor);
            outline: none;
            box-shadow: none;
        }
    }

    .errorMessage {
        margin: 5px 0 0 10px;
    }

    .email-group {
        margin-bottom: 64px;
    }
`;

const ProfileCircle = styled.div`
    width: 56px;
    height: 56px;
    background: var(--button-bgcolor);
    box-shadow: 0px 2px 8px rgba(81, 39, 66, 0.25);
    border-radius: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 53px;

    @media (max-width: 767.98px) {
        margin-right: 16px;
    }
`;

const FormContent = styled.div`
    max-width: 406px;
    margin-left: 109px;

    @media (max-width: 767.98px) {
        margin: 0 auto;
    }
`;

const ProfileTitle = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        justify-content: center;
        margin-bottom: 10px;
    }
`;

const Title = styled.h1`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: var(--white-color);
    margin-bottom: 0;

    @media (max-width: 767.98px) {
        font-size: 38px;
        line-height: 48px;
    }
`;

const LogoutContent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 575.98px) {
        justify-content: center;
        margin-bottom: 10px;
    }

    svg {
        margin-right: 11px;
    }

    h4 {
        font-family: ${FONTS.interFont};
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: var(--button-bgcolor);
        margin-bottom: 0;
    }
`;

const LogoutSection = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
        justify-content: center;
    }

    @media (max-width: 575.98px) {
        display: initial;
    }

    button {
        margin-left: 40px;
        width: 219px;
    }
`;

const ProfileSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 49px;

    @media (max-width: 767.98px) {
        display: inherit;
        text-align: center;
    }
`;

const FormTitle = styled.h3`
    font-family: ${FONTS.interFont};
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: var(--white-color);
    margin-bottom: 40px;
`;

const Passwordeye = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 85px;
    height: 32px;
    margin: auto;
    text-align: center;
`;
const InputIconField = styled.div`
    position: relative;

    .form-control {
        padding-right: 65px;
    }
`;

const editProfileSchema = Yup.object().shape({
    email: Yup.string().email("Please enter valid email").trim().required("Please enter email"),
    password: Yup.string().required("Please enter old password.").min(8, "old password should be minimum 8 characters long."),
    newPassword: Yup.string()
        .required("Please enter new password.")
        .min(8, "Password should be minimum 8 characters long.")
        .max(16, "Password should be maximum 16 characters.")
        .notOneOf([Yup.ref("password"), null], "New password should not be same as old password"),
    confirmPassword: Yup.string()
        .required("Password and confirm password must be same")
        .oneOf([Yup.ref("newPassword"), null], "Password and confirm password must be same")
});

const EditProfile = (): ReactElement => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { userProfile } = useSelector((state: IRootState) => state?.auth);
    const [showPassword, setshowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [show, setShow] = useState(false);
    useEffect(() => {
        dispatch(getProfile());
    }, []);

    const afterUpdateProfile = (status: any) => {
        setIsLoading(false);
        if (status) {
            navigate("/");
        }
    };

    const onSubmit = (values: any) => {
        setIsLoading(true);
        const payload = {
            email: values.email,
            oldPassword: values.password,
            password: values.newPassword
        };
        dispatch(updateProfile(payload, afterUpdateProfile));
    };

    const handlelogout = () => {
        dispatch({ type: LOGIN, payload: null });
        navigate("/login");
    };
    return (
        <EditprofilePage>
            <Container>
                <Formik
                    initialValues={{
                        email: userProfile?.email,
                        password: "",
                        newPassword: "",
                        confirmPassword: ""
                    }}
                    validationSchema={editProfileSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, touched, errors, handleSubmit, setFieldTouched, handleChange }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <ProfileSection>
                                    <ProfileTitle>
                                        <ProfileCircle>
                                            <Link to={"/"}>
                                                <ArrowLeft />
                                            </Link>
                                        </ProfileCircle>
                                        <Title>Profile</Title>
                                    </ProfileTitle>
                                    <LogoutSection>
                                        <LogoutContent onClick={() => setShow(true)}>
                                            <LogoutIcon />
                                            <h4>Log out</h4>
                                        </LogoutContent>
                                        <Button variant="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? <Spinner /> : "Save changes"}
                                        </Button>
                                    </LogoutSection>
                                </ProfileSection>
                                <FormContent>
                                    <Form.Group className="form-group email-group">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            value={values.email}
                                            type="email"
                                            placeholder=""
                                            onChange={handleChange("email")}
                                            onBlur={() => setFieldTouched("email")}
                                            autoCapitalize="none"
                                            autoCorrect="none"
                                        />
                                        {errors.email && touched.email && (
                                            <div style={{ color: "red" }} className="errorMessage">
                                                {errors.email}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <FormTitle>Change password</FormTitle>
                                    <Form.Group className="form-group">
                                        <Form.Label>Old password</Form.Label>
                                        <InputIconField>
                                            <Form.Control
                                                value={values.password}
                                                type={showPassword ? "text" : "password"}
                                                placeholder=""
                                                onChange={handleChange("password")}
                                                onBlur={() => setFieldTouched("password")}
                                                autoCapitalize="none"
                                                autoCorrect="none"
                                            />
                                            <Passwordeye
                                                onClick={() => {
                                                    setshowPassword(!showPassword);
                                                }}
                                                className={values.password === "" ? "d-none" : ""}
                                            >
                                                <img src={showPassword ? Images.passwordHide : Images.passwordShow} />
                                            </Passwordeye>
                                        </InputIconField>

                                        {errors.password && touched.password && (
                                            <div style={{ color: "red" }} className="errorMessage">
                                                {errors.password}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>New password</Form.Label>
                                        <InputIconField>
                                            <Form.Control
                                                value={values.newPassword}
                                                type={showNewPassword ? "text" : "password"}
                                                placeholder=""
                                                onChange={handleChange("newPassword")}
                                                onBlur={() => setFieldTouched("newPassword")}
                                                autoCapitalize="none"
                                                autoCorrect="none"
                                            />
                                            <Passwordeye
                                                onClick={() => {
                                                    setShowNewPassword(!showNewPassword);
                                                }}
                                                className={values.newPassword === "" ? "d-none" : ""}
                                            >
                                                <img src={showNewPassword ? Images.passwordHide : Images.passwordShow} />
                                            </Passwordeye>
                                        </InputIconField>
                                        {errors.newPassword && touched.newPassword && (
                                            <div style={{ color: "red" }} className="errorMessage">
                                                {errors.newPassword}
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>Re-type password</Form.Label>
                                        <InputIconField>
                                            <Form.Control
                                                value={values.confirmPassword}
                                                type={showConfirmPassword ? "text" : "password"}
                                                placeholder=""
                                                onChange={handleChange("confirmPassword")}
                                                onBlur={() => setFieldTouched("confirmPassword")}
                                                autoCapitalize="none"
                                                autoCorrect="none"
                                            />
                                            <Passwordeye
                                                onClick={() => {
                                                    setShowConfirmPassword(!showConfirmPassword);
                                                }}
                                                className={values.confirmPassword === "" ? "d-none" : ""}
                                            >
                                                <img src={showConfirmPassword ? Images.passwordHide : Images.passwordShow} />
                                            </Passwordeye>
                                        </InputIconField>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <div style={{ color: "red" }} className="errorMessage">
                                                {errors.confirmPassword}
                                            </div>
                                        )}
                                    </Form.Group>
                                </FormContent>
                            </Form>
                            <AlertModal
                                open={show}
                                string={"Are you sure you want to logout?"}
                                setOpen={setShow}
                                handleRemove={handlelogout}
                                closeButton={"Cancel"}
                                yesButton={"Logout"}
                            />
                        </>
                    )}
                </Formik>
            </Container>
        </EditprofilePage>
    );
};

export default EditProfile;
